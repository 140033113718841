<div class="modal-body" id="modalConsultantReviewsBody">
<div class="row" *ngIf="error">
  <div class="col-md-2">
    <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
  </div>
  <div class="col-md-9">
    <p>Une erreur vient de survenir et Allo Mégane n'est pas en mesure de visualiser les avis.</p>
    <p>Merci d'essayer de nouveau plus tard !</p>
  </div>
</div>



<div class="tabs-item active" *ngIf="!error">
  <ng-container *ngIf="consultantNotice.length>0;else second">
    <!-- REVIEWS AVG -->
    <div class="row text-center mb-4">
      <div class="col-sm-12" *ngIf="(transactionStatus | async)===true  &&  ((isLoggedIn$ | async)===true || isValidtoken )">
        <div class="row text-center">
          <div class="col-12">
            <button class="btn btn-success-mobiyo" (click)="addNotice()">Donner votre avis</button>
          </div>
          <div class="col-12 mt-1">
            <p>{{transactions.date|date:'dd/MM/yyyy'}}, à {{transactions.date|date:'HH:mm'}} Conversation Privée (CB)</p>
          </div>
        </div>
      </div>

    </div>

    <hr  *ngIf="(transactionStatus | async)===true  &&  ((isLoggedIn$ | async)===true || isValidtoken )">

    <!-- REVIEWS -->

    <div style="height: 100%;overflow-y: auto;" id="reviews">


      <div *ngFor="let notice of consultantNotice let i='index'" class="pl-2 pr-2">

        <ng-container *ngIf="i<3; else second2">

        <div class="text-blog mt-0">
          <i class="fa fa-user pr-2 fa-lg"></i>
          <span>{{notice.customer_name}}</span>
          - <ngb-rating class="text-warning" [rate]="notice.customer_notice_notation"></ngb-rating>
          <span class="pl-2 pr-2">{{notice.NoticeDateYear}}, à {{notice.NoticeDateHour}}</span>
        </div>
        <div class="text-comments pl-4">
          {{notice.customer_remark}}
        </div>

        <div *ngIf="notice.consultant_anwser" class="text-blog ml-5 mt-2">
          <img src="{{consultantImageUrl}}" style="width: 20px;height: 20px">
          <span class="ml-2">{{notice.consultant_anwser}}</span>
        </div>

        <hr *ngIf="consultantNotice.length>i+1">

        </ng-container>
        <ng-template #second2>

          <div style="display: none" class="hiddenNotice">
          <div class="text-blog mt-0">
            <i class="fa fa-user pr-2 fa-lg"></i>
            <span>{{notice.customer_name}}</span>
            - <ngb-rating class="text-warning" [rate]="notice.customer_notice_notation"></ngb-rating>
            <span class="pl-2 pr-2">{{notice.NoticeDateYear}}, à {{notice.NoticeDateHour}}</span>
          </div>
          <div class="text-comments pl-4">
            {{notice.customer_remark}}
          </div>

          <div *ngIf="notice.consultant_anwser" class="text-blog ml-5 mt-2">
            <img src="{{consultantImageUrl}}" style="width: 20px;height: 20px">
            <span class="ml-2">{{notice.consultant_anwser}}</span>
          </div>
          <hr *ngIf="consultantNotice.length>i+1">
          </div>
        </ng-template>

      </div>

      <div *ngIf="consultantNotice.length>3" class="text-center">
        <button class="btn btn-success-mobiyo" id="btnShowAllNotices" (click)="showAllNotices()">Voir tous les avis</button>
      </div>


    </div>



  </ng-container>
  <ng-template #second>

    <!-- REVIEWS AVG -->

    <div *ngIf="(transactionStatus | async) && isValidtoken">
    <div class="row text-center mb-4">
      <div class='col-sm-12'>
        <div class="row text-center">
          <div class="col-12">
            <button class="btn btn-success-mobiyo" (click)="addNotice()">Donner votre avis</button>
          </div>
          <div class="col-12 mt-1">
            <p>{{transactions.date|date:'dd/MM/yyyy'}}, à {{transactions.date|date:'HH:mm'}} Conversation Privée (CB)</p>
          </div>
        </div>
      </div>
    </div>
    <hr>
    </div>

    <!-- REVIEWS -->

    <div class="text-center" id="aucunAvis">

    <!--  Aucun avis  -->

    </div>


  </ng-template>



</div>

</div>


<div class="modal-footer justify-content-center" *ngIf="error">
  <a  class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
</div>

