<div class="text-left container mt-4">

<div>

    <div class="row align-items-center mb-4">
      <div class="col">
        <a (click)="this.homeService.redirect('/')" class="btn btn-success-mobiyo btn-sm mr-5">< Revenir à la liste des Consultants</a>
      </div>
    </div>

    <h1 class="h3 mb-3 font-weight-normal d-inline-block">
      Mes infos
    </h1>
    <div class="spinner-border spinner-border-sm d-inline-block ml-2 mb-1" role="status" *ngIf="!(account | async) || !(definitions | async)">
      <span class="sr-only">Loading...</span>
    </div>

  </div>
  <div id="mes-info" *ngIf="!!(account | async) && !!(definitions | async)">

    <fieldset class="border fieldset-info-mobiyo">
      <legend class="w-auto">Connexion et joignabilité</legend>
      <app-email-update
        class="row fieldset-row-mobiyo"
        [customerEmail]="(account | async)?.customerEmail"
        [infoDefinitions]="definitions | async"
        [isCustomerEmailValidate]="(account | async)?.isCustomerEmailValidate"
      ></app-email-update>
      <app-password-update
        class="row fieldset-row-mobiyo"
        [infoDefinitions]="(definitions | async)"
      ></app-password-update>
      <app-add-primary-phone
        class="row fieldset-row-mobiyo"
        [customerPrincipalCallNumber]="(account | async)?.principalCallNumber"
        [customerPrincipalCallNumberValidated]="(account | async)?.isPrincipalCallNumberValidate"
        [customerPrincipalCallNumberType]="(account | async)?.customerPrincipalCallNumberType"
        [infoDefinitions]="definitions | async"
      ></app-add-primary-phone>
      <app-add-secondary-phone
        *ngIf="(definitions | async)?.customer_secondary_phone_activated"
        class="row fieldset-row-mobiyo"
        [customerPrincipalCallNumber]="(account | async)?.secondaryCallNumber"
        [customerPrincipalCallNumberValidated]="(account | async)?.isSecondaryCallNumberValidate"
        [customerPrincipalCallNumberType]="(account | async)?.secondaryCallNumberType"
        [infoDefinitions]="definitions | async"
      ></app-add-secondary-phone>
    </fieldset>

    <div class="row">
      <div class="col-md-6">
        <fieldset class="border fieldset-info-mobiyo" style="height: 100%">
          <legend class="w-auto">Qui suis-je ?</legend>
          <app-name-update
            [customerName]="(account | async)?.customerName"
            (newName)="updateUserInfo($event, 'customerName')"
          ></app-name-update>
          <app-first-name-update
            [customerFirstName]="(account | async)?.customerFirstName"
            (newFirstName)="updateUserInfo($event, 'customerFirstName')"
          ></app-first-name-update>
          <app-gender-update
            *ngIf="(definitions | async)?.customer_gender_activated"
            [customerSex]="(account | async)?.customerSex"
            (newSex)="updateUserInfo($event, 'customerSex')"
          ></app-gender-update>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset
          *ngIf="
            (definitions | async)?.customer_birth_date_activated ||
            (definitions | async)?.customer_birth_time_activated ||
            (definitions | async)?.customer_birth_place_activated ||
            (definitions | async)?.customer_birth_country_activated
          "
          class="border fieldset-info-mobiyo"
          style="height: 100%"
        >
          <legend class="w-auto">Ma date de naissance</legend>
          <app-birth-date
            *ngIf="(definitions | async)?.customer_birth_date_activated"
            class="row fieldset-row-mobiyo"
            [birthInfo]="(account | async)?.customerBirthday"
            (birthInfoEvent)="updateUserInfo($event, 'customerBirthday')"
            [infoDefinitions]="definitions | async"
            [ngClass]="{'disable-update-birth-date':(account | async)?.customerBirthday!=''}">
          ></app-birth-date>
          <app-birth-time
            *ngIf="(definitions | async)?.customer_birth_date_activated && (definitions | async)?.customer_birth_time_activated"
            class="row fieldset-row-mobiyo"
            [birthInfo]="(account | async)?.customerBirthday"
            (birthInfoEvent)="updateUserInfo($event, 'customerBirthday')"
            [infoDefinitions]="definitions | async"
          ></app-birth-time>
          <app-birth-country
            *ngIf="(definitions | async)?.customer_birth_country_activated"
            class="row fieldset-row-mobiyo"
            [birthInfo]="(account | async)?.customerBirthcountry"
            (birthInfoEvent)="updateUserInfo($event, 'customerBirthcountry')"
            [infoDefinitions]="(definitions | async)"
          ></app-birth-country>
          <app-birth-place
            *ngIf="(definitions | async).customer_birth_place_activated"
            class="row fieldset-row-mobiyo"
            [birthInfo]="(account | async)?.customerBirthplace"
            (birthInfoEvent)="updateUserInfo($event, 'customerBirthplace')"
            [infoDefinitions]="(definitions | async)"
          ></app-birth-place>
        </fieldset>
      </div>
    </div>

  <fieldset *ngIf="(definitions | async)?.customer_address_activated"  class="border fieldset-info-mobiyo">
    <legend class="w-auto">Mon adresse</legend>
    <div class="row fieldset-row-mobiyo">
      <div class="col-md-1">
      <label for="address-address">
        Adresse
      </label>
      </div>
      <div class="col-md-5">
      <input type="text" class="form-control" id="address-address" placeholder="68, Avenue des Champs-Élysées">
      </div>
      <div class="col-md-2">
        <label for="address-cp">
          Code Postal
        </label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" id="address-cp" placeholder="75008">
      </div>
    </div>
    <div class="row fieldset-row-mobiyo">
      <div class="col-md-1">
        <label for="address-city">
          Ville
        </label>
      </div>
      <div class="col-md-5">
        <input type="text" class="form-control"id="address-city" placeholder="Paris">
      </div>
      <div class="col-md-2">
        <label for="address-country">
          Pays
        </label>
      </div>
      <div class="col-md-3">
        <select  id="address-country" class="form-control">
          <option>France</option>
          <option>Belgique</option>
          <option>Luxembourg</option>
        </select>
      </div>

    </div>
    <div class="row fieldset-row-mobiyo">
    <div class="col-md-11" style="margin-top: 5px;margin-bottom: 5px">
      <button style="float: right" type="button" class="btn btn-warning">Enregistrer les modifications</button>
    </div>
    </div>
  </fieldset>
  <br>
  <div class="row">
    <app-cancel-account
      class="col-md-10"
      [infoDefinitions]="(definitions | async)"
      [cancelDate]="(account | async)?.customerStatus === 'ASK4CLOSE' ? (account | async)?.customerDateAskClosed : ''"
    ></app-cancel-account>
    <div class="col-md-2">
      <a (click)="this.homeService.redirect('/')" class="btn btn-warning float-lg-right">Terminer</a>
    </div>
  </div>
  </div>
</div>
