import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {LoginService} from '../../_services/login.service';
import {ForfaitService} from '../../_services/forfait.service';
import {PaiementService} from '../../_services/paiement.service';
import {DatePipe} from '@angular/common';
import {ConsultantService} from '../../_services/consultant.service';
import {PrivateConversationService} from '../../_services/private-conversation.service';
import DateTimeHelper from '../../_helpers/date.time.helper';

declare const $: any;
import {environment} from '../../../environments/environment';
import {interval, Observable} from 'rxjs';
import {Definitions} from '../../_models/definitions';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {HomeService} from '../../_services/home.service';
import {CryptoService} from '../../_services/crypto.service';
import * as dayjs from 'dayjs';
import {PromotionService} from '../../_services/promotion.service';

@Component({
  selector: 'app-select-private-conversation',
  templateUrl: './select-private-conversation.component.html',
  styleUrls: ['./select-private-conversation.component.css']
})
export class SelectPrivateConversationComponent implements OnInit {
  @Input() consultantId: number;
  @Input() consultantName: string;
  @Input() price: number;
  @Input() isExtendCall: boolean;
  form: FormGroup;
  options: any;
  minutes: number;
  time = 10;
  merchantId: any;
  officeId: any;
  consultant: any;
  user = null;
  userEmail = null;
  cardPartialNumber = null;
  oneClickId = null;
  customerId: any;
  customerSessionId: any;
  historyId: number;
  authorizationId: any;
  consultationMode: any;
  selectedAmount: any;
  selectedProduct: any;
  step: number;
  error: boolean;
  closeInterval: any;
  public definitions: Observable<Definitions>;
  continue: boolean;
  public bonus: string;
  codePromo: string;
  public finalTotal: number;
  public preTotal: number;
  public  validCode = false;
  public promotionValue: string;
  isNotValidCodePromo = true;
  erroCodePromo: boolean;
  erroMessage: string;
  messageMerchant: string;
  minForfait: any;
  isMinForfaitValid = true;
  typeBuyPromDiff: any;
  public isSelected = false;
  isPromoAlreadyApplied = false;
  duration: number;
  bonusInTime = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private forfaitService: ForfaitService,
    private paiementService: PaiementService,
    private datePipe: DatePipe,
    private consultantService: ConsultantService,
    private privateConversationService: PrivateConversationService,
    private store: Store<AppState>,
    private homeService: HomeService,
    private crypto: CryptoService,
    private promotionService: PromotionService,
  ) {
    this.definitions = store.select('definitions');
    this.step = 1;
    this.error = false;
    this.continue = true;
  }

  ngOnInit(): void {

    document.addEventListener('keypress', this.submitForm);
    this.customerId = localStorage.getItem('customerId');
    this.customerSessionId = localStorage.getItem('customerSessionId');
    this.userEmail = localStorage.getItem('customerEmail');

    this.merchantId = environment.merchantId;
    this.officeId = environment.officeId;

    this.consultant = {name: this.consultantService.consultantName.getValue()};
    this.consultationMode = 'CB';

    this.form = this.fb.group({
      conversationDuration: ['', [Validators.required]],
    });

    this.getOptions();

    this.modalSelectConversation();
  }

  /**
   * recupere la liste des forfaits
   * rajoute un champ paidAmount = au tarif horaire * duree du forfait
   */
  getOptions() {
    this.forfaitService.getBuy(this.merchantId, this.officeId).subscribe(
      (res: any) => {
        this.options = res.body;
        this.options.forEach((element, index) => {
          this.options[index].paidAmount = Math.round(this.price * element.numberPaidMin * 100) / 100;
        });

        if (this.isExtendCall) {
          const selectedElement = this.options.find(element => element.buyId === parseInt(localStorage.getItem('buyId')));
          this.minutes = selectedElement.numberPaidMin;
        } else {
          this.minutes = this.options[0].numberPaidMin;
        }
      },
      error => {
        this.fail();
      }
    );
  }

  isNaN(price: any): boolean {
    return isNaN(price);
  }

  redirect() {

    if (!this.erroCodePromo) {
      this.privateConversationService.selectedConvInfoSubject.next(this.selectedProduct);
      $('#converationSelect').modal('hide');
      $('#converationReSelect').modal('hide');

      if (!this.isExtendCall) {
        this.homeService.redirect('/start-conversation');

      }

      this.time = 0;
    }

  }

  spin(status) {
    const btn = document.getElementById('btnPaiement');
    if (btn) {
      if (status === 'on') {
        btn.setAttribute('disabled', 'disabled');
        btn.innerHTML = '' +
          '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i> Recherche en cours...';
      } else {
        btn.innerHTML = 'Valider';
        btn.removeAttribute('disabled');
      }
    }
  }

  askMoney() {
    if (this.form.valid) {
      this.continue = true;
      this.isPromoAlreadyApplied = true;
      const duration = Number(this.form.value.conversationDuration);
      this.duration = duration;
      const price = Number(this.price);
      const total = Math.round(duration * price * 100) / 100;
      this.checkAmount(total, duration, price);

    }
  }

  checkAmount(total, duration, price) {
    const dateNow = dayjs();
    this.spin('on');
    if (!this.continue) {
      return false;
    }
    this.paiementService.sendGetcards(this.customerId, this.merchantId).subscribe(
      (result2: any) => {
        if (result2.statusCode === undefined) {
          if (result2.data.cards.length === 0) {
            this.fail();
            return;
          }
          for (const card of result2.data.cards) {

            let dateString = card.exp_month + '-01-' + card.exp_year;
            dateString = dateString.replace(/-/g, '/');
            const carDate = dayjs(dateString).format();
            if (card.active === true && card.main_payment === true && dateNow.format() < carDate) {
              this.cardPartialNumber = card.partial_number;
              this.oneClickId = card.one_click_id;
            }
          }

          if (this.cardPartialNumber === null) {
            for (const card of result2.data.cards) {

              let dateString = card.exp_month + '-01-' + card.exp_year;
              dateString = dateString.replace(/-/g, '/');
              const carDate = dayjs(dateString).format();
              if (card.active === true && dateNow.format() < carDate) {
                this.cardPartialNumber = card.partial_number;
                this.oneClickId = card.one_click_id;
              }
            }
          }


          const data = {
            merchantId: this.merchantId.toString(), // <= toString a virer !#
            partialNumber: this.cardPartialNumber,
            amount: this.validCode ? this.finalTotal.toString() : total.toString(), // <= toString a virer !#
            customerEmail: this.userEmail || localStorage.getItem('customerEmail'),
            consultantId: String(this.consultantId),
            consultationMode: this.consultationMode,
            duration: DateTimeHelper.MinutesToTimeFormat(duration),
            dateTime: this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss')
          };

          if (!this.continue) {
            return false;
          }
          this.forfaitService.setHistory(data).subscribe(
            (res: any) => {
              if (res.id === undefined) {
                this.fail();
                return;
              }

              this.historyId = res.id;
              let amounts = [];
              this.useCodePromotion(this.codePromo , res.id);
              if (this.isExtendCall) {
                localStorage.setItem('otid', localStorage.getItem('ntid'));
              }

              localStorage.setItem('ntid', res.id);


              const options = this.options;
              options.forEach(element => {
                if (element.paidAmount < data.amount) {
                  amounts.push(element.paidAmount);
                }
              });

              amounts = amounts.sort((a, b) => {
                return a > b ? -1 : 1; // sort desc
              });

              amounts.unshift(data.amount);

              const allAmounts = amounts.join('|');

              if (!this.continue) {
                return false;
              }
              this.forfaitService.getAskMoney(this.customerId, this.oneClickId, 'EUR', allAmounts, this.historyId).subscribe(
                (res2: any) => {
                  if (res2.statusCode === -16) {
                    this.fail(2);
                    return;
                  }

                  if (res2.data === undefined) {
                    this.fail();
                    return;
                  }


                  // success on recupere le authorization_id
                  if (res2.data.authorization_id) {
                    this.authorizationId = res2.data.authorization_id;
                    this.selectedAmount = parseFloat(total); // montant retourner en string !#
                    this.spin('off');
                    const selectedOption = this.options.find(element => element.paidAmount === this.selectedAmount);
                    localStorage.setItem('buyId', selectedOption.buyId);
                    if (selectedOption === undefined) {
                      this.fail();
                      return;
                    }

                    const selectedProduct = {
                      duration: selectedOption.numberPaidMin + this.bonusInTime,
                      price: price,
                      total: this.selectedAmount,
                      transactionId: this.authorizationId,
                      historyId: this.historyId,
                      OneClickId: this.oneClickId,
                      partialNumber: this.cardPartialNumber,
                      consultationMode: this.consultationMode
                    };
                    localStorage.setItem('pdt', this.crypto.set(JSON.stringify(selectedProduct)));
                    const currentAmount = parseFloat(data.amount);
                    const sameAmount = this.selectedAmount === currentAmount;
                    if (!sameAmount) {
                      this.minutes = selectedOption.numberPaidMin;
                    }

                    this.selectedProduct = selectedProduct;
                    this.next(2, currentAmount <= this.selectedAmount);

                    if (this.isExtendCall) {
                      this.extendCall();
                    }
                  }
                },
                error => {
                  this.fail();
                }
              );
            },
            error => {
              this.fail();
            }
          );

        } else {
          this.fail();

        }
      },
      error => {
        this.fail();
      }
    );
  }

  modalSelectConversation() {
    $('#converationSelect, #converationReSelect')
      .on('show.bs.modal', () => {
        this.reset();
      })
      .on('hidden.bs.modal', () => {
        this.reset();
      })
      .on('hide.bs.modal', (e) => {
        if (this.continue) {
          e.preventDefault();
          e.stopImmediatePropagation();
          return false;
        }
      });
  }

  next(step: number, redirect = false): void {
    this.step = step;
    this.error = false;
    this.spin('off');
    this.continue = false;
    if (step === 2 && !this.error) {
      if (redirect) {
        this.redirect();
      } else {
        this.closeInterval = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            clearInterval(this.closeInterval);
            this.redirect();
          }
        }, 1000);
      }
    }
  }

  fail(step = this.step): void {
    this.step = step;
    this.error = true;
    this.spin('off');
    clearInterval(this.closeInterval);
    this.continue = false;
  }

  reset(): void {
    this.step = 1;
    this.error = false;
    this.erroCodePromo = false;
    this.time = 10;
    this.spin('off');
    this.continue = false;
    this.codePromo = null;
    this.isNotValidCodePromo = false;
    this.isMinForfaitValid = true ;
    this.promotionValue = null;
    this.isSelected = false;
    (document.getElementById('promoCode') as HTMLInputElement).value = '';
    this.finalTotal = this.preTotal;
    this.validCode = false;
    this.bonusInTime = 0;
    if (!this.isExtendCall) {
      clearInterval(this.closeInterval);
    }

  }

  applyPromotion(prices , minutes) {
    this.codePromo = this.getPromoCOde();
    this.preTotal = prices * minutes ;
    this.finalTotal = this.preTotal;
    if (this.codePromo) {
      this.checkPromoCode(this.codePromo);
    }

  }
  /* check promo code*/
  checkPromoCode(code) {
    const customerId = this.getCustomerID();
    this.promotionService.checkPromotion(code , customerId).subscribe(
      (res: any) => {
        if (res.statusCode === undefined) {
          if (res.isValid) {
            this.isNotValidCodePromo = false;
            this.calculateTotal(res.promotionNature, res.promotionValue);
          } else {
            this.isNotValidCodePromo  = true;
          }
        } else  {
          this.erroCodePromo = true;
          this.erroMessage = 'Une erreur vient de survenir et nous ne sommes pas en mesure  d\'utiliser votre code promotion.';
          this.messageMerchant = 'Aprés votre prestation, n\'hésitez pas à appeler';
        }
      }, error => {
        this.erroCodePromo = true;
        this.erroMessage = 'Une erreur vient de survenir et nous ne sommes pas en mesure  de vérifier le code de promotion';
        this.messageMerchant = 'Merci de contacter directement';

      });
  }
  /*  delete promotion  */
  deletePromotion() {
    this.isNotValidCodePromo = false;
    this.codePromo = null;
    this.promotionValue = null;
    this.finalTotal = this.preTotal;

  }
  /* apply promotion code */
  useCodePromotion(code , history) {
    const customerId = this.getCustomerID();
    if (code) {
      this.promotionService.usePromotion(code, history , customerId).subscribe(
        (res: any) => {
          if (res.statusCode === undefined) {
          } else {
            this.erroCodePromo = true;
            this.erroMessage = 'Une erreur vient de survenir et nous ne sommes pas en mesure  d\'utiliser votre code promotion.';
            this.messageMerchant = 'Aprés votre prestation, n\'hésitez pas à appeler';
            return;
          }
        }, error => {
          this.erroCodePromo = true;
          this.erroMessage = 'Une erreur vient de survenir et nous ne sommes pas en mesure  d\'utiliser votre code promotion.';
          this.messageMerchant = 'Aprés votre prestation, n\'hésitez pas à appeler';
          return;

        });
    }
  }
  /* get bonus*/
  getBonus(promotionType, promotionValue) {
    if (promotionType === 'AMOUNT') {
      return promotionValue + '€ de remise';
    } else if (promotionType === 'PERCENTAGE') {
      return promotionValue + '% de remise';
    } else {
      this.bonusInTime = promotionValue;
      return promotionValue + ' min. de bonus';
    }

  }
  /* calculate final total*/
  calculateTotal(promotionType, promotionValue) {
    this.promotionValue = this.getBonus(promotionType, promotionValue);
    if (promotionValue && promotionType === 'AMOUNT') {
      this.bonus = promotionValue + '€';
      this.minForfait =  this.checkForfaitMinimal(this.preTotal , promotionValue);
      const diffForfait = this.preTotal - this.minForfait;
      if (diffForfait > 0) {
        this.finalTotal = this.preTotal - promotionValue;
        this.isMinForfaitValid = true;
      } else  {
        this.isMinForfaitValid = false;
      }
    } else if (promotionValue && promotionType === 'PERCENTAGE') {
      const percentage = (this.preTotal / 100) * promotionValue ;
      this.bonus = percentage.toFixed(2) + ' €';
      this.finalTotal =  this.preTotal - percentage;
      if (this.finalTotal <= 0) {
        this.isMinForfaitValid = false;
        this.minForfait = this.checkForfaitMinimal(this.preTotal, promotionValue);
      } else {
        this.isMinForfaitValid = true;
      }
    } else {
      this.bonus = null ;
      this.finalTotal = this.preTotal;
    }
  }
  /**
   *  add validation to the code promo input
   *
   */
  customValidator() {
    const el = $('#promoCode').val();
    this.isNotValidCodePromo = false;
    this.promotionValue = null;
    this.finalTotal = this.preTotal;
    this.validCode = el?.length  === 4;
  }

  /**
   *  check forfait minimal
   */
  checkForfaitMinimal(price , bonus) {
    this.definitions.subscribe(definitions => {
      this.typeBuyPromDiff = definitions?.type_buy_promo_diff;
    });
    let minimalForfait: any;
    const minDiff = (bonus / 100) * this.typeBuyPromDiff;
    minimalForfait = bonus + minDiff;
    return minimalForfait;
  }

  /**
   *
   * @param e
   * @param price
   * @param minutes
   */
  stopEnterSubmitting(e , price , minutes) {
    this.codePromo = this.getPromoCOde();
    if (e.keyCode === 13 && this.codePromo && !this.isNotValidCodePromo && !this.isSelected) {
      this.isSelected = true  ;
      this.applyPromotion(price, minutes);
      const src = e.srcElement || e.target;
      if (src.tagName.toLowerCase() !== 'textarea') {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    } else if (e.keyCode === 13) {
      this.applyPromotion(price, minutes);
    }
  }

  getPromoCOde() {
    this.codePromo = (document.getElementById('promoCode') as HTMLInputElement)?.value;
    if (this.codePromo?.length === 4) {
      return this.codePromo;
    }
    return undefined;
  }
  submitForm(e) {
    const finalTotal = (document.getElementById('finalTotal') as HTMLInputElement)?.getAttribute('data-value');
    this.codePromo = (document.getElementById('promoCode') as HTMLInputElement)?.value;
    const bonus = (document.getElementById('bonus') as HTMLInputElement)?.getAttribute('data-value');
    if ( e.key === 'Enter' && finalTotal  && this.codePromo.length === 4 && bonus) {
      document.getElementById('btnPaiement').click();
    }
  }

  onpaste(e) {
    const promoCode = document.getElementById('promoCode');
    promoCode?.addEventListener('input', event => {
      const { target } = event;
      if (target) {
        const el = (target as HTMLButtonElement).value;
        this.validCode = el?.length === 4;
      }

    });
  }

  extendCall() {
    const customerId = this.getCustomerID();


    const data = {
      merchantId: this.merchantId,
      oldTransactionId: localStorage.getItem('otid'),
      newTransactionId: localStorage.getItem('ntid'),
      oldSessionId: parseInt(localStorage.getItem('nsid')),
      consultantId: this.consultantId,
      clientId: this.customerId || customerId,
      record: 'true',
      duration: (this.duration + this.bonusInTime) * 60
    };

    this.privateConversationService.extendCall(data).subscribe(
        (res: any) => {
          if (res.sessionId !== undefined) {
            localStorage.setItem('osid', localStorage.getItem('nsid'));
            localStorage.setItem('nsid', res.sessionId);

          } else {
            $('#converationReSelect').modal('hide');
            $('#errorExtendCall').modal('show');

          }
        },
        error => {
          $('#converationReSelect').modal('hide');
          $('#errorExtendCall').modal('show');
        }
    );


  }

  changeOffer(buyId: any) {

  }

  getCustomerID() {
    this.loginService.sharedUser.subscribe(
      (customerId: any) => {
        return customerId;
      },
      error => {
        console.log(error);

      }
    );
  }
}
