import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {LoginService} from '../_services/login.service';
import {HomeService} from '../_services/home.service';
import {RegisterService} from '../_services/register.service';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {Observable} from 'rxjs';
import {Definitions} from '../_models/definitions';
import {AccountService} from '../_services/account.service';
import {NavigationStart, NavigationEnd, Router, Event as NavigationEvent} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Account} from '../_models/account';
import {PromotionService} from '../_services/promotion.service';
import {DefinitionService} from '../_services/definition.service';

declare var $: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit , AfterContentChecked  {
  private customerId: any;
  public customerEmail: any;
  public definitions: Observable<Definitions>;
  account: Observable<Account>;

  redirect: {id: number, url: string};


  constructor(
    public router: Router,
    private loginService: LoginService,
    public homeService: HomeService,
    private registerService: RegisterService,
    private store: Store<AppState>,
    private accountService: AccountService,
    public  promotionService: PromotionService,
    private definitionService: DefinitionService
) {
    this.definitions = store.select('definitions');
    this.account = store.select('account');
    this.definitions.subscribe(definitions => {
      if (definitions) {
        this.showPromotion();
      }
    });

  }

  ngOnInit(): void {
    this.definitionService.fetchDefinitions();
    this.checkRouteEvents();
    this.registerService.sharedUserId.subscribe(
        (res: any) => {
          this.customerId = res;
        },
        error => {
          console.log(error);
        }
    );

    this.registerService.customerOldEmail.subscribe(
        (res: any) => {
          this.customerEmail = res;
        },
        error => {
          console.log(error);
        }
    );
    this.checkTokenIfExist();
    this.homeService.outerRestForm();
    this.homeService.outerRestFormRegister();
  }

  ngAfterContentChecked(): void {
  }

  // close Modal
  onLogout() {
    this.loginService.logout();
  }

  sendMail() {
    const data = {
      recipientMail: this.customerEmail,
      customerId: this.customerId
    };

    this.registerService.sendValidationMail(data);

    // Set the date we're counting down to
    const countDownDate = new Date().getTime() + 2 * 60000;

    $('#requestMail').hide();
    $('#ResetMail').hide();
    $('#sendEmailCountDown').show();

    // Update the count down every 1 second
    const x = setInterval(() => {

      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      $('#sendEmailCountDown p').html('Email envoyé ' + minutes + 'm ' + seconds + 's avant de demander un autre lien');

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        $('#sendEmailCountDown').hide();
        $('#requestMail').show();
        $('#ResetMail').show();

      }
    }, 1000);
  }

  resetEmail() {

    $('#bodyModalValidationEmail').hide();
    $('#bodyModalResetEmail').show();

  }


  checkTokenIfExist() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const customerToken = urlParams.get('to');
    const tmpSession = urlParams.get('ts');
    let deleteToken = '1';
    if (tmpSession) {
      deleteToken = '0';
    }

    if (customerToken) {
      // Check if token is valid

      this.registerService.checkToken(customerToken, deleteToken).subscribe(
        (result: any) => {

          if (result.statusCode === undefined && result.isValid) {
            // If token is valid and token type is VALIDATE-MAIL
            if (result.commandToken === 'VALIDATE-MAIL') {
              this.accountService.update('isCustomerEmailValidate', true);
              this.definitions.subscribe((definitions) => {
                $('#clickWebLinkSuccessTitle').html('Validation de votre compte ' + definitions?.sms_brand);
              });
              $('#clickWebLinkSuccessMessage').html('FÉLICITATIONS ! Votre compte mail est activé !');
              $('#clickWebLinkSuccess').modal('show');

            } else if (result.commandToken === 'RE-INIT-PASSWORD') {
              $('#passwordResetModal').modal('show');
            } else {
              $('#errorAccountApi').modal('show');
            }
          } else {
            // Invalide token
            if (!tmpSession) {
              this.definitions.subscribe((definitions) => {
                $('#ModalEmailValidationErrorInvalide').html('Validation de votre compte ' + definitions?.sms_brand);
              });
              $('#clickWebLinkInvalideTokenMessage').html('Connectez-vous et demandez l\'émission d\'un nouveau lien.');

            } else {
              $('#ModalEmailValidationErrorInvalide').html('Réinitialisation de votre mot de passe');
              $('#clickWebLinkInvalideTokenMessage').html('Cliquez sur le lien "Réinitialiser le mot de passe" pour recevoir un nouveau lien.');

            }
            $('#invalideToken').modal('show');
          }

        },
        error => {
          // Can't connect to API account
          console.log(error);
          $('#clickWebLinkApiErrorMessage').html('Une erreur vient de survenir et nous ne sommes pas en mesure de vérifier votre lien.');
          $('#errorAccountApi').modal('show');
        }
      );
    }
  }

  checkRouteEvents() {
    this.router.events
      .pipe(
        filter(
          ( event: NavigationEvent ) => {
            return( event instanceof NavigationStart );
          }
        )
      )
      .subscribe(
        ( event: NavigationStart ) => {
          if (event.url === '/start-conversation' && (event.navigationTrigger === 'popstate' )) {
            this.redirect = {id: event.id, url: '/'};
          }
        }
      )
    ;
    this.router.events
      .pipe(
        filter(
          ( event: NavigationEvent ) => {
            return( event instanceof NavigationEnd );
          }
        )
      )
      .subscribe(
        ( event: NavigationEnd ) => {
          if (this.redirect && this.redirect.id === event.id) {
            const url = this.redirect.url;
            this.redirect = null;
            this.router.navigateByUrl(url);
          }
        }
      )
    ;
  }
  showPromotion() {
      this.promotionService.fetchPromotions('Acces');
  }
}
