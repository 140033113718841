import {Component, EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProfileService} from '../../../_services/profile.service';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateParserFormatter} from '../../../_helpers/CustomDateParserFormatter';
declare var $: any;

@Component({
  selector: 'app-birth-date',
  templateUrl: './birth-date.component.html',
  styleUrls: ['./birth-date.component.css'],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    }
  ]
})
export class BirthDateComponent implements OnInit {
  @Input() birthInfo: string;
  @Input() infoDefinitions: any;
  @Output() birthInfoEvent = new EventEmitter<string>();
  form: FormGroup;
  success = false;
  error = false;
  time: Date;
  minDate: { month: number; year: number; day: number };
  maxDate: { month: number; year: number; day: number };
  submitting = false;
  two = (x: number): string => x > 9 ? `${x}` : `0${x}`;
  dateToObj = (d: Date) => ({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() });
  dateTimeToString = (d: Date) => `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${this.two(d.getHours())}:${this.two(d.getMinutes())}:${this.two(d.getSeconds())}`;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
  ) {
    const [minDate, maxDate] = [new Date(), new Date()];
    minDate.setFullYear(minDate.getFullYear() - 100);
    maxDate.setFullYear(maxDate.getFullYear() - 16);
    this.minDate = {year: minDate.getFullYear(), month: minDate.getMonth() + 1, day: minDate.getDate()};
    this.maxDate = {year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate()};
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId'),
      customerBirthday: ['', [
        Validators.required
      ]],
    });
    $('#add-birth-date-modal')
      .on('show.bs.modal', () => {
        if (this.birthInfo) {
          this.time = new Date(this.birthInfo);
          this.form.patchValue({
            customerBirthday: this.dateToObj(new Date(this.birthInfo))
          });
        }
      }).on('hidden.bs.modal', () => {
        this.reset();
      });
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitting = true;
      const formValue = this.form.value;
      const birthdayObj = formValue.customerBirthday;
      const birthday = new Date(birthdayObj.year, birthdayObj.month - 1, birthdayObj.day);
      if (this.time) {
        birthday.setHours(this.time.getHours());
        birthday.setMinutes(this.time.getMinutes());
        birthday.setSeconds(this.time.getSeconds());
      }
      formValue.customerBirthday = this.dateTimeToString(birthday);
      this.profileService.editBirthDate(formValue).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.birthInfoEvent.emit(formValue.customerBirthday);
            this.next();
          } else {
            this.next(false);
          }
        },
        (error) => {
          this.next(false);
        }
      );
    } else {
      this.next(false);
    }
  }

  next(state = true): void {
    if (state) {
      $('#add-birth-date-modal').modal('hide');
    } else {
      this.success = state;
      this.error = !state;
    }
  }

  reset(): void {
    this.success = false;
    this.error = false;
    this.form.patchValue({
      customerBirthday: ''
    });
    this.submitting = false;
  }
}
