import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {Definitions} from '../_models/definitions';
declare var $: any;



@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  definitions: Observable<Definitions>;

   apiUrl = environment.apiUrlAccountCustomer;
   private data: any;
   public customerOldEmail = new BehaviorSubject(null);
   public currentUserIdSubject = new BehaviorSubject(null);
   sharedUserId = this.currentUserIdSubject.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private store: Store<AppState>
) {
    this.definitions = store.select('definitions');
  }

  register(formData) {
    formData.merchantId = environment.merchantId;
    formData.cabinetId = environment.officeId;
    return this.http.post(`${this.apiUrl}/account-create`, formData);
  }

  checkEmail(email: string) {
    this.data = {
      merchantId: environment.merchantId,
      cabinetId: environment.officeId,
      customerEmail: email
    };
    return this.http.post(`${this.apiUrl}/account-create-check`, this.data);

  }

  checkToken(customerToken, deleteToken= '0') {

    const params = new  HttpParams({
      fromObject : {
        customerToken,
        deleteToken
      }
    });
    return this.http.get(`${this.apiUrl}/account-check-token`, {params});

  }

  createToken(data) {
    return this.http.post(`${this.apiUrl}/account-create-token`, data);
  }

  sendEmail(data) {
    this.definitions.subscribe((definitions) => {
      if (definitions?.provider_mail) {
        data.senderMail = definitions.provider_mail;
      }
    });
    return this.http.post(`${this.apiUrl}/account-mail-send`, data);
  }

  createTemporarySession(data) {
    return this.http.post(`${this.apiUrl}/account-create-temporary-session`, data);
  }

  resetPassword(data) {
    return this.http.post(`${this.apiUrl}/account-password-init`, data);
  }


  sendValidationMail(data) {
    const baseUrl = window.location.origin + location.pathname;
    const dataToken = {customerId: data.customerId, commandToken: 'VALIDATE-MAIL'};
    const recipientMail = data.recipientMail;
    this.createToken(dataToken).subscribe(
      (result: any) => {
        if (result.statusCode === undefined) {
          const emailToken = result.customerToken;
          if (result.customerToken) {
            const dataMailSend = {
              merchantId: environment.merchantId,
              recipientMail,
              templateParams: {url: baseUrl + '?to=' + emailToken},
              template: 'url_template_account_activation',
              subject: 'Activation de votre compte',
            };
            this.sendEmail(dataMailSend).subscribe(
              (res: any) => {
                if (res.statusCode === undefined) {
                  // ...
                } else {
                  $('#registerModal').modal('hide');
                  $('#modalErrorEmailValidation').modal('show');
                }
              },
              error => {
                $('#registerModal').modal('hide');
                $('#modalErrorEmailValidation').modal('show');
              }
            );
          }
        } else {
          $('#registerModal').modal('hide');
          $('#modalErrorEmailValidation').modal('show');
        }
      },
      error => {
        $('#registerModal').modal('hide');
        $('#modalErrorEmailValidation').modal('show');
      }
    );

  }


}
