
<app-navbar></app-navbar>
<div>
  <app-notification
    [definitions]="(definitions | async)"
    [customerId]="(account | async)?.customerId"
    [customerEmail]="(account | async)?.customerEmail"
    [customerPhone]="(account | async)?.principalCallNumber"
    [customerPhoneValidate]="(account | async)?.isPrincipalCallNumberValidate"
    [customerSecondPhone]="(account | async)?.secondaryCallNumber"
  ></app-notification>

  <div class="row mt-5" *ngIf="(session | async).isLoggedIn === null">
    <div class="col text-center">
      <div class="spinner-border" role="status" aria-hidden="true">
        <span class="sr-only">Chargement...</span>
      </div>
    </div>
  </div>
  <router-outlet *ngIf="(session | async).isLoggedIn !== null"></router-outlet>
</div>



<!-- Account Module  -->
<app-account></app-account>

<!-- Payment  Module  -->
<app-payment [definitions]="definitions | async"></app-payment>


