<div class="col-md-8">
  Mon email : {{customerEmail}}
  <ng-container *ngIf="isCustomerEmailValidate;else second">
    <i class="fa fa-check-circle text-success" title="Le compte est validé" ></i>
  </ng-container>
  <ng-template #second>
    <i class="fa fa-check-circle text-danger" title="Le compte n'est pas validé !" ></i>
  </ng-template>
</div>
<div class="col-md-2 text-center">
  <ng-container *ngIf="!isCustomerEmailValidate">
    <button type="button"  class="btn btn-warning info-add-update-btn-mobiyo" (click)="openModalValidationEmail()">
      Valider
      <span class="spinner-border spinner-border-sm" role="status" *ngIf="senEmail">
        <span class="sr-only">Loading...</span>
      </span>
    </button>
  </ng-container>
</div>
<div class="col-md-2">
  <ng-container *ngIf="customerEmail;else second">
    <button type="button" class="btn btn-warning info-add-update-btn-mobiyo" data-toggle="modal" data-target="#change-email-modal">
      Modifier
    </button>
  </ng-container>
  <ng-template #second>
    <button type="button"  class="btn btn-warning info-add-update-btn-mobiyo" data-toggle="modal" data-target="#change-email-modal">
      Ajouter
    </button>
  </ng-template>
</div>

<div class="modal fade" id="change-email-modal" tabindex="-1" aria-labelledby="change-email-modal-label" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="change-email-modal-label">Changer mon mail</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body" *ngIf="!success && !error">
          <p>
            Si vous voulez changer le mail associé à votre compte {{infoDefinitions?.sms_brand}}, vous pouvez le faire ci-dessous. N'oubliez pas de cliquer sur le bouton *Enregistrer les modifications* quand vous avez terminé.
          </p>
          <div class="form-group">
            <label for="current-email">Mail actuel</label>
            <input
              formControlName="customerOldEmail"
              type="email"
              class="form-control"
              id="current-email"
            />
          </div>
          <div class="form-group">
            <label for="new-email">Nouveau mail</label>
            <input
              formControlName="customerNewEmail"
              type="email"
              class="form-control"
              id="new-email"
              autocomplete="off"
              [class.is-valid]="f.customerNewEmail.valid"
              [class.is-invalid]="f.customerNewEmail.invalid"
            />
          </div>
          <div class="row" *ngIf="f.customerNewEmail.errors?.email || f.customerNewEmail.errors?.pattern || f.customerNewEmail.errors?.mustDifferent">
            <div class="col-2">
              <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
            </div>
            <div class="col-10">
              <p *ngIf="f.customerNewEmail.errors?.email || f.customerNewEmail.errors?.pattern">
                Votre email est incorrect !
              </p>
              <p *ngIf="f.customerNewEmail.errors?.mustDifferent">
                Votre email est identique à l'ancien !
              </p>
            </div>
          </div>
        </div>
        <div class="modal-body" *ngIf="success">
          <p>
            Vous allez recevoir sur votre compte <b>{{ this.form.controls.customerNewEmail.value }}</b> un lien sur lequel vous devrez cliquez pour enregistrer votre nouvelle adresse email.
          </p>
          <p>
            Vérifiez qu'il ne soit pas rangé dans vos spams !
          </p>
        </div>
        <div class="modal-body" *ngIf="error">
          <div class="row">
            <div class="col-2">
              <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
            </div>
            <div class="col-10">
              <p class="text-danger">
                Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer l'opération demandée.
              </p>
            </div>
          </div>
          <br>
          <p>
            Merci de contacter directement {{infoDefinitions?.sms_brand}} au {{infoDefinitions?.provider_call_number}}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" type="submit" [disabled]="!form.valid || submitting" *ngIf="!success && !error">Enregistrer les modifications</button>
          <button type="button" class="btn btn-warning" data-dismiss="modal" *ngIf="success">J'ai compris</button>
          <button type="button" class="btn btn-warning" data-dismiss="modal" *ngIf="error">Continuer</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal fade" id="check-email-modal" tabindex="-1" aria-labelledby="check-email-modal-label" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="check-email-modal-label">Changer mon mail</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="successCheck">
        <div class="row">
          <div class="col-2">
            <i class="fa fa-check-circle fa-4x text-success"></i>
          </div>
          <div class="col-10 align-self-center">
            Votre email est modifié.
          </div>
        </div>
      </div>
      <div class="modal-body" *ngIf="errorCheck">
        <div class="row">
          <div class="col-2">
            <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
          </div>
          <div class="col-10 align-self-center text-danger">
            Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer l'opération demandée.
          </div>
        </div>
        <br>
        <p>
          Merci de contacter directement {{infoDefinitions?.sms_brand}} au {{infoDefinitions?.provider_call_number}}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-dismiss="modal" *ngIf="successCheck" (click)="this.homeService.redirect('/profile');">J'ai compris</button>
        <button type="button" class="btn btn-warning" data-dismiss="modal" *ngIf="errorCheck" (click)="this.homeService.redirect('/profile');">Continuer</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal validation mail -->

<div class="modal fade" id="validation-email-modal" tabindex="-1" aria-labelledby="" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="cancel-account-modal-label">ACTIVER MON COMPTE ALLO MEGANE</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Vous allez recevoir un mail sur {{customerEmail}}, expédié par {{infoDefinitions?.provider_mail}} <br>

          ATTENTION : Vérifiez que le mail ne soit pas dans vos spams !
        </p>
        <p>
          Consultez votre mail, puis cliquez sur le lien pour activer votre compte.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal">J'ai compris</button>
      </div>
    </div>
  </div>
</div>


<!--    Mobile phone Modal Error  -->
<div class="modal fade" id="modalErrorEmailValidation" tabindex="-1" role="dialog" aria-labelledby="modalErrorEmailValidationLabel" aria-hidden="false">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="modalErrorEmailValidationLabel">ACTIVER MON COMPTE ALLO MEGANE</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p >
              Une erreur vient de survenir et nous ne sommes pas en mesure de vous envoyer un mail pour activer votre compte.
            </p>
          </div>


        </div>
        <p >Merci de contacter directement {{infoDefinitions?.sms_brand}} au {{infoDefinitions?.provider_call_number}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>

      </div>
    </div>
  </div>
</div>
