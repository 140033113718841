import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ConsultantService} from '../../_services/consultant.service';
import {Observable} from 'rxjs';
import {LoginService} from '../../_services/login.service';
import {environment} from '../../../environments/environment';
import {NoticeService} from '../../_services/notice.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {Account} from '../../_models/account';
import {AccountService} from '../../_services/account.service';
import {Definitions} from '../../_models/definitions';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import * as isTomorrow from 'dayjs/plugin/isTomorrow';
import 'dayjs/locale/fr';
import {filter} from 'rxjs/operators';
import {PaiementService} from '../../_services/paiement.service';
import {CryptoService} from '../../_services/crypto.service';
import {HomeService} from '../../_services/home.service';
declare var $: any;


@Component({
  selector: 'app-consultant-profile',
  templateUrl: './consultant-profile.component.html',
  styleUrls: ['./consultant-profile.component.css']
})
export class ConsultantProfileComponent implements OnInit {
  private account: Observable<Account>;
  public definitions: Observable<Definitions>;
  private isPrincipalCallNumberValidate: boolean;
  data: any;
  isLoggedIn$: Observable<boolean>;
  isValidtoken: boolean;
  private consultantId$ = this.consultantservice.consultantId;
  private consultantCallNumber$ = this.consultantservice.consultantCallNumber;
  private consultantName$ = this.consultantservice.consultantName;
  public showAddNotice: any;
  public showAllNotice: any;
  consultantInfo: any;
  listConsu = [];
  consultantID: any;
  plannings = [];
  public languagesText: {code: string, text: string}[];
  public benefits: any[];
  public slideConfig: {};
  public status: string;
  public statusColor: string;
  public statusCode: number;
  private isLoggedIn: boolean;
  previousUrl: string;
  customerId: string;
  emptyCard: boolean;
  merchandId = environment.merchantId;
  activateLink = false;
  serviceType: any;
  voyanceTypes: any;

  public findLanguage = (lan: string) => this.languagesText.find(({code}) => code === lan);

  constructor(
    private route: ActivatedRoute,
    private loginservice: LoginService,
    private consultantservice: ConsultantService,
    private noticeService: NoticeService,
    private paiementService: PaiementService,
    private loginService: LoginService,
    private router: Router,
    private store: Store<AppState>,
    private accountService: AccountService,
    private config: NgbRatingConfig,
    private cryptoService: CryptoService,
    public homeService: HomeService
  ) {
    this.account = store.select('account');
    this.definitions = store.select('definitions');
    this.account.subscribe((account) => {
      this.isPrincipalCallNumberValidate = account?.isPrincipalCallNumberValidate;
    });
    this.slideConfig = {
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite : false
          }
        },
        {
          breakpoint: 600,
          settings: 'unslick',
        },
        {
          breakpoint: 480,
          settings: 'unslick'
        }
      ]
    };
    config.max = 5;
    config.readonly = true;
    this.languagesText = [
      {
        code: 'EN',
        text: 'I also speak english!'

      },
      {
        code: 'ES',
        text: '¡Yo también hablo español!'
      },
      {
        code: 'IT',
        text: 'Parlo anche italiano'
      }
    ];

    this.voyanceTypes = {
      tarologist_type: 'Tarot',
      runes_type: 'Runes',
      astrologer_type: 'Astrologie',
      fortune_teller_type: 'Cartomancie',
      oracle_type: 'Oracles',
      numerologist_type: 'Numérologie',
      psychic_seer_type: 'Voyant',
      pendulum_type: 'Pendule',
      dowsing_type: 'Radiesthésiste',
      medium_type: 'Medium'
    }

    this.benefits = [];
    dayjs.locale('fr');
    dayjs.extend(isToday);
    dayjs.extend(isTomorrow);
    this.customerId = localStorage.getItem('customerId');
  }

  ngOnInit(): void {
    dayjs.locale('fr');
    this.isLoggedIn$ = this.loginservice.isLoggedIn;
    this.isLoggedIn$.subscribe((value) => {
      this.isLoggedIn = value;
    });
    this.isValidtoken = localStorage.getItem('customerSessionId') !== null;
    this.consultantID = this.cryptoService.get(localStorage.getItem('ctcx'));
    this.getConsutlantData(this.consultantID);
    this.noticeService.showAllNotice.subscribe(res => {
      this.showAllNotice = res;
    });
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }

  getConsutlantData(consultantID) {

    this.consultantservice.sendGetConsutalntByID(environment.merchantId, environment.officeId , consultantID).subscribe(
    (res: any) => {
        this.consultantInfo = res.body.data[0];
        const tooltipContents = {
          available: 'Je suis disponible en',
          busy: 'Je suis actuellement en consultation',
          unavailable: 'Je ne suis pas disponible actuellement en'
        };

        localStorage.setItem('cc', this.consultantInfo.conversationcost);

        const result = this.consultantservice.handle(this.consultantInfo);
        this.status = result.statut;
        this.statusColor = result.colorClass;
        this.statusCode = result.statutCode;
        this.serviceType = result.service;
        result.prestations.forEach(prestation => {
          const benefit = {
            type: prestation.name.toLowerCase(),
            text: undefined,
            status: prestation.active === 0 ? 'unavailable' : (result.statutCode === 0 ? 'available' : 'busy'),
            cost: undefined,
            className: '',
            tooltipContent: undefined,
            data: undefined
          };
          switch (prestation.name) {
            case 'Conversation': {
              benefit.text = 'Conversations Privées par CB';
              benefit.cost = this.consultantInfo.conversationcost;
              benefit.className = `fa-conv-${benefit.status}`;
              benefit.tooltipContent = `${tooltipContents[benefit.status]} conversation privée`;
              break;
            }
            case 'Audiotel': {
              benefit.text = 'Conversations par Téléphone';
              benefit.tooltipContent = `${tooltipContents[benefit.status]} Audiotel`;
              break;
            }
            case 'Chat': {
              benefit.text = 'Voyance par CHAT';
              benefit.cost = this.consultantInfo.chatcost;
              benefit.className = `fa-chat-${benefit.status}`;
              benefit.tooltipContent = `${tooltipContents[benefit.status]} Chat`;
              break;
            }
            case 'Mail': {
              benefit.text = 'Voyance par MAIL';
              benefit.cost = this.consultantInfo.mailcost;
              benefit.className = `fa-mail-${benefit.status}`;
              benefit.tooltipContent = `${tooltipContents[benefit.status]} Mail`;
              break;
            }
            default:
              break;
          }
          if (benefit.status === 'busy') {
            benefit.tooltipContent = tooltipContents[benefit.status];
          }
          this.benefits.push(benefit);
        });
      },
      error => {
          console.log(error);
      }


    );

  }

  getClickedConsultantId(consultandId, consultantName, status , serviceType) {
    const supportedservices = ['audiotel' , 'conversation'];
    const statusList = ['available' , 'je consulte immediatement'];
    if (statusList.includes(status.toLowerCase()) && supportedservices.includes(serviceType.toLowerCase())) {

      const conditionAudiotel = this.benefits.find(({type, status}) => type === 'audiotel' && status === 'available');
      if (conditionAudiotel) {
        this.activateLink = true ;
        this.consultantservice.showSVAModalAction(this.activateLink);

      } else if (!this.isLoggedIn && !this.isValidtoken) {
        $('#loginModal').modal('show');
      } else {
        if (this.isPrincipalCallNumberValidate === true) {
          this.consultantId$.next(consultandId);
          this.consultantName$.next(consultantName);
          localStorage.setItem('selectedConsultantId', consultandId);
          localStorage.setItem('consultantName' , consultantName);

          this.checkCard();

        } else {
          this.accountService.showPhoneAlert();
        }
      }
    }

  }

  ModalConsultantReviews() {
    this.noticeService.showAddNotice.subscribe(res => {
      this.showAddNotice = res;
    });

    $('#modalConsultantReviews').modal('show');
  }

  convertCurrency(currency: string) {
    switch (currency.toLowerCase()) {
      case 'eur': return '€';
      case 'usd': return '$';
    }
    return currency.toLowerCase();
  }


  // check if there is any registred card
  checkCard() {
    this.loginService.sharedUser.subscribe(
      (customerId: any) => {
        this.customerId = customerId;
      },
      error => {
        console.log(error);

      }
    );
    this.paiementService.sendGetcards(this.customerId, this.merchandId).subscribe(
      (res: any) => {

        if (res.statusCode === undefined) {

          this.emptyCard = true;
          for (const card of res.data.cards) {
            if (card.active === true) {
              this.emptyCard = false;
            }
          }

          if (this.emptyCard !== true) {
            $('#converationSelect').modal('show');
          } else {
            $('#addPaymentCardNotice').modal('show');
          }

        } else {
          $('#converationError').modal('show');
        }

      },
      err => {
        console.log(err);
        $('#converationError').modal('show');
      }
    );
  }

  showSVAModal(time = null , service = null) {
    if (time === 'Maintenant' && service === 'audiotel') {
       this.activateLink = true;
    }
    this.consultantservice.showSVAModalAction(this.activateLink);
  }
}
