<div class="container mt-4 my-4" xmlns:svg="">
<div class="row justify-content-center mb-5 mt-5">
  <div class="flex_etapes d-flex justify-content-center w-100">
    <div class="first etape_out d-flex flex-column justify-content-center m-1 w-100">
      <div class="card card-dark bg-light card-steps-text ">
        <div class="card-body ">
          <div class="row font-weight-normal">
            <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
              <p *ngIf="isLogging;else elseBlock1" class="card-text steps-text ">
                Voyance immédiate en 3 étapes
              </p>
              <ng-template #elseBlock1><p class="card-text steps-text">Voyance immédiate en 2 étapes </p></ng-template>
            </div>
            <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
              <img [ngClass]="{'img-mavoyance-2':!isLogging,'img-mavoyance':isLogging}" src="/assets/images/mavoyance-01.png">
              <p class="card-text steps-text ">1 Je choisis un voyant.
              </p>
            </div>

            <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}" *ngIf="isLogging">
              <img class=" img-mavoyance" src="/assets/images/mavoyance-02.png">
              <p class="card-text steps-text ">2 Je crée ou je me connecte sur mon compte.
              </p>

            </div>
            <div [ngClass]="{'col-sm-3':!isLogging ,'col-sm-2':isLogging}">
              <img *ngIf="isLogging" class=" img-mavoyance" src="/assets/images/mavoyance-03.png">
              <p *ngIf="isLogging;else elseBlock2" class="card-text steps-text"> 3 Je commence la
                consultation.</p>
              <ng-template #elseBlock2>
                <img  [ngClass]="{'img-mavoyance-2':!isLogging ,'img-mavoyance':isLogging}" src="/assets/images/mavoyance-02.png">
                <p class="card-text steps-text">2 Je commence la consultation.</p>
              </ng-template>
            </div>
            <div [ngClass]="{'col-sm-3':!((isLoggedIn$ | async) === false && !isValidtoken) ,'col-sm-2':(isLoggedIn$ | async) === false && !isValidtoken}">
              <p class="card-text steps-text">Fin de l'appel : je serai débité du temps passé. </p>
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>

</div>



    <div class="row justify-content-center mb-2 mt-2">
      <div class="col-md-4 mb-3 search-expert">
        Rechercher un expert<br>
        <div class="mt-2">
        <input type="text" class="mr-2 form-control input-search-mobiyo" id="consultant"(keyup)="addFilter($event)" maxlength="30" placeholder="Nom du consultant">
      </div>
      </div>
      <div class="col-md-4 mb-3 text-center">
      Comment souhaitez-vous consulter ? <br>
        <div class="mt-2 ml-4">
        <a class="nav-link-mobiyo"><span class="fa fa-phone fa-audiotel-unavailable consultation_modes icon-phone-search-mobiyo mr-4"  id="Audiotel" style="" (click)="addFilter($event)"  data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top" [ngbTooltip]="consultationModesTitle['AUDIOTEL']" ></span></a>
        <a class="nav-link-mobiyo"><span class="fa-conv-unavailable consultation_modes consultation-modes-icon-mobiyo mr-4" id="Conversation" (click)="addFilter($event)"  data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top" [ngbTooltip]="consultationModesTitle['CB']"></span></a>
        <a class="nav-link-mobiyo"><span class="fa-chat-unavailable consultation_modes consultation-modes-icon-mobiyo mr-4" id="Chat" (click)="addFilter($event)" data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top" [ngbTooltip]="consultationModesTitle['CHAT']"></span></a>
        <a class="nav-link-mobiyo"><span class="fa-mail-unavailable consultation_modes consultation-modes-icon-mobiyo mr-4" id="Mail" (click)="addFilter($event)" data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top" [ngbTooltip]="consultationModesTitle['MAIL']"></span></a>
        </div>
      </div>
      <div class="col-md-4 mb-3 text-center">
        En quelle langue souhaitez-vous consulter ? <br>
        <div class="mt-2">
<!--        <a class="nav-link-mobiyo"><i aria-hidden="true" class="fa-fr flag-consultant-details mr-2 search-flag-mobiyo flag-border languages_types" data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top" [ngbTooltip]="langTitle['FRLANG']"  id="FR"></i></a>-->
        <a class="nav-link-mobiyo"><i aria-hidden="true" class="fa-en flag-consultant-details mr-5 search-flag-mobiyo languages_types" id="EN" data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top" [ngbTooltip]="langTitle['EN']" (click)="addFilter($event)"></i></a>
        <a class="nav-link-mobiyo"><i aria-hidden="true" class="fa-es flag-consultant-details search-flag-mobiyo languages_types" id="ES" data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top" [ngbTooltip]="langTitle['ES']" (click)="addFilter($event)"></i></a>
        </div>
      </div>
    </div>

  <div class="row justify-content-center mb-1 mt-1">

    <div class="col-md-12">
      <a class="mr-2 nav-link nav-link-mobiyo active-link" style="font-size:18px;padding-left: 0px;" (click)="showVoyanceType()">Recherche avancée <i class="fa fa-chevron-down" id="search-advanced-icon"></i></a>
    </div>
  </div>

  <div class="row justify-content-center mb-2 mt-2 row-voyance-types"  style="display: none">
    <div class="col-md-12 mb-4">
      Quel type de voyance souhaitez-vous obtenir ?
    </div>
  </div>

  <div class="row justify-content-center mb-2 mt-2 row-voyance-types" id="row-voyance-types" style="display: none">
    <div class="col-md-12 text-center">
        <a id="tarologist_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo" (click)="addFilter($event)" style=""><span>Tarot</span></a>
        <a id="runes_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto" (click)="addFilter($event)">Runes</a>
        <a id="astrologer_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto" (click)="addFilter($event)">Astrologie</a>
        <a id="fortune_teller_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto" (click)="addFilter($event)">Cartomancie</a>
        <a id="numerologist_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto" (click)="addFilter($event)">Numérologie</a>
    </div>
  </div>

  <div class="row justify-content-center mb-5 mt-1 row-voyance-types" style="display: none">
    <div class="col-md-12 text-center">
      <a id="psychic_seer_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto" (click)="addFilter($event)">Voyant</a>
      <a id="pendulum_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto" (click)="addFilter($event)">Pendule</a>
      <a id="medium_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto" (click)="addFilter($event)">Medium</a>
      <a id="dowsing_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto" (click)="addFilter($event)">Radiesthésiste</a>
      <a id="oracle_type" class="badge badge-light badge-unactive-mobiyo voyance_types mr-4 nav-link nav-link-mobiyo my-auto" (click)="addFilter($event)">Oracles</a>
    </div>
  </div>




<div class="row row-landscape ">
  <div class="col-md-12 text-center mt-5" *ngIf="listData?.length==0"><label class="label-title-mobiyo">Aucun consultant trouvé</label></div>

  <div class="col-md-3"  *ngFor="let number of [1,2,3,4,5,6,7,8]">
    <ngx-content-loading
      [speed]="'1500ms'"
      [width]="1000"
      [height]="2000"
      *ngIf="loading"
      >
      <svg:g ngx-rect width="1000" height="1230" y="70" x="70" rx="10" ry="10"></svg:g>
      <svg:g ngx-rect width="900" height="200" y="1350" x="90" rx="100" ry="100"></svg:g>
      <svg:g ngx-circle cy="1650" cx="100" r="50" ry="5"></svg:g>
      <svg:g ngx-rect width="800" height="100" y="1600" x="170" rx="50" ry="50"></svg:g>
      <svg:g ngx-circle cy="1800" cx="100" r="50" ry="5"></svg:g>
      <svg:g ngx-rect width="800" height="100" y="1750" x="170" rx="50" ry="50"></svg:g>
      <svg:g ngx-rect width="700" height="100" y="1900" x="70" rx="50" ry="50"></svg:g>
    </ngx-content-loading>
  </div>


  <div *ngFor="let consultant of listData " class="col-md-3 col-sm-3 col-xs-3 mt-3 col-sm-3-mobiyo" (mouseover)="mouseOverLang()">

    <div *ngIf="consultant.isvalidconsultant">
      <div class="card card-mobiyo card-Landscape">
        <div class="card-top card-top-mobiyo">
          <!-- statut-->
          <div *ngIf="consultant.statutCode === 0; else elseBlock">
            <div [ngClass]="[consultant.statutColor]" class="text-white p-1 card-status">
              <a class="text-white btn-details"
                 (click)="redirectToConsultant(consultant.consultantid)">{{consultant.statut}}</a>
            </div>
          </div>
          <ng-template #elseBlock>
            <div [ngClass]="[consultant.statutColor]" class="text-white p-1 card-status "
                 >
              {{consultant.statut}}
            </div>
          </ng-template>
          <a class="btn-details"
             (click)="redirectToConsultant(consultant.consultantid)">
            <img alt="{{consultant.consultantlogin}}" class="card-img-top bg-light card-status"
                 src="{{consultant.consultantpictureurl || 'assets/images/male.png'}}">
          </a>
        </div>

        <div class="bg-pink card-info">
          <div class="text-left card-top-lang">
               <span *ngFor="let lang of consultant.language" data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top"
                     [ngbTooltip]="langTitle[lang]"
               >
                 <i *ngIf="lang !== 'FR'" aria-hidden="true" class="fa-{{lang.toLocaleLowerCase()}}"></i>&nbsp;
               </span>
          </div>
          <div class="text-center card-top-pseudo">
            <a class="text-white btn-details"
               (click)="redirectToConsultant(consultant.consultantid)"
               >{{consultant.consultantlogin}}</a>
          </div>
        </div>


        <div class="card-body">
            <audio controls="" src="{{consultant.consultantsoundurl}}"  class="audio-icon" target="" controlsList="nodownload"></audio>
            <a class="text-black btn-details" (click)="redirectToConsultant(consultant.consultantid)">
              <p class="card-text mt-2 card-text-mobiyo" maxlength="0" >
                {{consultant.consultantdescription?.length > 20 ? (consultant.consultantdescription | slice:0:70) + '...' : (consultant.presentation) }}
              </p>
            </a>
          <hr>
          <h4 class="card-title card-title-mobiyo">
            <span>
              <ngb-rating [rate]="consultant.reviewnote || 0" class="text-warning"></ngb-rating>
              <span class="text-right card-review-number" >
                              {{ consultant?.nbconsultation || 0 }} Consultation
              </span>
            </span>
            <a [ngClass]="{'disabled': !(consultant?.reviewnote > 0)}"
               (click)="ModalConsultantReviews(consultant)"
               [routerLink]=""
              class="rating-mobiyo review-link"
              queryParamsHandling="preserve" style="font-size: 11px;">Consulter les avis
              de {{consultant.consultantlogin}} ?</a>
          </h4>


        </div>
        <div class="card-footer p-0 card-footer-mobiyo">
          <h5 class="text-center mt-2" *ngIf="consultant.prestationSorted.length>0">Mes Prestations</h5>
          <div class="row row-cols-2  px-3 pb-2 align-items-stretch">
            <div *ngFor="let item of consultant.prestationSorted" class="col col-service px-2 mt-2">

              <!-- con-->
              <div
                (mouseover)="mouseOver(consultant.statutCode, item.active)"
                *ngIf="item.name ==='Conversation'"
                [ngClass]="{
                  'service-available': item.active === 1 && consultant.statutCode === 0,
                  'service-busy': consultant.statutCode === 1 && item.active === 1,
                  'service-unavailable': item.active === 0
                }"
                class="rounded p-2"
                style="min-height: 52px;"
              >
                <ng-template #tootipConvContent>
                  <p *ngIf=" consultant.statutCode === 0 && item.active === 1 "> Je suis disponible en conversation
                    privée</p>
                  <p *ngIf="consultant.statutCode === 1 && item.active === 1 "> Je suis actuellement en consultation</p>
                  <p *ngIf="item.active === 0"> Je ne suis pas disponible en conversation privée</p>


                </ng-template>
                <div *ngIf="activateLink; else elseBlock">  <a class="text-black btn-details" (click)="redirectToConsultant(consultant.consultantid)" ><div [ngbTooltip]="tootipConvContent" class="d-flex align-items-center" placement="right"
                                                                              tooltipClass="{{this.tooltipClass}}">
                  <span
                    [ngClass]="{
                      'fa-conv-available': consultant.statutCode === 0,
                      'fa-conv-busy': consultant.statutCode === 1,
                      'fa-conv-unavailable': item.active === 0
                    }"
                    class="icon-service-type mr-1"
                  >
                  </span>
                  <span class="price-service-type text-nowrap">
                    {{consultant.conversationcost}}{{consultant.currency?.replace('EUR', '€')}}/min
                  </span>
                </div></a></div>
                <ng-template #elseBlock><div [ngbTooltip]="tootipConvContent" class="d-flex align-items-center" placement="right"
                                                            tooltipClass="{{this.tooltipClass}}">
                  <span
                    [ngClass]="{
                      'fa-conv-available': consultant.statutCode === 0,
                      'fa-conv-busy': consultant.statutCode === 1,
                      'fa-conv-unavailable': item.active === 0
                    }"
                    class="icon-service-type mr-1"
                  >
                  </span>
                  <span class="price-service-type text-nowrap">
                    {{consultant.conversationcost}}{{consultant.currency?.replace('EUR', '€')}}/min
                  </span>
                </div></ng-template>

              </div>
              <!-- chat -->
              <div
                (mouseover)="mouseOver(consultant.statutCode , item.active )"
                *ngIf="item.name ==='Chat'"
                [ngClass]="{
                 'service-available': item.active === 1  && consultant.statutCode === 0 ,
                 'service-busy':consultant.statutCode === 1 && item.active === 1,
                 'service-unavailable':item.active === 0
                }"
                class="rounded p-2"
                style="min-height: 52px;"
              >
                <ng-template #tootipChatContent>
                  <p *ngIf="consultant.statutCode === 0 && item.active === 1"> Je suis disponible en Chat</p>
                  <p *ngIf="consultant.statutCode === 1 && item.active === 1 "> Je suis actuellement en
                    consultation </p>
                  <p *ngIf="item.active === 0"> Je ne suis pas disponible en Chat</p>

                </ng-template>
                <div
                     [ngbTooltip]="tootipChatContent"
                     class="d-flex align-items-center"
                     placement="right"
                     tooltipClass="{{this.tooltipClass}}"

                >
                  <span
                    [ngClass]="{
                      'fa-chat-available': consultant.statutCode === 0,
                      'fa-chat-busy': consultant.statutCode === 1,
                      'fa-chat-unavailable': item.active === 0
                    }"
                    class="icon-service-type mr-1"
                  ></span>
                  <span
                    class="price-service-type ">{{consultant.chatcost}}{{consultant.currency?.replace('EUR', '€')}}
                    /min</span>
                </div>
              </div>
              <!---audiotel -->
              <div
                (mouseover)="mouseOver(consultant.statutCode , item.active)"
                *ngIf="item.name ==='Audiotel'"
                [ngClass]="{
                  'service-available': item.active === 1  && consultant.statutCode === 0,
                  'service-busy':consultant.statutCode === 1 && item.active === 1,
                  'service-unavailable':item.active === 0
                }"
                class="rounded p-1 text-center h-100"
                style="min-height: 52px;"
              >
                <ng-template #tootipAudiotelContent>
                  <p *ngIf="consultant.statutCode === 0 && item.active === 1"> Je suis disponible en Audiotel</p>
                  <p *ngIf="consultant.statutCode === 1 && item.active === 1"> Je suis actuellement en consultation</p>
                  <p *ngIf="item.active === 0"> Je ne suis pas disponible actuellement en Audiotel</p>
                </ng-template>

                <div
                  [ngbTooltip]="tootipAudiotelContent"
                  placement="right"
                  class="d-flex h-100 align-items-center"
                  tooltipClass="{{this.tooltipClass}}"
                  [ngClass]="{'btn': consultant.statutCode === 0 && item.active === 1  }"
                  (click)="showSVAModal(activateLink)"
                >
                  <app-sva
                    *ngIf="definitions | async"
                    [definitions]="definitions | async"
                    [full]="true"
                    [zoom]="40"
                    [isLinkActive] = "activateLink"
                    class="mx-auto"
                    [ngClass]="{'ml-mobiyo': consultant.statutCode === 0 && item.active === 1 }"
                  ></app-sva>
                </div>


              </div>
              <!--mail-->
              <div
                (mouseover)="mouseOver(consultant.statutCode , item.active)"
                *ngIf="item.name === 'Mail'"
                [ngClass]="{
                  'service-available': item.active === 1 && consultant.statutCode === 0,
                  'service-busy': consultant.statutCode === 1 && item.active === 1,
                  'service-unavailable': item.active === 0
                }"
                class="rounded p-2"
                style="min-height: 52px;"
              >
                <ng-template #tootipMailContent>
                  <p *ngIf="consultant.statutCode === 0 && item.active === 1"> Je suis disponible en Mail</p>
                  <p *ngIf="consultant.statutCode === 1 && item.active === 1"> Je suis actuellement en consultation </p>
                  <p *ngIf="item.active === 0 "> Je ne suis pas disponible en Mail</p>
                </ng-template>
                <div [ngbTooltip]="tootipMailContent" class="d-flex align-items-center" placement="right"
                     tooltipClass="{{this.tooltipClass}}">
                  <span
                    [ngClass]="{
                      'fa-mail-available': item.active === 1 && consultant.statutCode === 0,
                      'fa-mail-busy':consultant.statutCode === 1 && item.active === 1,
                      'fa-mail-unavailable':item.active === 0
                    }"
                    class="icon-service-type mr-1"
                  ></span>
                  <span
                    class="price-service-type">{{consultant.mailcost}} {{consultant.currency?.replace('EUR', '€')}}</span>
                </div>
              </div>
            </div>

          </div>


        </div>

      </div>


    </div>
  </div>
</div>
</div>

<!-- Modal consultant reviews -->

<div class="modal fade" id="modalConsultantReviews" tabindex="-1" role="dialog" aria-labelledby="ModalessageLabel" aria-hidden="false" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{consultantInfo?.consultantlogin}}&nbsp;
          <ngb-rating *ngIf="consultantInfo?.reviewnote>0" class="text-warning" [rate]="consultantInfo?.reviewnote"></ngb-rating>&nbsp;
          <span *ngIf="consultantInfo?.reviewcount>0">({{consultantInfo?.reviewcount}} avis)</span>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="showAllNotice">
        <app-show-reviews
          *ngIf="consultantInfo"
          id="allNotices"
          [consultantId]="consultantInfo.consultantid"
          [consultantImageUrl]="consultantInfo.consultantpictureurl"
        >
        </app-show-reviews>
      </div>
      <div *ngIf="showAddNotice" class="p-4">
        <app-add-review
          *ngIf="consultantInfo"
          id="addNotice"
          [consultantId]="consultantInfo.consultantid"
          [consultantName]="consultantInfo.consultantlogin"
          [closeModal] = false
          [redirect]= false
          [numberOfCall] = 1
        ></app-add-review>
      </div>
    </div>
  </div>
</div>

<div aria-hidden="false" aria-labelledby="modalConsultantReviewsErrorLabel" class="modal fade" data-backdrop="static"
     id="modalConsultantReviewsError" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalConsultantReviewsErrorLabel">Les avis</h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>Une erreur vient de survenir et {{(definitions | async)?.sms_brand}} n'est pas en mesure de visualiser les avis.</p>
            <p>Merci d'essayer de nouveau plus tard !</p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a [routerLink]="['/']" class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>


<!-- Modal modalConsultantAddReviewsError  -->

<div aria-hidden="false" aria-labelledby="modalConsultantAddReviewsErrorLabel" class="modal fade" data-backdrop="static"
     id="modalConsultantAddReviewsError" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalConsultantAddReviewsErrorLabel">Donner son avis</h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>Une erreur vient de survenir et {{(definitions | async)?.sms_brand}} n'est pas en mesure de prendre en compte votre avis !</p>
            <p>Merci d'essayer de nouveau plus tard !</p>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a [routerLink]="['/']" class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>


<!-- Modal modalShowAutioTel  -->
<div class="modal modalShowAutioTelDisplay fade" id="modalShowAutioTel"  tabindex="-1" role="dialog" aria-labelledby="modalShowAutioTelLabel" aria-hidden="false" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalShowAutioTelLabel">Conversations par Téléphone</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <p>Vous pouvez appeler votre consultant sur le {{(definitions | async)?.sva_number}}</p>
            <div class="text-center">
              <app-sva
                *ngIf="!!(definitions | async)"
                [definitions]="definitions | async"
                [zoom]="75"
              ></app-sva>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a class="btn btn-success text-center" data-dismiss="modal">Continuer</a>
      </div>
    </div>
  </div>
</div>

