import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Account} from '../../_models/account';
import {Definitions} from '../../_models/definitions';
import {Session} from '../../_models/session';
import {Router} from '@angular/router';
import {LoginService} from '../../_services/login.service';
import {HomeService} from '../../_services/home.service';
import {PaiementService} from '../../_services/paiement.service';
import {RegisterService} from '../../_services/register.service';
import {DefinitionService} from '../../_services/definition.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {AccountService} from '../../_services/account.service';
import {SessionService} from '../../_services/session.service';
import {CookieService} from 'ngx-cookie-service';
import {PromotionService} from '../../_services/promotion.service';

declare var $: any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  isLoggedIn: boolean;
  customerId: number;
  customerSessionId: string;
  account: Observable<Account>;
  definitions: Observable<Definitions>;
  session: Observable<Session>;

  constructor(
    public router: Router,
    private loginService: LoginService,
    public homeService: HomeService,
    public paiementService: PaiementService,
    private registerService: RegisterService,
    private definitionService: DefinitionService,
    private store: Store<AppState>,
    private accountService: AccountService,
    private sessionService: SessionService,
    private cookieService: CookieService,
    private promotionService: PromotionService
  ) {
    this.account = store.select('account');
    this.definitions = store.select('definitions');
    this.session = store.select('session');

    this.session.subscribe(({isLoggedIn, customerSessionId, customerId}) => {
      this.isLoggedIn = isLoggedIn;
      this.customerId = customerId;
      this.customerSessionId = customerSessionId;
      if (this.customerSessionId && this.customerId && !this.isLoggedIn) {
        console.log(this.customerSessionId, this.customerId, this.isLoggedIn)
        this.checkSessionIsActive(this.customerSessionId);
      }
    });
  }

  ngOnInit(): void {
  }

  confirmLogout() {
    $('#Modalessage').modal('show');
  }

  onLogout() {
    this.loginService.logout();
  }

  checkSessionIsActive(sessionId = localStorage.getItem('customerSessionId')) {
    this.loginService.checkSession(sessionId).subscribe(
      (result: any) => {
        if (result.isSessionActive === false) {
          this.onLogout();
        } else {
          this.sessionService.login(sessionId, this.customerId);
          this.fetchConnectedAccount();
        }
      },
      (error: any) => {
        console.error('error : ', error);
      }
    );
  }


  fetchConnectedAccount() {
    this.loginService.getcurrentUser(
      localStorage.getItem('customerSessionId'),
      JSON.parse(localStorage.getItem('customerId'))
    ).subscribe(
      (result: any) => {
        delete result.headers;
        this.accountService.init(result);
      },
      error => {
        console.error('error : ', error);
      }
    );
  }

  mouseover() {
      this.promotionService.fetchPromotions('Deconnexion');
  }
}
