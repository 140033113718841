import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NoticeService {

  apiUrl = environment.apiUrlNotice;
  public showAddNotice: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showAllNotice: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public transactionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showAddNoticeEndCall: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);



  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  consultantNotice(merchantId, consultantId) {
    const params = new  HttpParams({
      fromObject : {
        merchantId,
        consultantId
      }
    });
    return this.http.get(`${this.apiUrl}/consultant-notice-get`, {params});
  }

  consultantReviews(merchantId, consultantId) {
    const params = new  HttpParams({
      fromObject : {
        merchantId,
        consultantId
      }
    });
    return this.http.get(`${this.apiUrl}/consultant-reviews-get`, {params});
  }

  customerTransactions(merchantId, customerId, sessionId, consultantId) {
    const params = new  HttpParams({
      fromObject : {
        merchantId,
        customerId,
        sessionId,
        consultantId,

      }
    });
    return this.http.get(`${this.apiUrl}/customer-transactions-get`, {params});
  }

  customerNoticeSet(formData) {
    return this.http.post(`${this.apiUrl}/customer-notice-set`, formData);
  }

  customerNoticeEnable(formData) {
    return this.http.post(`${this.apiUrl}/customer-notice-enable`, formData);
  }

  customerNoticeCustomerUpdate(formData) {
    return this.http.post(`${this.apiUrl}/customer-notice-customer-update`, formData);
  }
  


}
