import {Component, Input, OnInit} from '@angular/core';
import {Definitions} from '../_models/definitions';
import {environment} from '../../environments/environment';
import {ProfileService} from '../_services/profile.service';
import * as phoneUtil from 'google-libphonenumber';
import {HomeService} from '../_services/home.service';
declare var $: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  @Input() definitions: Definitions;
  @Input() customerId: number;
  @Input() customerEmail: string;
  @Input() customerPhone: string;
  @Input() customerPhoneValidate: boolean;
  @Input() customerSecondPhone: string;
  loader = false;
  error = false;

  constructor(
    private profileService: ProfileService,
    public homeService: HomeService
  ) { }

  ngOnInit(): void {
    $('#email-notif-modal, #phone-notif-modal')
      .on('hide.bs.modal', () => {
        return !this.loader;
      })
      .on('hidden.bs.modal', () => {
        this.reset();
      });
  }

  validateEmail(): void {
    this.loader = true;

    const baseUrl = window.location.origin + location.pathname;
    const tokenData = {
      customerId: this.customerId,
      commandToken: 'VALIDATE-MAIL'
    };
    const mailData = {
      merchantId: environment.merchantId,
      recipientMail: this.customerEmail,
      templateParams: {url: baseUrl + '?to='},
      template: 'url_template_account_activation',
      subject: 'Activation de votre compte',
      senderMail: this.definitions.provider_mail
    };

    this.profileService.createToken(tokenData)
      .subscribe(
        (result: any) => {
          if (result.statusCode === undefined && result.customerToken) {
            mailData.templateParams.url += result.customerToken;
            this.profileService.mailSend(mailData)
              .subscribe(
                (res: any) => {
                  if (res.statusCode === undefined) {
                    this.loader = false;
                    $('#email-notif-modal').modal('hide');
                  } else {
                    console.error(res?.body);
                    this.next(false);
                  }
                },
                error => {
                  console.error(error);
                  this.next(false);
                }
              );
          } else {
            console.error(result?.body || 'Token error : ' + JSON.stringify(result));
            this.next(false);
          }
        },
        error => {
          console.error(error);
          this.next(false);
        }
      );
  }

  private next(status: boolean): void {
    this.error = !status;
    if (status === false) {
      this.loader = false;
    }
  }

  private reset(): void {
    this.error = false;
    this.loader = false;
  }

  formatPhone(customerPhone) {
    if (customerPhone) {
      const phoneNumber = phoneUtil.PhoneNumberUtil.getInstance();
      const PHONE = phoneNumber.parseAndKeepRawInput(customerPhone, 'FR');
      const flag = 'flag-icon-' + phoneNumber.getRegionCodeForNumber(PHONE).toLowerCase();
      const phone = '0' + PHONE.getNationalNumber();
      return `<span class="flag-icon ${flag}"></span> ${phone}`;
    } else {
      return '';
    }
  }
  checkRedirect() {
    if (this.customerPhoneValidate) {
      this.homeService.redirect('profile');
    } else {
      this.homeService.redirect('phone');
    }
  }
}
