<div class="col-md-8">
  Mon heure de naissance : {{ textBirthTime() }}
</div>
<div class="col-md-4">
  <button type="button" class="btn btn-warning info-add-update-btn-mobiyo" data-toggle="modal" data-target="#add-birth-time-modal" [disabled]="!birthInfo">
    <ng-container *ngIf="this.birthInfo;else second">Modifier</ng-container>
    <ng-template #second>
      Ajouter
    </ng-template>
  </button>
</div>
<div class="modal fade" id="add-birth-time-modal" tabindex="-1" aria-labelledby="add-birth-time-modal-label" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="add-birth-time-modal-label">
          <span *ngIf="!birthInfo">Ajouter mon heure de naissance</span>
          <span *ngIf="birthInfo">Corriger mon heure de naissance</span>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body" *ngIf="!success && !error">
          <p *ngIf="!birthInfo">
            Si vous voulez renseigner votre heure de naissance, vous pouvez le faire ci-dessous. N'oubliez pas de cliquer sur le bouton "Enregistrer les modifications" quand vous avez terminé.
          </p>
          <p *ngIf="birthInfo">
            Si Vous voulez corriger votre heure de naissance, vous pouvez le faire ci-dessous. N'oubliez pas de cliquer sur le bouton "Enregistrer les modifications" quand vous avez terminé.
          </p>
          <div class="form-group">
            <label for="birth-time" *ngIf="!birthInfo">Ajouter heure</label>
            <label for="birth-time" *ngIf="birthInfo">Corriger heure</label>
            <select id="birth-time" class="form-control" formControlName="customerBirthday">
              <option
                *ngFor="let hour of hours"
                value="{{ hour.value }}"
                [ngStyle]="{'font-weight': heads.includes(hour.value) ? 'bolder' : 'normal'}"
              >
                {{ hour.text }}
              </option>
            </select>
          </div>
          <div class="row" *ngIf="f.customerBirthday.errors?.invalid">
            <div class="col-2">
              <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
            </div>
            <div class="col-10 align-self-center">
              Votre heure de naissance est erroné !
            </div>
          </div>
        </div>
        <div class="modal-body" *ngIf="success">
          <div class="row">
            <div class="col-2">
              <i class="fa fa-check-circle fa-4x text-success"></i>
            </div>
            <div class="col-10 align-self-center">
              Votre heure de naissance est modifié.
            </div>
          </div>
        </div>
        <div class="modal-body" *ngIf="error">
          <div class="row">
            <div class="col-2">
              <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
            </div>
            <div class="col-10 align-self-center text-danger">
              Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer l'opération demandée.
            </div>
          </div>
          <br>
          <p>
            Merci de contacter directement {{infoDefinitions?.sms_brand}} au {{infoDefinitions?.provider_call_number}}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" type="submit" [disabled]="!form.valid || submitting" *ngIf="!success && !error">Enregistrer les modifications</button>
          <button type="button" class="btn btn-warning" data-dismiss="modal" *ngIf="success">J'ai compris</button>
          <button type="button" class="btn btn-warning" data-dismiss="modal" *ngIf="error">Continuer</button>
        </div>
      </form>
    </div>
  </div>
</div>
