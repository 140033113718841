import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Account} from '../../_models/account';
import {Definitions} from '../../_models/definitions';
import {Session} from '../../_models/session';
import {Router} from '@angular/router';
import {LoginService} from '../../_services/login.service';
import {HomeService} from '../../_services/home.service';
import {PaiementService} from '../../_services/paiement.service';
import {RegisterService} from '../../_services/register.service';
import {DefinitionService} from '../../_services/definition.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isLoggedIn: boolean;
  customerId: number;
  customerSessionId: string;
  account: Observable<Account>;
  definitions: Observable<Definitions>;
  session: Observable<Session>;
  conversionEncryptOutput: any;

  constructor(
    public router: Router,
    private loginService: LoginService,
    public homeService: HomeService,
    public paiementService: PaiementService,
    private registerService: RegisterService,
    private definitionService: DefinitionService,
    private store: Store<AppState>
  ) {
    this.account = store.select('account');
    this.definitions = store.select('definitions');
    this.session = store.select('session');
  }

  ngOnInit(): void {
  }

}
