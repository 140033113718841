import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PhoneService {

  apiUrl = environment.apiUrlAccountCustomer;


  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  // @todo Vérifier si encore utilisable
  validatePhone() {
    const customerSessionId = localStorage.getItem('customerSessionId');
    const customerId = localStorage.getItem('customerId');
    const apiUrl = 'https://vcb.dev.api.mobiyo.com/customer-account';
    this.http.post(`${apiUrl}/account-create-code`, {customerSessionId, customerId}).subscribe(
      (result: any) => {
        localStorage.setItem('customerSessionId', result.body.customerSessionId);
        console.log(result);
        console.log('success');
      },
      (error: any) => {
        console.log(error.message);
        console.log(error.status);
        console.log(error.statusText);
      });
  }

  updatePhone(formData) {
    return this.http.post(`${this.apiUrl}/account-update-phone`, formData);
  }

  createCode(formData) {
    return this.http.post(`${this.apiUrl}/account-create-code`, formData);
  }

  checkCode(formData) {
    return this.http.post(`${this.apiUrl}/account-check-code`, formData);
  }

  sendSMSCode(formData) {
    return this.http.post(`${this.apiUrl}/account-sms-send`, formData);
  }
  ivrPlayCode(formData) {
    return this.http.post(`${this.apiUrl}/account-ivr-play-code`, formData);
  }

  deleteSecondaryPhone(formData) {
    return this.http.post(`${this.apiUrl}/account-delete-phone`, formData);
  }

}
