import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import * as dayjs from 'dayjs';
import * as calendar from 'dayjs/plugin/calendar';
import * as isToday from 'dayjs/plugin/isToday';
import * as isTomorrow from 'dayjs/plugin/isTomorrow';
import * as objectSupport from 'dayjs/plugin/objectSupport';
declare var $: any;


@Injectable({
  providedIn: 'root'
})
export class ConsultantService {

  public consultantId = new BehaviorSubject(localStorage.getItem('selectedConsultantId'));
  public consultantCallNumber = new BehaviorSubject(localStorage.getItem('consultantCallNumber'));
  public consultantName = new BehaviorSubject(localStorage.getItem('consultantName'));
  public consultantCost = new BehaviorSubject(localStorage.getItem('cc'));

  private apiUrl = environment.apiUrlConsultant;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    dayjs.extend(isToday);
    dayjs.extend(isTomorrow);
    dayjs.extend(objectSupport);
  }

  sendGetConsutalnt(merchantId, officeId) {
    const params = new HttpParams({
      fromObject: {
        merchantId,
        officeId
      }
    });
    return this.http.get(`${this.apiUrl}/get-consultant`, {params});
  }

  sendGetConsutalntByID(merchantId, officeId, consultantId) {
    const params = new HttpParams({
      fromObject: {
        merchantId,
        officeId,
        consultantId
      }
    });
    return this.http.get(`${this.apiUrl}/get-consultant`, {params});
  }

  getConsultant(consultantId) {
    const params = new HttpParams({
      fromObject: {
        consultantId,
      }
    });

    return this.http.get(`${this.apiUrl}/get-consultant-by-id`, {params});
  }

  sendGetPlaning(merchantId, officeId, consultantId, planningDateStart, planningDateEnd) {
    const params = new HttpParams({
      fromObject: {
        merchantId,
        officeId,
        consultantId,
        planningDateStart,
        planningDateEnd
      }
    });
    const headers = new HttpHeaders().set('Content-Type', 'application/text');
    return this.http.get(`${this.apiUrl}/get-consultant`, {params});
  }

  formatTime(date) {
    if (date.format('HH:mm') === '00:00') {
      return 'minuit';
    }
    if (date.format('HH:mm') === '12:00') {
      return 'midi';
    }
    return date.format('mm') > 0 ? date.format('H:mm') : date.format('H') + 'H';
  }

  handle(data) {
    let date = null;
    let planning = null;
    let statut = 'ABSENT';
    let colorClass = 'bg-secondary';
    let statutCode = -1;
    let service = null;
    const costs = ([data.conversationcost, data.chatcost, data.mailcost]).filter(c => c !== null);
    const prestations = data.prestations.map(pres => ({
      name: pres,
      active: 0
    }));
    let days = Object.keys(data.planning);

    if (days.length > 0) {

      // trier les jours
      days = days.sort((a, b) => dayjs(a) < dayjs(b) ? -1 : 1);
      const day = days[0];
      let plannings = data.planning[day];

      // filter expired hours
 //     plannings = plannings.filter(p => !dayjs(day + ' ' + p.endHour).isBefore(dayjs()));
      // sort all planning day by hour
      plannings = plannings.sort((a, b) => dayjs(day + ' ' + a.startHour) < dayjs(day + ' ' + b.startHour) ? -1 : 1);

      if (plannings.length > 0) {
        planning = plannings[0];
        date = day + ' ' + planning.startHour;
      }
    } else if (data.returndate != null) {
      date = data.returndate;
    }

    if (date) {
      const now = dayjs();
      // vérifier s'il est planifié en ce moment
      if (planning &&
        dayjs(date).isBefore(now) &&
        dayjs(date.replace(planning.startHour, planning.endHour)).isAfter(now)
      ) {

        if ((planning.serviceType.indexOf('audiotel') >= 0 || data.consultantstate === 1) && data.consultantavailable) {
          statut = 'JE CONSULTE IMMEDIATEMENT';
          colorClass = 'bg-success';
          statutCode = 0;
        } else {
          statut = 'EN CONSULTATION';
          colorClass = 'bg-warning';
          statutCode = 1;

        }
        // active service type (prestation)
        planning.serviceType.forEach(type => {
          const presta = prestations.find(p => p.name.toLowerCase() === (type.toLowerCase() === 'cb' ? 'conversation' : type.toLowerCase()));
          if (presta) {
            service = presta.name;
            presta.active = 1;
          }
        });
      } else if (dayjs(date) > dayjs().add(30, 'day')) {
        // absent si date de retour > 30 jours
        statut = 'ABSENT';
        date = null;
      } else if (dayjs(date).isAfter(now)) {
        statut = 'DE RETOUR ';
        statutCode = 2;

        // si aujourd'hui
        if (dayjs(date).format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
          statut += 'à ' + this.formatTime(dayjs(date));
        } else if (dayjs(date).format('YYYY-MM-DD') === dayjs().add(1, 'day').format('YYYY-MM-DD')) {
          statut += 'demain à ' + this.formatTime(dayjs(date));
        } else {
          statut += 'Le ' + dayjs(date).format('DD MMMM');
        }
      }
    }

    const sortServices = ['Conversation', 'Audiotel', 'Chat', 'Mail'];
    return {
      statut,
      colorClass,
      statutCode,
      date,
      service,
      cost: costs.length > 0 ? costs[0] + ' ' + data.currency.replace('EUR', '€') + ' / MIN' : '',
      prestations: prestations.sort((a, b) => sortServices.indexOf(a.name) > sortServices.indexOf(b.name) ? 1 : -1)
    };
  }

  handleplaningDate(plannings) {
    const planning = plannings;
    for (const date in plannings) {
      let consulations = plannings[date];
      let firstStartHour = '';
      consulations.forEach((consultation, index) => {
        const startHour = parseInt(consultation.startHour.split(':')[0], 10);
        const endHour = parseInt(consultation.endHour.split(':')[0], 10);
        let starthourConsult = dayjs(new Date(0, 0, 0, startHour)).format('H');
        let endHourConsult = dayjs(new Date(0, 0, 0, endHour)).format('H');

        let startText = '';

        if (dayjs(date).isToday() && starthourConsult === dayjs().format('H')) {
          starthourConsult = 'Maintenant';
          startText = 'Maintenant';
        } else if (starthourConsult === '0') {
          starthourConsult = 'minuit';
          startText = 'De minuit';
        } else if (starthourConsult === '12') {
          starthourConsult = 'midi';
          startText = 'De midi';
        } else {
          starthourConsult += 'H';
          startText = 'De ' + starthourConsult;
        }
        if (endHourConsult === '0') {
          endHourConsult = 'minuit';
        } else if (endHourConsult === '12') {
          endHourConsult = 'midi';
        } else {
          endHourConsult += 'H';
        }

        planning[date][index].hourText = {
          from: startText,
          to: `à ${endHourConsult}`
        };

        if (index === 0) {
          firstStartHour = starthourConsult;
        }

      });
      if (dayjs(date).isTomorrow() && firstStartHour !== '') {
        const yesterdayDate = dayjs(date).add(-1, 'day').format('YYYY-MM-DD');
        if (planning[yesterdayDate] === undefined) {
          planning[yesterdayDate] = [{
            hourText: {
              from: 'Ce consultant est de retour demain à ' + firstStartHour
            }
          }];
        }
      }
      consulations = this.sortArrayOfObject(consulations);
    }
    return planning;
  }

  private sortArrayOfObject(unordered: any[]): any[] {
    return unordered.sort((a, b) => {
      const keyA = parseInt(a.startHour.split(':')[0], 10);
      const keyB = parseInt(b.startHour.split(':')[0], 10);
      // Compare the 2 dates
      if (keyA < keyB) { return -1; }
      if (keyA > keyB) { return 1; }
      return 0;
    });
  }
  showSVAModalAction(activateLink) {
    if (activateLink) {
      const width = $(window).width();
      if (width < 768) {
        $('#modalShowAutioTel').modal('hide');
      } else {
        $('#modalShowAutioTel').modal('show');
      }
    }
  }
}
