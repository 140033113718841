<div class=" purchase-container mt-4">

  <div class="div-title">

    <div class="row align-items-center mb-4">
      <div class="col">
        <a class="btn btn-success-mobiyo btn-sm mr-5"  (click)="this.homeService.redirect('/')">< Revenir à la liste des Consultants</a>
      </div>
    </div>

    <h1 class="h3 mb-3 font-weight-normal d-inline-block">
      Mes achats
    </h1>
    <div class="dataTable-search">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"><i class="pi pi-search mr-2"></i></span>
        </div>
        <input (input)="dt.filterGlobal($event.target.value, 'contains')" class="form-control input-purchase-mobiyo" pInputText
               placeholder="Recherche globale" type="text"/>
      </div>

    </div>
    <div *ngIf="!(account | async) || !(definitions | async) || !purchases" class="spinner-border spinner-border-sm d-inline-block ml-2 mb-1"
         role="status">
      <span class="sr-only">Loading...</span>
    </div>

  </div>
  <p-table #dt [filterDelay]="0" [globalFilterFields]="['amount','partial_number','consultant_firstname','transaction_datetime','cost','service_duration']" [loading]="loading" [multiSortMeta]="multiSortMeta" [paginator]="true"
           [responsive]="true" [rowHover]="true" [rowsPerPageOptions]="" [rows]="10"
           [showCurrentPageReport]="true" [sortOrder]="-1"
           [value]="purchases"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           dataKey="id"
           sortField="transaction_datetime" styleClass="p-datatable-customers">
    <ng-template pTemplate="header">
      <tr class="th-p-data-table">
        <th class="th-p-date text-center" pSortableColumn="transaction_datetime" width="13%">Date
          <p-sortIcon field="transaction_datetime"></p-sortIcon>
        </th>
        <th class="th-p-prestation text-center">Prestation</th>
        <th class="th-p-avis text-center">Avis</th>
        <th class="th-p-consultant text-center">Consultant</th>
        <th class="th-p-price-per-min text-center" pSortableColumn="cost">Coût
          <p-sortIcon field="cost"></p-sortIcon>
        </th>
        <th pSortableColumn="service_duration" width="12%">Durée réelle
          <p-sortIcon field="service_duration"></p-sortIcon>
        </th>
        <th class="th-p-card text-center">Moyen de paiement</th>
        <th class="th-p-price text-center">Code Promo.</th>
        <th class="th-p-price text-center">Avantage</th>
        <th class="th-p-price text-center">Prix Normal</th>
        <th class="th-p-price text-center">Prix Payé</th>
        <th class="th-p-recl text-center">Réclamations</th>
        <th class="th-p-actions text-center">Actions</th>
      </tr>
    </ng-template>
    <ng-template let-purchase pTemplate="body">
      <tr class="p-selectable-row">
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Date</span>

          <span *ngIf="purchase.transaction_datetime">
       {{purchase.transaction_datetime.replaceAll('-', '/') | slice:0:16  | date:'dd/MM/yyyy' }}
            , à {{purchase.transaction_datetime.replaceAll('-', '/') | slice:0:16| date:'HH:mm'  }}
      </span>
        </td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Prestation</span>

          <ng-container *ngIf="purchase.transaction_type=='capture';else second1">
            Conversation Privée (CB)
          </ng-container>
          <ng-template #second1>
            <ng-container *ngIf="purchase.transaction_type=='refund';else third1">
              Remboursement
            </ng-container>
          </ng-template>
          <ng-template #third1>
            {{purchase.transaction_type}}
          </ng-template>
        </td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Avis</span>
          <ng-container *ngIf="purchase.is_notice_authorized;else second2">
            <a (click)="openReview(purchase)" class="btn-link col-sm-4 nav-link-mobiyo td-link" style="color:#1eb117;">
              Laisser un avis ?
            </a>
          </ng-container>
          <ng-template #second2>
            <ng-container
              *ngIf="purchase.customer_remark_status  ==='CUSTOMER_REMARK_CREATED' || purchase.customer_remark_status ==='CUSTOMER_REMARK_VALIDATED_FOR_CONSULTANT';else third2">
              <a (click)="openReview(purchase)" class="btn-link col-sm-4 nav-link-mobiyo td-link" style="color:#1eb117;">
                A lire
              </a>
            </ng-container>
          </ng-template>
          <ng-template #third2>
            <ng-container
              *ngIf="purchase.customer_remark_status ==='CUSTOMER_REMARK_READ' && purchase.consultant_answer_status !=='CONSULTANT_ANSWER_VALIDATED_FOR_CUSTOMER'  && purchase.consultant_answer_status !=='CONSULTANT_ANSWER_READ' ;else fourth2">
              <!-- add && customer_notice_notation_status = NOTATION_READ -->
              <a (click)="openReview(purchase)" class="btn-link col-sm-4 nav-link-mobiyo td-link" style="color:#1eb117;">
                Lu
              </a>
            </ng-container>
          </ng-template>
          <ng-template #fourth2>
            <ng-container
              *ngIf="purchase.consultant_answer_status ==='CONSULTANT_ANSWER_VALIDATED_FOR_CUSTOMER' || purchase.consultant_answer_status ==='CONSULTANT_ANSWER_CREATED';else fifth2">
              <a (click)="openReview(purchase)" class="btn-link col-sm-4 nav-link-mobiyo td-link" style="color:#1eb117;">
                Réponse à lire
              </a>
            </ng-container>
          </ng-template>
          <ng-template #fifth2>
            <ng-container *ngIf="purchase.consultant_answer_status ==='CONSULTANT_ANSWER_READ';else sixth2">
              <a (click)="openReview(purchase)" class="btn-link col-sm-4 nav-link-mobiyo td-link" style="color:#1eb117;">
                Réponse lue
              </a>
            </ng-container>
          </ng-template>
          <ng-template #sixth2>
            <a class="btn-link col-sm-4 nav-link-mobiyo disabled td-link">
              Laisser un avis ?
            </a>
          </ng-template>
        </td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Consultant</span>
          {{purchase.consultant_pseudo}}
        </td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Coût</span>
          <span *ngIf="purchase.cost | number:'1.2-2'">{{purchase.cost}} €/min</span></td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Durée réelle</span>

          {{purchase.service_duration}}
        </td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Moyen de paiement</span>
          {{purchase.partial_number}}</td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Code Promo.</span>
          {{purchase.code}}
        </td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Avantage</span>
          <ng-container *ngIf="purchase.nature=='DURATION';else nature1">
              + {{purchase.nature_value}} min.
          </ng-container>
          <ng-template #nature1>
            <ng-container *ngIf="purchase.nature=='AMOUNT';else nature2">
              - {{purchase.nature_value}} €
            </ng-container>
          </ng-template>
          <ng-template #nature2>
            <ng-container *ngIf="purchase.nature=='PERCENTAGE';else nature3">
              - {{purchase.nature_value}} %
            </ng-container>
          </ng-template>
          <ng-template #nature3>

          </ng-template>
        </td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title">Prix Normal</span>
          <span class="datatable-td-price-mobiyo" *ngIf="purchase.original_amount">{{purchase.original_amount | number:'1.2-2' }} €</span>
        </td>
        <td class="datatable-td-mobiyo text-center">
          <span class="p-column-title ">Prix Payé</span>
          <span class="datatable-td-price-mobiyo">{{purchase.amount==null ? 0 : purchase.amount | number:'1.2-2' }} €</span></td>
        <td class="datatable-td-mobiyo">
          <span class="p-column-title">Réclamations</span>

          <a (click)="requestClaim(purchase)" class="btn-link col-sm-4 nav-link-mobiyo td-link" style="color:#1eb117;">
            <ng-container *ngIf="purchase.complaint_description;else second3">Voir</ng-container>
            <ng-template #second3>
              Un problème ?
            </ng-template>
          </a>
        </td>
        <td class="datatable-td-mobiyo text-center" *ngIf="purchase.transaction_type=='capture'">
          <span class="p-column-title">Actions</span>

          <i class="pi pi-download" data-toggle="tooltip" data-placement="top" tooltipClass="tooltip-available tlp-top"
             [ngbTooltip]="downloadPaymentTitle" (click)="downloadPayment(purchase)"></i>
        </td>
        <td class="td-separator">
<!--          <hr>-->
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="13">Aucun achat trouvé</td>
      </tr>
    </ng-template>
  </p-table>

</div>
<!-- Modal Claim -->

<div aria-hidden="false" aria-labelledby="clickWebLinkSuccessLabel" class="modal fade" id="modalClaim" role="dialog"
     tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="clickWebLinkSuccessTitle">Mes Achats</h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="stepClaim===0" class="modal-body">
        <div class="row">
          <fieldset class="border fieldset-info-mobiyo fieldset-update-info-mobiyo">
            <legend class="w-auto pl-2 pr-2 legend-first-name">
              Votre réclamation :
            </legend>
            <p class="ml-4">Date :
              le {{purchase?.complaint_datetime.replaceAll('-', '/') | slice:0:16| date:'dd/MM/yyyy'}},
              à {{purchase?.complaint_datetime.replaceAll('-', '/') | slice:0:16| date:'HH:mm'}}</p> <br>
            <p class="ml-4">Réclamation : {{purchase?.complaint_description}}</p>
          </fieldset>
        </div>
      </div>
      <div *ngIf="stepClaim===1" class="modal-body">
        <div class="row">
          <div class="col-md-12">


            <p>Vous avez demandé à informer {{definitionsInfo?.sms_brand}} d'un problème !</p>
            <p>Voulez-vous vraiment laisser un message à {{definitionsInfo?.sms_brand}} ?</p>

          </div>
        </div>
      </div>
      <form (ngSubmit)="onSubmit()" [formGroup]="formClaim">
        <div *ngIf="stepClaim===2" class="modal-body">
          <div class="tabs-item active">
            <div class="row mb-4">
              <div class="form-group col-sm-12">
                <label for="customerClaim">Saisissez ici votre message</label>
                <textarea
                  autocomplete="off"
                  class="form-control"
                  cols="6"
                  formControlName="customerClaim"
                  id="customerClaim"
                  type="email"
                >
            </textarea>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="stepClaim===1" class="modal-footer">
          <button class="btn btn-success col-5" data-dismiss="modal" type="button">Non</button>
          <button (click)="claimSecondStep()" class="btn btn-danger" type="button">Oui</button>
        </div>

        <div *ngIf="stepClaim===2" class="modal-footer">
          <button aria-label="Close" class="btn btn-success" data-dismiss="modal" type="button" type="submit">Annuler
          </button>
          <button [disabled]="formClaim.invalid||formClaimInvalid" class="btn btn-danger" type="button" type="submit">
            Envoyer mon message
            <span *ngIf="formClaimInvalid" class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only"></span>
      </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Claim error -->


<div aria-hidden="false" aria-labelledby="modalClaimErrorLabel" class="modal fade" data-backdrop="static" id="modalClaimError"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalClaimErrorLabel">Mes Achats </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p>
              Une erreur vient de survenir et {{definitionsInfo?.sms_brand}} n'est pas en mesure de recevoir votre
              message !
            </p>
          </div>
        </div>
        <p>Merci de contacter directement {{definitionsInfo?.sms_brand}}au {{definitionsInfo?.provider_call_number}}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success text-center" data-dismiss="modal" type="button">Continuer</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal reviews -->

<div aria-hidden="false" aria-labelledby="ModalessageLabel" class="modal fade" data-backdrop="static" id="modalConsultantReviews"
     role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Avis</h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="p-4">
        <div *ngIf="showAddNotice" class="p-4">
          <app-add-review [closeModal]=true
                          [consultantId]="purchase.consultant_id"
                          [consultantName]="purchase.consultant_firstname"
                          [redirect]=false
                          [transacationId]="purchase.transaction_id"
                          [numberOfCall] = 1
                          id="addNotice"
          ></app-add-review>
        </div>
        <div *ngIf="showReadNotice" class="p-4">

          <div class="row">
            <h4> Votre note :
              <ngb-rating [rate]="purchase.customer_notice_notation||0" class="text-warning"></ngb-rating>
            </h4>
          </div>

          <fieldset *ngIf="purchase.customer_remark" class="border fieldset-info-mobiyo fieldset-update-info-mobiyo">
            <legend class="w-auto pl-2 pr-2 legend-first-name">
              Votre message envoyé à : {{purchase.consultant_firstname}}
            </legend>
            <p class="ml-4">{{purchase.customer_remark}}</p>
          </fieldset>

          <fieldset *ngIf="purchase.consultant_answer_status ==='CONSULTANT_ANSWER_VALIDATED_FOR_CUSTOMER'||purchase.consultant_answer_status ==='CONSULTANT_ANSWER_READ'"
                    class="border fieldset-info-mobiyo fieldset-update-info-mobiyo">
            <legend class="w-auto pl-2 pr-2 legend-first-name">
              Sa réponse :
            </legend>
            <p class="ml-4">Sa réponse :  {{purchase.consultant_anwser}}</p>
          </fieldset>

        </div>
      </div>
    </div>
  </div>
</div>
