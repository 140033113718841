import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account.component';
import {LoginComponent} from './login/login.component';
import {AccountHomeComponent} from './account-home/account-home.component';
import {RegisterComponent} from './register/register.component';
import {ProfileComponent} from './profile/profile.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import { EmailUpdateComponent } from './email-update/email-update.component';
import { UpdatePrimaryPhoneComponent } from './phone/update-primary-phone/update-primary-phone.component';
import { UpdateSecondaryPhoneComponent } from './phone/update-secondary-phone/update-secondary-phone.component';
import { AddSecondaryPhoneComponent } from './phone/add-secondary-phone/add-secondary-phone.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import {PasswordResetRequestComponent} from './password-reset-request/password-reset-request.component';
import { PasswordUpdateComponent } from './password-update/password-update.component';
import { CancelAccountComponent } from './cancel-account/cancel-account.component';
import {FirstNameUpdateComponent} from './first-name-update/first-name-update.component';
import { NameUpdateComponent } from './name-update/name-update.component';
import { GenderUpdateComponent } from './gender-update/gender-update.component';
import { EmailResetComponent } from './email-reset/email-reset.component';
import { BirthDateComponent } from './birth-data/birth-date/birth-date.component';
import { BirthTimeComponent } from './birth-data/birth-time/birth-time.component';
import { BirthPlaceComponent } from './birth-data/birth-place/birth-place.component';
import { BirthCountryComponent } from './birth-data/birth-country/birth-country.component';
import {NgbDatepickerModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { AddPrimaryPhoneComponent } from './phone/add-primary-phone/add-primary-phone.component';
import {NgxSelectFlagsModule} from 'ngx-select-flags';
import {PromotionModule} from '../promotion/promotion.module';
import {DeviceDetectorService} from 'ngx-device-detector';



@NgModule({
  declarations: [
    AccountComponent,
    LoginComponent,
    AccountHomeComponent,
    PasswordResetRequestComponent,
    RegisterComponent,
    ProfileComponent,
    EmailUpdateComponent,
    PasswordResetComponent,
    UpdatePrimaryPhoneComponent,
    UpdateSecondaryPhoneComponent,
    AddSecondaryPhoneComponent,
    PasswordUpdateComponent,
    CancelAccountComponent,
    FirstNameUpdateComponent,
    NameUpdateComponent,
    GenderUpdateComponent,
    EmailResetComponent,
    BirthDateComponent,
    BirthTimeComponent,
    BirthPlaceComponent,
    BirthCountryComponent,
    AddPrimaryPhoneComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        NgxSelectFlagsModule,
        PromotionModule
    ],
  exports: [AccountComponent, PasswordResetComponent],
  providers: [
    DeviceDetectorService
  ]

})
export class AccountModule {
}
