import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {LoginService} from '../../_services/login.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {SessionService} from '../../_services/session.service';
import {HomeService} from '../../_services/home.service';
import {PromotionService} from '../../_services/promotion.service';
declare var $: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hasError = false;
  form: FormGroup;
  control: AbstractControl;
  @Input() IsValid: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginservice: LoginService,
    private sessionService: SessionService,
    private homeService: HomeService,
    private promotionservice: PromotionService
  ) {
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerEmail: ['', [Validators.required, Validators.email,
        Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}')]
      ],
      customerPassword: ['', [Validators.required]],
    });
  }

  onSubmit() {
    const currentRoute = window.location.pathname;
    const lastItemPath = currentRoute.substring(currentRoute.lastIndexOf('/') + 1);
    const location = ['login', 'login.html'];
    if (this.form.valid) {
      this.loginservice.login(this.form.value).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            localStorage.setItem('customerSessionId', result.customerSessionId);
            localStorage.setItem('customerId', JSON.stringify(result.customerId));
            this.loginservice.loggedIn.next(true);
            this.sessionService.login(result.customerSessionId, result.customerId);
            this.loginservice.getUserInfo(result.customerSessionId, result.customerId);
            if (location.includes(lastItemPath)) {
              this.homeService.redirect('/');
            }
          } else {
            this.hasError = true;
          }
        },
        (error: any) => {
          console.log('error');
        });
    } else {
      console.log('Form not Valid');
    }
  }

  closeLoginModal() {
    $('#loginModal').modal('hide');
    $('#resetPasswordModal').modal('show');
  }

  openRegisterModal() {
    $('#loginModal').modal('hide');
    $('#registerModal').modal('show');

  }

  clearError() {
    this.hasError = false;
  }

}
