<app-notification
  [definitions]="(definitions | async)"
  [customerId]="(account | async)?.customerId"
  [customerEmail]="(account | async)?.customerEmail"
  [customerPhone]="(account | async)?.principalCallNumber"
  [customerPhoneValidate]="(account | async)?.isPrincipalCallNumberValidate"
  [customerSecondPhone]="(account | async)?.secondaryCallNumber"
></app-notification>
<ul class="navbar-nav ml-md-auto d-md-flex" style="margin-right: 100px">
  <div class="nav-item">
    <li class="nav-link" id="navCustomerFirstName" *ngIf="(session | async).isLoggedIn && (account | async)">Bonjour {{(account | async)?.customerFirstName}}</li>
  </div>

  <div class="nav-item ">
    <li *ngIf="(session | async).isLoggedIn === false">
      <a class="btn-link font-weight-bold btn-link-login " *ngIf="router.url !== '/login'" data-toggle="modal" data-target="#loginModal">S'identifier </a>
    </li>
    <li class="nav-item dropdown" *ngIf="(session | async).isLoggedIn && (account | async)">
      <a class="nav-link nav-link-mobiyo dropdown-toggle" data-toggle="dropdown" >Mon compte</a>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item nav-link-mobiyo"  (click)="this.homeService.redirect('/add-payment-card')"> Mes Moyens de Paiements </a></li>
        <li><a class="dropdown-item nav-link-mobiyo"  (click)="this.homeService.redirect('/purchases')"> Mes Achats </a></li>
        <li><a class="dropdown-item nav-link-mobiyo"  (click)="this.homeService.redirect('/profile')"> Mes Infos </a></li>
        <div class="dropdown-divider"></div>
        <li><a class="dropdown-item nav-link-mobiyo" (mouseover)="mouseover()" (click)="confirmLogout()">Déconnexion</a></li>
      </ul>
    </li>
  </div>
</ul>
<!-- Btn  Logout Modal -->

<a id="openModalLogout" [hidden]="true" data-toggle="modal" data-target="#Modalessage">
</a>

<!--   Logout Modal -->
<div class="modal fade" id="Modalessage" tabindex="-1" role="dialog" aria-labelledby="ModalessageLabel" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" >Se déconnecter ?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-8">
            <p>
              Voulez-vous vraiment vous déconnecter ?
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Non</button>
        <button type="button" class="btn btn-secondary text-center" data-dismiss="modal" (click)="onLogout()">Oui</button>

      </div>
    </div>
  </div>
</div>



