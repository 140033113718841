import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';
import {HomeService} from './home.service';
import * as dayjs from 'dayjs';
import * as PromotionsActions from '../actions/promotions.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {CookieService} from 'ngx-cookie-service';
import {CryptoService} from './crypto.service';
import {Observable} from 'rxjs';
import {Account} from '../_models/account';
import {Definitions} from '../_models/definitions';
import { DeviceDetectorService } from 'ngx-device-detector';
import {Promotion} from '../_models/promotion';
declare var $: any;



@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  private  apiUrl =  environment.apiUrlPromotion;
  merchantId = environment.merchantId;
  public account: Observable<Account>;
  public definitions: Observable<Definitions>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private homeService: HomeService,
    public store: Store<AppState>,
    private cookieService: CookieService,
    private cryptoService: CryptoService,
    private detectorService: DeviceDetectorService
) {
    this.definitions = this.store.select('definitions');
    this.definitions.subscribe(definitions => {
      if (definitions) {
        this.merchantId = definitions.merchantId;
      }
    });
  }

  checkPromotion(code , customerId) {
    const params = new  HttpParams({
      fromObject : {
        merchantId : String(this.merchantId),
        customerId: customerId || Number(localStorage.getItem('customerId')),
        code: code
      }
    });
    return this.http.get(`${this.apiUrl}/check-promotion`, {params});

  }

  usePromotion(code , history, customerId) {
    const data = {
      merchantId: this.merchantId,
      customerId: customerId || Number(localStorage.getItem('customerId')),
      code: code,
      command: 'USE',
      transactionId: history
    };
    return this.http.post(`${this.apiUrl}/use-promotion`, data);

  }

  getPromotion(position: string) {
    const params = new  HttpParams({
      fromObject : {
        merchantId : String(this.merchantId),
        positionSpot: position,
        active: '1'
      }
    });
    return this.http.get(`${this.apiUrl}/get-promotions`, {params});
  }
  useCodepromotion() {
    const paths = ['/' , '/consultant-details'];
    const  sessionId = localStorage.getItem('customerSessionId');
    const currentLocation = window.location.pathname;
    if (sessionId ) {
      paths.includes(currentLocation) ? $('#promotionPopup').modal('hide') : this.homeService.redirect('/');
  } else {
    $('#promotionPopup').modal('hide');
    $('#loginModal').modal('show');
   }
  }
  closePopup() {
    $('#promotionPopup').modal('hide');
  }

  handlePromotionBonus(promotion: any) {
    let bonus: string;
    switch (promotion.promotionNature) {
      case 'AMOUNT':
        bonus = promotion.promotionValue + '€ de remise';
        break;
      case 'PERCENTAGE':
        bonus = promotion.promotionValue + ' % de réduction';
        break;
      case 'DURATION':
        bonus = promotion.promotionValue + ' minutes offertes';
        break;
    }
    return {
      bonus
    };
  }

  fetchPromotions(position: string) {
    if (!this.cookieService.check(this.cryptoService.set(position))) {
      this.getPromotion(position).subscribe(
        (res: any) => {
          const initialResult = this.adaptData(res.promotions);
          initialResult.forEach(promotion => {
            const result = this.handlePromotionBonus(promotion);
            promotion.bonus = result.bonus;
            if (promotion.endDateTime == null) {
              promotion.expirationDate = 'Illimitée';
            } else {
              promotion.expirationDate = dayjs(promotion.endDateTime).format('DD/MM/YYYY');
            }
          });
          initialResult.sort((a, b) => a.expirationDate > b.expirationDate ? 1 : -1);
          if (initialResult.length) {
            $('#promotionPopup').modal('show');
            this.store.dispatch(new PromotionsActions.SetPromotions(initialResult));
            if (position !== 'Fin Consultation') {
              this.cookieService.set(this.cryptoService.set(position), '1');
            }
          } else {
            if (position === 'Fin Consultation') {
              this.homeService.redirect('/');
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }

  }

  private adaptData(promotions: Promotion[]) {
    return promotions.map(promo => {
      switch (this.detectorService.browser) {
        case 'Chrome':
          break;
        case 'Firefox':
          break;
        case 'Safari':
          promo.startDateTime = promo.startDateTime.replace('-', '/');
          if (promo.endDateTime) {
            promo.endDateTime = promo.endDateTime.replace('-', '/');
          }
          break;
        case 'IE':
          promo.startDateTime = promo.startDateTime.replace('-', '/').substring(0, promo.startDateTime.lastIndexOf(':00'));
          if (promo.endDateTime) {
            promo.endDateTime = promo.endDateTime.replace('-', '/').substring(0, promo.endDateTime.lastIndexOf(':00'));
          }
          break;
      }
      return promo;
    });
  }
}
