import {Component, Input, OnInit} from '@angular/core';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NoticeService} from '../../_services/notice.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {HomeService} from '../../_services/home.service';
import {CookieService} from 'ngx-cookie-service';
import {PromotionService} from '../../_services/promotion.service';

declare var $: any;

@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.css']
})
export class AddReviewComponent implements OnInit {

  @Input() consultantId: string;
  @Input() consultantName: string;
  @Input() transacationId: any;
  @Input() closeModal: any;
  @Input() redirect: any;
  @Input() numberOfCall;

  constructor(
    config: NgbRatingConfig,
    private fb: FormBuilder,
    public noticeService: NoticeService,
    private router: Router,
    public homeService: HomeService,
    private cookieService: CookieService,
    private promotionService: PromotionService


  ) {
    config.max = 5;
    config.readonly = true;
  }
  customerNoticeNotation = 0;
  hovered = 0;
  readonly = false;
  formAddReview: FormGroup;
  customerRemark: string;
  transaction = {call_duration: 0, amount: 0, date: undefined, transaction_id: undefined
  };
  transactions: any;

  get f() {
    return this.formAddReview.controls;
  }


  ngOnInit(): void {

    console.log('consultantName', this.consultantName);
    this.getCustomerTransactions();
    this.formAddReview = this.fb.group({
      customerRemark: ['', [
        Validators.required,
      ]],
    });

    $('#modalConsultantReviews').on('hidden.bs.modal', () => {

      $('.hiddenNotice').hide();
      $('#btnShowAllNotices').show();
      this.customerNoticeNotation = 0;
      this.formAddReview.reset();

    });

  }

  onSubmit() {

    this.customerRemark = this.formAddReview.value.customerRemark;

    const dataCustomerNoticeEnable = {
      merchantId: environment.merchantId,
      consultantId: parseInt(this.consultantId, 10),
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      transactionId: this.transaction.transaction_id,
      sessionId: localStorage.getItem('customerSessionId'),
    };

    const dataCustomerNoticeSet = {
      transactionId: this.transaction.transaction_id,
      sessionId: localStorage.getItem('customerSessionId'),
      customerNoticeNotation: this.customerNoticeNotation,
      customerRemark: this.formAddReview.value.customerRemark
    };

    this.noticeService.customerNoticeEnable(dataCustomerNoticeEnable).subscribe(
      (result: any) => {
        console.log('customerNoticeEnable success');

        this.noticeService.customerNoticeSet(dataCustomerNoticeSet).subscribe(
          (result2: any) => {
            console.log('customerNoticeSet success');
            $('#addNotice').hide();
            $('#allNotices').show();
            this.noticeService.showAddNotice.next(false);
            this.getCustomerTransactions();
            // this.noticeService.transactionStatus.next(true);
            this.noticeService.showAllNotice.next(true);

            if (this.closeModal) {
              $('#modalConsultantReviews').modal('hide');
              $('#review').modal('hide');
            }
            this.promotionService.fetchPromotions('Fin Consultation');
            if (this.redirect) {
              this.homeService.redirect('/');

            }

          },
          (error: any) => {
            console.log('customerNoticeSet error');
          });

      }, (error => {
        console.log('customerNoticeEnable error');
        $('#modalConsultantReviews').modal('hide');
        $('#modalConsultantAddReviewsError').modal('show');
      })
    );


  }


  getCustomerTransactions() {
    const merchantId = environment.merchantId;
    this.noticeService.customerTransactions(merchantId, localStorage.getItem('customerId'), localStorage.getItem('customerSessionId'), this.consultantId).subscribe(
      (result: any) => {
        if (result.body[0]) {

          if (this.transacationId && this.numberOfCall <= 1) {

            const transaction = result.body.find(i => i.transaction_id === parseInt(this.transacationId));

            this.transaction = transaction;


          } else {


            this.transactions = result.body.slice(0, this.numberOfCall);


            this.transaction.date = this.transactions[0].date;
            this.transaction.transaction_id = this.transactions[0].transaction_id;


            this.transactions.forEach(
              element => {
                this.transaction.call_duration += element.call_duration;
                this.transaction.amount += element.amount;
              }
            );



          }
        }
      },
      (error: any) => {

      }
    );

  }

}

