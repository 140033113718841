import {Component, Input, OnInit} from '@angular/core';
import * as dayjs from 'dayjs';
import {environment} from '../../../environments/environment';
import {ConsultantService} from '../../_services/consultant.service';
import {Observable} from 'rxjs';
import {Definitions} from '../../_models/definitions';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {AccountService} from '../../_services/account.service';
import {Account} from '../../_models/account';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-consultant-planning',
  templateUrl: './consultant-planning.component.html',
  styleUrls: ['./consultant-planning.component.css']
})
export class ConsultantPlanningComponent implements OnInit {

  @Input() public consultantId: number;
  public slideConfig: {};
  plannings = [];
  public definitions: Observable<Definitions>;
  private account: Observable<Account>;
  activateLink = false;


  constructor(
    private consultantservice: ConsultantService,
    private store: Store<AppState>,
    private accountService: AccountService,
    private route: ActivatedRoute

  ) {

    this.account = store.select('account');
    this.definitions = store.select('definitions');
    this.account.subscribe((account) => {
    });

    this.slideConfig = {
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite : false
          }
        },
        {
          breakpoint: 600,
          settings: 'unslick',
        },
        {
          breakpoint: 480,
          settings: 'unslick'
        }
      ]
    };

  }

  ngOnInit(): void {
    dayjs.locale('fr');
    this.listConsultantPlanning();
  }


  // Consultant Planning api call

  listConsultantPlanning() {
    const startDay = new Date();
    const endDay = new Date();
    const currentHour = dayjs(startDay).format('YYYY-MM-DD HH:mm:ss');

    endDay.setDate(startDay.getDate() + 6);
    this.consultantservice.sendGetPlaning(
      environment.merchantId,
      environment.officeId,
      this.consultantId,
      currentHour,
      endDay.getFullYear() + '-' + (Number(endDay.getMonth()) + 1) + '-' + endDay.getDate() + ' 23:59:59'
    ).subscribe(
      (res: any) => {
        console.log(res);
        this.plannings = this.getConsultantPlanningStructured(res);
      },
      error => {
        console.log(error);
      }
    );
  }

  // Returns structured data of api consultant-planning return

  getConsultantPlanningStructured(res) {


    if (Object.keys(res.body.data).length !== 0) {
      res = this.consultantservice.handleplaningDate(res.body.data[0].planning);
    }

    const startDay = new Date();
    const endDay = new Date();
    endDay.setDate(startDay.getDate() + 6);

    const plannings = [];
    const dates = this.getDates(startDay, endDay);

    dates.forEach((date) => {

      const dayConsultations = [];
      const dateStr = date.getFullYear() + '-' + ('0' + (Number(date.getMonth()) + 1)).slice(-2) + '-' + ('0' + Number(date.getDate())).slice(-2);
      const consultations = res[dateStr];
      if (consultations) {
        consultations.forEach(consultation => {
          const serviceTypes = consultation.serviceType;
          if (serviceTypes) {
            serviceTypes.forEach(service => {
              let serviceName = 'EN AUDIOTEL';
              if (service !== 'audiotel') {
                serviceName = 'En Consultation Privée';
              }
              const dayConsultation = {
                service,
                type: serviceName,
                hour: consultation.hourText
              };
              dayConsultations.push(dayConsultation);
            });
          } else {
            dayConsultations.push({
              hour: consultation.hourText
            });
          }
        });
      }

      const planning = {
        dayText: this.checkCurrentPlanningDay(date),
        day: dayjs(date).format('dddd D MMMM'),
        dayColor: [6, 0].includes(dayjs(date).get('d')) ? '#25fde9' : '#dadada',
        consultations: dayConsultations
      };
      plannings.push(planning);

    });
    return plannings;
  }

  checkCurrentPlanningDay(date: Date): string {
    if (dayjs(date).isToday()) {
      return 'Aujourd\'hui';
    }
    if (dayjs(date).isTomorrow()) {
      return 'Demain';
    }
    if (dayjs(date).add(-1, 'day').isTomorrow()) {
      return 'Après demain';
    }
    return '';
  }


  // Returns an array of dates between the two dates

  getDates(startDate, endDate) {
    // tslint:disable-next-line:prefer-const one-variable-per-declaration
    let dates = [],
      currentDate = startDate,
      addDays = function(days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  showSVAModal(time = null , service = null) {
    if (time === 'Maintenant' && service === 'audiotel') {
      this.activateLink = true;
    }
    this.consultantservice.showSVAModalAction(this.activateLink);
  }

}
