import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  //  to be changed to STG/PROD urls
  private  apiUrl =  environment.apiUrlHistory;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  getHistory(params) {

    return this.http.get(`${this.apiUrl}/get_history_my_purchases`, {params});

  }


  // setComplaint

  setComplaint(formData) {
    return this.http.post(`${this.apiUrl}/set_complaint`, formData);
  }



  getTransaction(params) {

    return this.http.get(`${this.apiUrl}/get-history`, {params});

  }

}
