<div class="row align-items-center">
  <div class="col-7 col-7-mobiyo border-right py-4">
    <form class="form-horizontal" [formGroup]="form" (submit)="onSubmit()" id="formlogin"  >
      <div class="row">
        <label for="inputcustomerEmail" class="col-sm-4">Votre email </label>
        <div class="col-sm-8">
          <input
            type="email"
            id="inputcustomerEmail"
            formControlName="customerEmail"
            class="form-control"
            [class.is-invalid]="(f.customerEmail.errors && (f.customerEmail.errors.email || f.customerEmail.errors.pattern) ) || hasError == true "
            [ngClass]="{ 'is-valid': f.customerEmail.valid }"
            (click)="clearError()"
            aria-describedby="emailInvalidLogin"
          >
          <div
            *ngIf="f.customerEmail.errors?.email || f.customerEmail.errors?.pattern"
            id="emailInvalidLogin"
            class="invalid-email-mobiyo invalid-feedback"
          >
            Votre mail est invalide
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <label for="inputPassword" class="col-sm-4">Votre mot de passe</label>
        <div class="col-sm-8">
          <input
            type="password"
            id="inputPassword"
            formControlName="customerPassword"
            class="form-control "
            [class.is-invalid]="hasError"
            [ngClass]="{ 'is-valid': f.customerPassword.valid }"
            (click)="clearError()"
            aria-describedby="unknownEmail"
          >
          <div *ngIf="hasError" id="unknownEmail" class="unkown-email-pwd-mobiyo invalid-feedback">
            Utilisateur introuvable, adresse e-mail ou mot de passe incorrect
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-7 offset-sm-4">
          <button class="btn btn-success btn-block col-sm-4 btn-mobiyo" type="submit" [disabled]="!form.valid || hasError">Valider</button>
        </div>
      </div>
    </form>
    <div class="row mt-4">
      <label class="col-sm-4" style="font-size: 0.9rem;">Mot de passe oublié ?</label>
      <a
        data-toggle="modal"
        data-target="#resetPasswordModal"
        class="btn-link col nav-link-mobiyo"
        (click)="closeLoginModal()"
      >
        Ré-initialiser le mot de passe ici
      </a>
    </div>
  </div>
  <div class="col link-new-client">
    <label>Nouveau client ?</label>
    &nbsp;
    <a class="btn-link nav-link-mobiyo" (click)="openRegisterModal()">Commencer ici</a>
  </div>
</div>

