import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProfileService} from '../../_services/profile.service';
declare var $: any;

@Component({
  selector: 'app-gender-update',
  templateUrl: './gender-update.component.html',
  styleUrls: ['./gender-update.component.css']
})
export class GenderUpdateComponent implements OnInit {
  @Input() customerSex: string;
  @Output() newSex = new EventEmitter<string>();
  form: FormGroup;
  formData: any;
  success = false;
  error = false;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
  ) { }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId'),
      customerSex: ['', [
        Validators.required
      ]],
    }
    );

    $('#update-gender-modal')
      .on('show.bs.modal', () => {
        this.form.patchValue({
          customerSex: this.customerSex
        });
      }).on('hidden.bs.modal', () => {
        this.reset();
      });
  }

  onSubmit() {

    if (this.form.valid) {
      this.profileService.accountUpdatePersonal(this.form.value).subscribe(
        (result: any) => {
          if (!result.statusCode) {
            // this.next();
            $('#update-gender-modal').modal('hide');
            this.newSex.emit(this.form.value.customerSex);
          } else {
            this.next(false);
          }
        },
        (error) => {
          this.next(false);
        }
      );
    } else {
      this.next(false);
    }
  }

  next(state = true): void {
    this.success = state;
    this.error = !state;
  }

  customSex(): string {
    return this.customerSex === 'H' ? 'Homme' : (this.customerSex === 'F' ? 'Femme' : '');
  }

  private reset() {
    this.success = false;
    this.error = false;
  }
}
