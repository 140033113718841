<div  class="modal fade" id="promotionPopup" tabindex="-1" role="dialog" aria-labelledby="promotionPopupLabel"  data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <div class="text-center" *ngIf="!(promotions | async) || (promotions | async).length === 0">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div *ngFor="let promo of (promotions | async)">
          <div class="row">
            <div class="col-sm-12 ">
              <p class="float-right" *ngIf="promo.expirationDate">Expire le : {{promo.expirationDate}}</p>
            </div>
            <div class="col-sm-12">
              <p>{{promo?.description}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 offset-md-5 "> {{promo?.code}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 offset-md-3 ">{{promo?.bonus}} {{bonusMessage}}</div>
          </div>
          <div class="row mt-3 ">
            <div class="col-md-6 offset-md-3">
              <button aria-label="Close" class="btn btn-danger mx-3 use-promo-btn " data-dismiss="modal" type="button"  (mousedown)="this.promotionService.closePopup()" >Utiliser plus tard
              </button>
              <button (mousedown)="this.promotionService.useCodepromotion()" class="btn btn-success mx-3 use-promo-btn" type="button" id="useLater">Utiliser la
                promotion
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
