import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ConsultantProfileComponent} from './consultant-profile/consultant-profile.component';
import {ConsultantComponent} from './consultant.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ReviewModule} from '../review/review.module';
import {PrivateConversationModule} from '../private-conversation/private-conversation.module';
import { SvaComponent } from './sva/sva.component';
import {PromotionModule} from '../promotion/promotion.module';
import { ConsultantPlanningComponent } from './consultant-planning/consultant-planning.component';
import {NgxContentLoadingModule} from "ngx-content-loading";


@NgModule({
  declarations: [
    ConsultantProfileComponent,
    ConsultantComponent,
    SvaComponent,
    ConsultantPlanningComponent,

  ],
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        SlickCarouselModule,
        NgbModule,
        ReviewModule,
        PrivateConversationModule,
        PromotionModule,
        NgxContentLoadingModule
    ],
  exports: [
    ConsultantComponent ,
    ConsultantProfileComponent,
    SvaComponent
  ]
})
export class ConsultantModule { }
