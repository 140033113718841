import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PhoneService} from '../../_services/phone.service';
import {Router} from '@angular/router';
import {DefinitionService} from '../../_services/definition.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {Observable} from 'rxjs';
import {Account} from '../../_models/account';
import * as phoneUtil from 'google-libphonenumber';
import {AccountService} from '../../_services/account.service';
import {environment} from '../../../environments/environment';
import {Definitions} from '../../_models/definitions';
import {HomeService} from "../../_services/home.service";
declare var $: any;

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.css']
})
export class PhoneComponent implements OnInit {
  formAddPhone: FormGroup;
  formMobilePhoneCode: FormGroup;
  formWiredPhoneCode: FormGroup;

  isValidationSet = false;
  isPhoneRequireValidation: boolean;
  phoneNumber: string;
  openAddPhoneForm = true;
  openValidateFormMobile = false;
  openValidateFormFilaire = false;
  customerId = JSON.parse(localStorage.getItem('customerId'));
  customerSessionId = localStorage.getItem('customerSessionId');
  data = {
    customerId: this.customerId,
    customerSessionId: this.customerSessionId,
    customerPhoneOrder: 'PRINCIPAL'
  };
  selectedCountryCode: string;
  countryCodes: string[];
  countryCodesData: {};
  disabledUpdatePhoneBtn: boolean;
  account: Observable<Account>;
  definitions: Observable<Definitions>;
  private phoneUtilInstance: any;
  submitting = false;

  constructor(
    private phoneService: PhoneService,
    private fb: FormBuilder,
    private router: Router,
    private definitionService: DefinitionService,
    private store: Store<AppState>,
    private accountService: AccountService,
    public homeService: HomeService
  ) {
    this.account = store.select('account');
    this.definitions = store.select('definitions');
    this.phoneUtilInstance = phoneUtil.PhoneNumberUtil.getInstance();
    this.countryCodesData = {
      be: '+32',
      fr: '+33',
      lu: '+352',
      ch: '+41',
      ca: '+1'
    };
  }

  get f() {
    return this.formAddPhone.controls;
  }

  get fMobile() {
    return this.formMobilePhoneCode.controls;
  }

  get fWired() {
    return this.formWiredPhoneCode.controls;
  }

  ngOnInit(): void {
    this.checkPageAccess();

    this.formAddPhone = this.fb.group({
      customerId: this.customerId,
      customerSessionId: this.customerSessionId,
      customerPhoneOrder: 'PRINCIPAL',
      customerCallNumber: ['' , [Validators.required, Validators.pattern('^0(6|4|5|7)[0-9]{8}$')]]
    });
    this.formMobilePhoneCode = this.fb.group({
      customerCallNumberCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[0-9]*')]]
    });
    this.formWiredPhoneCode = this.fb.group({
      customerCallNumberCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('[0-9]*')]]
    });
    this.outerRestForm();

    this.definitions.subscribe((definitions) => {
      this.isPhoneRequireValidation = definitions?.tel_validate || false;
      this.countryCodes = definitions?.customer_countries.map(value => value.toLowerCase()) || [];
      this.selectedCountryCode = this.countryCodes[0] || 'fr';
    });

    this.fillPhone();
  }

  onSubmitAddPhone() {
    this.disabledUpdatePhoneBtn = true;
    const formData = this.formAddPhone.getRawValue();
    formData.customerCallNumber = this.countryCodesData[this.selectedCountryCode.toLowerCase()] + formData.customerCallNumber;
    if (this.formAddPhone.valid) {
      this.submitting = true;
      this.phoneService.updatePhone(formData).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.accountService.update('principalCallNumber', result.principalCallNumber);
            this.phoneNumber = result.principalCallNumber;
            if (this.isPhoneRequireValidation === false) {
              $('#ModalSuccessAddPhone').modal('show');
              this.submitting = false;
              this.disabledUpdatePhoneBtn = false;

            } else {
              if (result.principalCallNumberType === 'MOBILE') {
                this.submitSMS();
                this.openValidateFormMobile = true;
                this.openAddPhoneForm = false;
                this.submitting = false;
                this.disabledUpdatePhoneBtn = false;
              }
              if (result.principalCallNumberType === 'FIXE') {
                this.ivrPlay();
                this.openValidateFormFilaire = true;
                this.openAddPhoneForm = false;
                this.submitting = false;
                this.disabledUpdatePhoneBtn = false;
              }
            }
          } else {
            $('#ModalErrorAddPhone').modal('show');
            this.submitting = false;
            this.disabledUpdatePhoneBtn = false;
          }
        },
        error => {
          $('#ModalErrorAddPhone').modal('show');
          this.submitting = false;
          this.disabledUpdatePhoneBtn = false;
          console.log(error);
        }
      );
    }
  }

  onSubmitCheckCode() {
    const formData = this.formMobilePhoneCode.getRawValue();
    const data = {
      customerId: this.customerId,
      customerSessionId: this.customerSessionId,
      customerCallNumberCode: JSON.parse(formData.customerCallNumberCode),
      deleteCode : 1
    };
    if (this.formMobilePhoneCode.valid) {
      this.phoneService.checkCode(data).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.accountService.update('isPrincipalCallNumberValidate', true);
            $('#ModalSuccessAddPhone').modal('show');
            localStorage.setItem('customerCallNumber', this.phoneNumber);
          } else {
            if (result.statusCode === -17) {
              $('#ModalErrorMobilePhoneCodeNotFound').modal('show');

            } else {
              $('#ModalErrorMobilePhone').modal('show');

            }
          }
        },
        error => {
          $('#ModalErrorMobilePhone').modal('show');
          console.log(error);
        }
      );
    } else {
      console.log('error');
    }
  }

  onSubmitWiredCheckCode() {
    const formData = this.formWiredPhoneCode.getRawValue();
    const data = {
      customerId: this.customerId,
      customerSessionId: this.customerSessionId,
      customerCallNumberCode: JSON.parse(formData.customerCallNumberCode),

    };
    if (this.formWiredPhoneCode.valid) {
      this.phoneService.checkCode(data).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.accountService.update('isPrincipalCallNumberValidate', true);
            $('#ModalSuccessAddPhone').modal('show');
          } else {
            if (result.statusCode === -17) {
              $('#ModalErrorMobilePhoneCodeNotFound').modal('show');
            } else {
              $('#ModalErrorWiredPhone').modal('show');
            }
          }
        },
        error => {
          $('#ModalErrorWiredPhone').modal('show');
          console.log(error);
        }
      );
    } else {
      console.log('error');
    }
  }

  submitSMS() {
    this.phoneService.createCode(this.data).subscribe(
      (res: any) => {
        console.log(res);
        const smsData = {
          merchantId: environment.merchantId,
          customerCallNumber: this.phoneNumber,
          customerCallNumberCode: res.customerCallNumberCode

        };
        this.phoneService.sendSMSCode(smsData).subscribe(
          (smsres: any) => {
            console.log('SMS sent');
          },
          error => {
            console.log('SMS sent error');

          }
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  ivrPlay() {
    this.phoneService.createCode(this.data).subscribe(
      (res: any) => {
        console.log(res);
        const callData = {
          merchantId: environment.merchantId,
          merchantCallNumber: '0549082735',
          customerCallNumber: this.phoneNumber,
          customerCallNumberCode: res.customerCallNumberCode
        };
        this.phoneService.ivrPlayCode(callData).subscribe(
          (callRes: any) => {
            console.log('Call sent');
          },
          error => {
            console.log('SMS sent error');

          }
        );
      },
      error => {
        console.log(error);
      }
    );

  }

  changeCodeNumber($event) {
    this.selectedCountryCode = $event;
    if (this.f.customerCallNumber.validator) {
      this.isValidationSet = true;
    }
  }

  resetToProfiePage() {
    this.homeService.redirect('/');
  }

  outerRestForm() {
    document.addEventListener('click', e => {
      const target = e.target as HTMLTextAreaElement;
      if (target.className === 'modal fade') {
        this.resetToProfiePage();
      }
    }, false);
  }

  private fillPhone() {
    this.account.subscribe((account) => {
      if (account?.principalCallNumber) {
        const phoneObj = this.phoneUtilInstance.parseAndKeepRawInput(account.principalCallNumber, 'FR');
        this.selectedCountryCode = this.phoneUtilInstance.getRegionCodeForNumber(phoneObj).toLowerCase();
        this.formAddPhone.patchValue({
          customerCallNumber: '0' + phoneObj.getNationalNumber()
        });
      }
    });
  }

  private checkPageAccess() {
    this.account.subscribe((account => {
      if (account?.isPrincipalCallNumberValidate) {
        // this.router.navigate(['/']);
      }
    }));
  }

  formatPhone(customerPhone) {
    if (customerPhone) {
      const phoneNumber = phoneUtil.PhoneNumberUtil.getInstance();
      const PHONE = phoneNumber.parseAndKeepRawInput(customerPhone, 'FR');
      const flag = 'flag-icon-' + phoneNumber.getRegionCodeForNumber(PHONE).toLowerCase();
      const phone = '0' + PHONE.getNationalNumber();
      return `<span class="flag-icon ${flag}"></span> ${phone}`;
    } else {
      return '';
    }
  }

  sendCheckCode() {
    this.onSubmitAddPhone();

    $('.requestSMS').hide();
    $('.updatePhone').hide();
    $('.sendSMSCountDown').show();

    // Set the date we're counting down to
    var countDownDate = new Date().getTime()+2*60000;

    // Update the count down every 1 second
    var x = setInterval(function() {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      $(".sendSMSCountDown p").html("SMS envoyé "+minutes + "m " + seconds + "s avant de demander un autre SMS");

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        $('.sendSMSCountDown').hide();
        $('.requestSMS').show();
        $('.updatePhone').show();

      }
    }, 1000);

  }

  changeNumber() {
    this.openValidateFormMobile = false;
    this.openValidateFormFilaire = false;
    this.openAddPhoneForm = true;
  }
}
