<div class="container mt-lg-5" *ngIf="openAddPhoneForm == true" style="width: 85%">
  <form [formGroup]="formAddPhone" (ngSubmit)="onSubmitAddPhone()">
    <fieldset class="border form-group p-4">
      <legend  class="w-auto">Enregistrer mon numéro de téléphone sur lequel je serai contacté </legend>
      <p>Pour recevoir les appels des consultants d'{{(definitions | async)?.sms_brand}}, merci de bien vouloir saisir votre numéro de téléphone à contacter en priorité.</p>
      <fieldset class="border form-group p-3">
        <legend class="w-auto">Numéro de téléphone à appeler en priorité</legend>
        <div class="row align-items-center">
          <div class="col-sm-1" style="font-size: 30px">
            <ngx-select-flags
              [selectedCountryCode]="selectedCountryCode"
              [countryCodes]="countryCodes"
              [showLabels]="false"
              (changedCountryCode)="changeCodeNumber($event)"
            ></ngx-select-flags>
          </div>
          <div class="col-sm-4">
            <input
              type="text"
              formControlName="customerCallNumber"
              class="form-control"
              [ngClass]="{'is-invalid':f.customerCallNumber.errors && !f.customerCallNumber.errors.required}"
              [class.is-valid]=" f.customerCallNumber.valid"
              required>
          </div>
        </div>

        <div *ngIf="f.customerCallNumber.errors" class="row mt-3 align-items-center" >
          <div *ngIf="f.customerCallNumber.errors.pattern" class="col-sm-1">
            <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>
          </div>
          <div *ngIf="f.customerCallNumber.errors.pattern" class="col-sm-10">
            <p class="text-danger">Ce numéro de téléphone n’est pas valide</p>
          </div>
        </div>
      </fieldset>
      <div class="row mt-3">
        <div class="col-sm-12 text-right" style="vertical-align: middle">
          <button class="btn  btn-warning text-left btn-mobiyo" type="submit" [disabled]="formAddPhone.invalid || disabledUpdatePhoneBtn" >
            Enregistrer mon numéro
            <span class="spinner-border spinner-border-sm align-self-center" role="status" aria-hidden="true" [hidden]="!submitting"></span>
          </button>
        </div>
      </div>
    </fieldset>

  </form>
</div>


<div class="container mt-lg-5" *ngIf="openValidateFormMobile == true"  style="width: 85%">
  <form [formGroup]="formMobilePhoneCode" (ngSubmit)="onSubmitCheckCode()">
    <fieldset class="border form-group p-4">
      <legend  class="w-auto">Valider mon numéro de téléphone mobile</legend>
      <p>{{(definitions | async)?.sms_brand}} vient de vous envoyer un SMS au <span [innerHTML]="formatPhone(this.phoneNumber)"></span> avec un code à 4 chiffres. Merci de le saisir ci-dessus.</p>
      <fieldset class="border form-group p-3">
        <legend class="w-auto">Code</legend>
        <div class="row">
          <div class="col-sm-4">
            <input
              [class.is-invalid]=" fMobile.customerCallNumberCode.errors && !fMobile.customerCallNumberCode.errors.required"
              [ngClass]="{ 'is-valid': fMobile.customerCallNumberCode.valid }" class="form-control"
              type="text"
              formControlName="customerCallNumberCode"
              class="form-control"
            >
          </div>
          <div class="col-sm-8" *ngIf="fMobile.customerCallNumberCode.errors && !fMobile.customerCallNumberCode.errors.required">
            <div class="row">
              <div class="col-sm-2" *ngIf="fMobile.customerCallNumberCode.errors">
                <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>
              </div>
              <div class="col-sm-10 my-auto" *ngIf="fMobile.customerCallNumberCode.errors">
                <p class="text-danger">
                  Ce code n'est pas valide
                </p>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="row mt-3">
        <div class="col-sm-10">
          <button class="btn  btn-warning text-left btn-mobiyo" type="submit" [disabled]="!formMobilePhoneCode.valid" >Valider le code que j'ai reçu</button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-8 sendSMSCountDown" style="display: none">
          <p></p>
        </div>
        <div class="col-sm-8 requestSMS">
          <p>Je n'ai pas reçu de sms ? &nbsp;&nbsp; <a class="nav-link-mobiyo">Recevoir un nouveau code ici !</a></p>

        </div>
        <div class="col-sm-4">
          <a class="nav-link-mobiyo updatePhone" (click)="changeNumber()">Modifier mon numéro de téléphone</a>
        </div>
      </div>
    </fieldset>


  </form>
</div>


<div class="container mt-lg-5" *ngIf="openValidateFormFilaire == true" style="width: 85%">
  <form [formGroup]="formWiredPhoneCode" (ngSubmit)="onSubmitWiredCheckCode()">
    <fieldset class="border form-group p-4">
      <legend  class="w-auto">Valider mon numéro de téléphone Filaire</legend>
      <p>{{(definitions | async)?.sms_brand}} est en train de vous appeler au <span [innerHTML]="formatPhone(this.phoneNumber)"></span> à partir du {{(definitions | async)?.provider_call_number}}  pour vous donner un code à 4 chiffres.</p>
      <p>Merci de le saisir ci-dessous</p>
      <fieldset class="border form-group p-3">
        <legend class="w-auto">Code</legend>
        <div class="row mt-3">
          <div class="col-sm-4">
            <input
              [class.is-invalid]=" fWired.customerCallNumberCode.errors && !fWired.customerCallNumberCode.errors.required "
              [ngClass]="{ 'is-valid': fWired.customerCallNumberCode.valid }" class="form-control"
              formControlName="customerCallNumberCode"
              class="form-control"

            >
          </div>
          <div class="col-sm-8" *ngIf="fWired.customerCallNumberCode.errors && !fWired.customerCallNumberCode.errors.required">
            <div class="row">
              <div class="col-sm-2" *ngIf="fWired.customerCallNumberCode.errors">
                <i class="fa fa-3x fa-exclamation-triangle text-danger "></i>
              </div>
              <div class="col-sm-10 my-auto" *ngIf="fWired.customerCallNumberCode.errors">
                <p class="text-danger">
                  Ce code n'est pas valide
                </p>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="row  mt-3">
        <div class="col-sm-10">
          <button class="btn  btn-warning text-left btn-mobiyo" type="submit" [disabled]="formWiredPhoneCode.invalid" >Valider le code </button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-9 sendSMSCountDown" style="display: none">
          <p></p>
        </div>
        <div class="col-sm-9 requestSMS">
          <p>Je n'ai pas reçu d'appel d'{{(definitions | async)?.sms_brand}}?  &nbsp;&nbsp;  <a class="nav-link-mobiyo" (click)="sendCheckCode()">Appelez-moi de nouveau !</a></p>
        </div>
        <div class="col-sm-3">
          <a class="nav-link-mobiyo updatePhone" (click)="changeNumber()">Modifier le numéro</a>
        </div>
      </div>
    </fieldset>


  </form>
</div>

<!--    Add Phone Modal Success  -->
<div class="modal fade" id="ModalSuccessAddPhone" tabindex="-1" role="dialog" aria-labelledby="ModalSuccessAddPhoneLabel" aria-hidden="false">
  <div class="modal-dialog  modal-dialog-mobiyo" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h6>Ajouter mon numéro de téléphone à appeler</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetToProfiePage()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-11">
            <p >
              Félicitations ! Votre N° de téléphone <span [innerHTML]="formatPhone(this.phoneNumber)"></span> est validé !
            </p>
            <p> Vous pourrez le modifier dans <a (click)="homeService.redirect('/profile')"  class="btn-details" data-dismiss="modal">Mes infos</a> si nécessaire. </p>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal" (click)="resetToProfiePage()">Continuer</button>

      </div>
    </div>
  </div>
</div>

<!--    Mobile phone Modal Error  -->
<div class="modal fade" id="ModalErrorMobilePhone" tabindex="-1" role="dialog" aria-labelledby="ModalErrorMobilePhoneLabel" aria-hidden="false">
  <div class="modal-dialog modal-dialog-mobiyo " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="ModalErrorRegisterLabel"> Ajouter mon numéro de téléphone à appeler en priorité</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p >
              Une erreur vient de survenir et nous ne sommes pas en mesure de vous envoyer un SMS avec le code d'activation.
            </p>
          </div>
        </div>
        <p>Merci de contacter directement {{(definitions | async)?.sms_brand}} au {{(definitions | async)?.provider_call_number}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>

      </div>
    </div>
  </div>
</div>


<!--    Mobile phone Modal Error Code not found  -->
<div class="modal fade" id="ModalErrorMobilePhoneCodeNotFound" tabindex="-1" role="dialog" aria-labelledby="ModalErrorMobilePhoneCodeNotFoundLabel" aria-hidden="false">
  <div class="modal-dialog modal-dialog-mobiyo " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="ModalErrorMobilePhoneCodeNotFoundLabel"> Ajouter mon numéro de téléphone à appeler en priorité</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p >
              Le code que vous avez saisit est erroné, merci de saisir le code que vous avez reçu ou recevez un nouveau code en cliquant sur « en recevoir un nouveau ici ! »
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>

      </div>
    </div>
  </div>
</div>


<!--    Wired phone Modal Error  -->
<div class="modal fade" id="ModalErrorWiredPhone" tabindex="-1" role="dialog" aria-labelledby="ModalErrorWiredPhoneLabel" aria-hidden="false">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="ModalErrorWiredPhoneLabel"> Ajouter mon numéro de téléphone à appeler en priorité</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"  >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p >
              Une erreur vient de survenir et nous ne sommes pas en mesure de vous appeler pour vous communiquer le code d'activation.
            </p>
          </div>


        </div>
        <p>Merci de contacter directement {{(definitions | async)?.sms_brand}} au {{(definitions | async)?.provider_call_number}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>

      </div>
    </div>
  </div>
</div>


<!--    Mobile phone Modal Error  -->
<div class="modal fade" id="ModalErrorAddPhone" tabindex="-1" role="dialog" aria-labelledby="ModalErrorAddPhoneLabel" aria-hidden="false">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="ModalErrorAddPhoneLabel"> Ajouter mon numéro de téléphone à appeler en priorité</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2">
            <i class="fa fa-4x fa-exclamation-triangle text-danger "></i>
          </div>
          <div class="col-md-9">
            <p >
              Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer l'opération demandé.
            </p>
          </div>


        </div>
        <p>Merci de contacter directement {{(definitions | async)?.sms_brand}} au {{(definitions | async)?.provider_call_number}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success text-center" data-dismiss="modal">Continuer</button>

      </div>
    </div>
  </div>
</div>
