import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private  apiUrl =  environment.apiUrlAccountCustomer;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  // create token
  createToken(formData) {
    return this.http.post(`${this.apiUrl}/account-create-token`, formData);
  }

  // mail send
  mailSend(formData) {
    return this.http.post(`${this.apiUrl}/account-mail-send`, formData);
  }

  // check token
  checkToken(customerToken) {
    const params = new HttpParams({
      fromObject: {
        customerToken
      }
    });
    return this.http.get(`${this.apiUrl}/account-check-token`, {params});

  }
 // edit personal info name, first name and gender

  accountUpdatePersonal(formData) {
    return this.http.post(`${this.apiUrl}/account-update-personal`, formData);
  }

  // edit email
  editEmail(formData) {
    return this.http.post(`${this.apiUrl}/account-update-email`, formData);
  }

  // edit email
  editPassword(formData) {
    return this.http.post(`${this.apiUrl}/account-update-password`, formData);
  }

  askClose(formData) {
    return this.http.post(`${this.apiUrl}/account-ask-close`, formData);
  }

  editBirthDate(formData) {
    return this.http.post(`${this.apiUrl}/account-update-birth`, formData);
  }
}
