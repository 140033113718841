<div class="row fieldset-row-mobiyo">
  <div class="col-md-8">
    Mon nom : {{customerName}}
  </div>
  <div class="col-md-4">
    <ng-container *ngIf="customerName;else second">
      <button type="button" (click)="onOpenModal()" class="btn btn-warning info-add-update-btn-mobiyo" data-toggle="modal" data-target="#update-name-modal">
        Modifier
      </button>
    </ng-container>
    <ng-template #second>
      <button type="button" (click)="onOpenModal()" class="btn btn-warning info-add-update-btn-mobiyo" data-toggle="modal" data-target="#update-name-modal">
        Ajouter
      </button>
    </ng-template>
  </div>
  <div class="modal fade" id="update-name-modal" tabindex="-1" aria-labelledby="update-name-modal-label" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="modal-body" *ngIf="!success && !error">
            <div class="row">
              <div class="col-md-12">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <fieldset class="border fieldset-info-mobiyo fieldset-update-info-mobiyo">
                <legend class="w-auto pl-2 pr-2">
                  <ng-container *ngIf="customerOldName;else second1">Changer</ng-container>
                  <ng-template #second1>
                    Ajouter
                  </ng-template>
                  mon nom
                </legend>
                <div class="col-md-12">
                  <p>
                    Si vous voulez
                    <ng-container *ngIf="customerOldName;else second2">changer</ng-container>
                    <ng-template #second2>
                      ajouter
                    </ng-template>
                    le nom associé à votre compte Allo Mégane, vous pouvez le faire ci-dessous. N'oubliez pas de cliquer sur le bouton "Enregistrer les modifications" quand vous avez terminé.
                  </p>
                  <div class="form-group">
                    <label for="new-name">
                      <ng-container *ngIf="customerOldName;else second3">Nouveau nom</ng-container>
                      <ng-template #second3>
                        Ajouter nom
                      </ng-template>
                    </label>
                    <input
                      maxlength="25"
                      formControlName="customerName"
                      type="text"
                      class="form-control"
                      id="new-name"
                      autocomplete="off"
                      [class.is-valid]="f.customerName.valid"
                      [class.is-invalid]="f.customerName.invalid"
                    />
                    <span  class="text-danger" *ngIf="f.customerName.errors?.pattern">
                    Syntaxe du nom est incorrect !
                  </span >
                    <span class="text-danger"  *ngIf="f.customerName.errors?.mustDifferent">
                    Le nouveau nom est identique à l'ancien !
                  </span>
                    <span class="text-danger"  *ngIf="f.customerName.errors?.mustDifferent">
                    Le nouveau nom est identique à l'ancien !
                  </span>
                  </div>
                </div>
                <div class="col-md-12 mb-2">
                  <button type="button" class="btn btn-warning" type="submit" [disabled]="!form.valid">Enregistrer les modifications</button>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="modal-body" *ngIf="success">
            <div class="row">
              <div class="col-md-12">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <fieldset class="border fieldset-info-mobiyo fieldset-update-info-mobiyo">
                <legend class="w-auto pl-2 pr-2">
                  Mes Infos
                </legend>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-2">
                      <i class="fa fa-check-circle fa-4x text-success"></i>
                    </div>
                    <div class="col-md-10 align-self-center">
                      Mise à jour des informations avec succès.
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-2 mt-4">
                  <button type="button" class="btn btn-warning" (click)="onClickBtn()" data-dismiss="modal" *ngIf="success">J'ai compris</button>
                </div>
              </fieldset>

            </div>

          </div>
          <div class="modal-body" *ngIf="error">
            <div class="row">
              <div class="col-md-12">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <fieldset class="border fieldset-info-mobiyo fieldset-update-info-mobiyo">
                <legend class="w-auto pl-2 pr-2">
                  Mes Infos
                </legend>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-2">
                      <i class="fa fa-exclamation-triangle fa-4x text-danger"></i>
                    </div>
                    <div class="col-md-10 align-self-center">
                      <p class="text-danger">
                        Une erreur vient de survenir et nous ne sommes pas en mesure d'effectuer l'opération demandée.
                      </p>
                      <p>
                        Merci d'essayer de nouveau plus tard !
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-2">
                  <button type="button" class="btn btn-warning" (click)="onClickBtn()" data-dismiss="modal" *ngIf="error">Continuer</button>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
