import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID, APP_INITIALIZER, Injector} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './account/login/login.component';
import {ConsultantComponent} from './consultant/consultant.component';
import {RegisterComponent} from './account/register/register.component';
import {LoginGuard} from './_services/login.guard';
import {AddPaymentCardComponent} from './payment/add-payment-card/add-payment-card.component';
import {DatePipe} from '@angular/common';
import {ProfileComponent} from './account/profile/profile.component';
import { NotificationComponent } from './notification/notification.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { PhoneComponent } from './account/phone/phone.component';
import { ConsultantProfileComponent } from './consultant/consultant-profile/consultant-profile.component';
import {IsLoggedInGuard} from './_services/is-loggedin.guard';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {SafePipe} from './_helpers/safe.pipe';
import { PaymentModule } from './payment/payment.module';
import { AccountModule } from './account/account.module';
import { CryptoService } from './_services/crypto.service';
import { NgxSelectFlagsModule } from 'ngx-select-flags';
import {ReviewModule} from './review/review.module';
import {PrivateConversationModule} from './private-conversation/private-conversation.module';
import {StartPrivateConversationComponent} from './private-conversation/start-private-conversation/start-private-conversation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { reducer as accountReducer } from './reducers/account.reducer';
import { reducer as definitionsReducer } from './reducers/definitions.reducer';
import { reducer as sessionReducer } from './reducers/session.reducer';
import { reducer as promotionsReducer } from './reducers/promotion.reducer';
import {DataTablesModule} from 'angular-datatables';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/fr';
import localeDeExtra from '@angular/common/locales/extra/fr';
import {ConsultantModule} from './consultant/consultant.module';
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {CheckSessionGuard} from './_services/check-session.guard';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SigninComponent } from './shared/signin/signin.component';
import {PromotionModule} from './promotion/promotion.module';
import {PaymentComponent} from './payment/payment.component';
import {AccountComponent} from './account/account.component';
import {createCustomElement} from '@angular/elements';
import {ConsultantPlanningComponent} from './consultant/consultant-planning/consultant-planning.component';
import { NgxContentLoadingModule } from 'ngx-content-loading';

registerLocaleData(localeDe, 'fr', localeDeExtra);

const routes: Routes = [
  {path: 'home.html', component: HomeComponent},
  {path: 'index.html' , component: ConsultantComponent, pathMatch: 'full',  canActivate: [CheckSessionGuard]},
  {path: 'registration.html', component: RegisterComponent, canActivate: [IsLoggedInGuard]},
  {path: 'add-payment-card.html', component: AddPaymentCardComponent, canActivate: [LoginGuard , CheckSessionGuard]},
  {path: 'purchases.html', component: PurchaseComponent, canActivate: [LoginGuard , CheckSessionGuard]},
  {path: 'phone.html', component: PhoneComponent, canActivate: [LoginGuard]},
  {path: 'profile.html', component: ProfileComponent, canActivate: [LoginGuard , CheckSessionGuard]},
  {path: 'login.html', component: LoginComponent , canActivate: [IsLoggedInGuard]},
  {path: 'consultant-details.html', component: ConsultantProfileComponent,   canActivate: [CheckSessionGuard]},
  {path: 'start-conversation.html', component: StartPrivateConversationComponent , canActivate: [LoginGuard]},
  {path: '**', redirectTo: 'index.html', pathMatch: 'full'}
];


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotificationComponent,
    PurchaseComponent,
    PhoneComponent,
    SafePipe,
    NavbarComponent,
    SigninComponent,
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes),
        SlickCarouselModule,
        PaymentModule,
        AccountModule,
        ReviewModule,
        PrivateConversationModule,
        NgxSelectFlagsModule,
        NgbModule,
        ConsultantModule,
        StoreModule.forRoot({
            account: accountReducer,
            definitions: definitionsReducer,
            session: sessionReducer,
            promotions: promotionsReducer
        }),
        DataTablesModule,
        NgxDatatableModule,
        TableModule,
        CalendarModule,
        PromotionModule,
        NgxContentLoadingModule
    ],
  providers: [
    LoginGuard,
    IsLoggedInGuard,
    CheckSessionGuard,
    DatePipe,
    CryptoService,
    {provide: LOCALE_ID, useValue: 'fr-FR'}
  ],
  bootstrap: [AppComponent],
  exports: [
  ],
/*   entryComponents :  [
     AccountComponent,
     ConsultantProfileComponent,
     ConsultantComponent,
     LoginComponent,
     SigninComponent,
     PaymentComponent,
     AddPaymentCardComponent,
     PurchaseComponent,
     ProfileComponent,
     StartPrivateConversationComponent,
     PhoneComponent,
     ConsultantPlanningComponent
   ]*/
})
export class AppModule {
 /* constructor(private injector: Injector) {
  }
  ngDoBootstrap() {
    const el = createCustomElement(ConsultantProfileComponent, { injector: this.injector });
    customElements.define('app-consultant-details-component', el);
    const el2 = createCustomElement(ConsultantComponent, { injector: this.injector });
    customElements.define('app-consultant-component', el2);
    const el4 = createCustomElement(AccountComponent, { injector: this.injector });
    customElements.define('app-account-component', el4);
    const el5 = createCustomElement(SigninComponent, { injector: this.injector });
    customElements.define('app-signin-component', el5);
    const el6 = createCustomElement(LoginComponent, { injector: this.injector });
    customElements.define('app-login-component', el6);
    const el7 = createCustomElement(PaymentComponent, { injector: this.injector });
    customElements.define('app-payment-component', el7);
    const el8 = createCustomElement(AddPaymentCardComponent, { injector: this.injector });
    customElements.define('app-add-payment-component', el8);
    const el9 = createCustomElement(AppComponent, { injector: this.injector });
    customElements.define('app-component', el9);
    const el10 = createCustomElement(PurchaseComponent, { injector: this.injector });
    customElements.define('app-purchase-component', el10);
    const el11 = createCustomElement(ProfileComponent, { injector: this.injector });
    customElements.define('app-profile-component', el11);
    const el12 = createCustomElement(StartPrivateConversationComponent, { injector: this.injector });
    customElements.define('app-start-private-conversation-component', el12);
    const el13 = createCustomElement(PhoneComponent, { injector: this.injector });
    customElements.define('app-phone-component', el13);
    const el14 = createCustomElement(ConsultantPlanningComponent, { injector: this.injector });
    customElements.define('app-consultant-planning-component', el14);
  }*/
}




