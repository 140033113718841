import {Component, Input, OnInit} from '@angular/core';
import {Definitions} from '../../_models/definitions';
import {ConsultantService} from '../../_services/consultant.service';

declare var $: any;

@Component({
  selector: 'app-sva',
  templateUrl: './sva.component.html',
  styleUrls: ['./sva.component.css']
})
export class SvaComponent implements OnInit {
  @Input() definitions: Definitions;
  @Input() zoom: string;
  @Input() full = false;
  @Input() isLinkActive = false;
  public svaNumber: string;
  public svaTarif: string;
  public showDetails = false;
  private readonly svaNumbers: any;

  constructor(
    public  consultantservice: ConsultantService
  ) {
    this.svaNumbers = {
      A005: 'Service 0,05€/appel <br/>+ prix appel',
      D040: 'Service 0,40 € / min<br/>+ prix appel',
      A008: 'Service 0,08 €/appel <br/>+ prix appel',
      A009: 'Service 0,09 €/appel  <br/>+ prix appel',
      A010: 'Service 0,10 €/appel <br/>+ prix appel',
      A012: 'Service 0,12 €/appel <br/>+ prix appel',
      A015: 'Service 0,15 €/appel <br/>+ prix appel',
      A020: 'Service 0,20 €/appel <br/>+ prix appel',
      A030: 'Service 0,30 €/appel <br/>+ prix appel',
      A040: 'Service 0,40 €/appel <br/>+ prix appel',
      A050: 'Service 0,50 €/appel <br/>+ prix appel',
      A060: 'Service 0,60 €/appel <br/>+ prix appel',
      A065: 'Service 0,65 €/appel <br/>+ prix appel',
      A079: 'Service 0,79 €/appel <br/>+ prix appel',
      A080: 'Service 0,80 €/appel <br/>+ prix appel',
      A090: 'Service 0,90 €/appel <br/>+ prix appel',
      A099: 'Service 0,99 €/appel <br/>+ prix appel\n',
      A100: 'Service 1 €/appel <br/>+ prix appel',
      A150: 'Service 1,5 €/appel <br/>+ prix appel',
      A199: 'Service 1,99 €/appel <br/>+ prix appel',
      A200: 'Service 2 €/appel <br/>+ prix appel',
      A250: 'Service 2,5 €/appel <br/>+ prix appel',
      A299: 'Service 2,99 €/appel <br/>+ prix appel',
      A300: 'Service 3 €/appel <br/>+ prix appel',
      D005: 'Service 0,05 €/min  <br/>+ prix appel',
      D006: 'Service 0,06 €/min <br/>+ prix appel',
      D009: 'Service 0,09 €/min <br/>+ prix appel',
      D010: 'Service 0,10 €/min <br/>+ prix appel',
      D012: 'Service 0,12 €/min <br/>+ prix appel',
      D015: 'Service 0,15 €/min  <br/>+ prix appel',
      D018: 'Service 0,18 €/min <br/>+ prix appel',
      D020: 'Service 0,20 €/min  <br/>+ prix appel',
      D025: 'Service 0,25 €/min <br/>+ prix appel',
      D030: 'Service 0,30 €/min <br/>+ prix appel',
      D035: 'Service 0,35 €/min <br/>+ prix appel',
      D045: 'Service 0,45 €/min <br/>+ prix appel',
      D050: 'Service 0,50 €/min <br/>+ prix appel',
      D060: 'Service 0,60 €/min  <br/>+ prix appel',
      D070: 'Service 0,70 €/min <br/>+ prix appel',
      D080: 'Service 0,80 €/min <br/>+ prix appel',
      M001: 'Service 0,08 €/appel  <br/>+ 0,02€/min',
      M002: 'Service 0,12 €/appel <br/>+ 0,04€/min'
    };
  }

  ngOnInit(): void {
    this.svaNumber = this.numberWithSpaces(this.definitions.sva_number);
    if (this.definitions.customer_countries?.length && this.definitions.customer_countries[0] === 'FR') {
      this.showDetails = true;
      this.svaTarif = this.svaNumbers[this.definitions.sva_number_tariff];
    }

  }

  numberWithSpaces(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  showSVModal() {
    this.consultantservice.showSVAModalAction(this.isLinkActive);
  }


}
