import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProfileService} from '../../_services/profile.service';
declare var $: any;

@Component({
  selector: 'app-name-update',
  templateUrl: './name-update.component.html',
  styleUrls: ['./name-update.component.css']
})
export class NameUpdateComponent implements OnInit {

  @Input() customerName: string;
  form: FormGroup;
  hasError = false;
  @Output() newName = new EventEmitter<string>();
  formData: any;
  success = false;
  error = false;
  customerOldName: string;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
  ) { }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId'),
      customerOldName: [{value: '', disabled: true}],
      customerName: ['', [
        Validators.required,
        Validators.pattern('[a-zA-Z _\\-,\'éèà]+$')
      ]],
    });
    $('#update-name-modal')
      .on('show.bs.modal', () => {
        this.form.patchValue({
          customerOldName: this.customerName,
          customerName: this.customerName
        });
      });
  }

  onSubmit() {
    this.form.value.customerName = this.form.value.customerName.trim();

    if (this.form.valid) {
      this.profileService.accountUpdatePersonal(this.form.value).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            // this.customerName = this.form.value.customerName;
           // this.next();
            $('#update-name-modal').modal('hide');
            this.newName.emit(this.form.value.customerName);

          } else {
            console.log('error: ', result);
            this.next(false);
          }
        },
        (error) => {
          console.log('error :', error);
          this.next(false);
        }
      );
    } else {
      console.log('Form not Valid', this.form.errors);
      this.next(false);
    }

  }

  next(state = true): void {
    this.success = state;
    this.error = !state;
  }

  clearError() {
    this.hasError = false;
  }

  onClickBtn() {
    $('#update-name-modal').modal('hide');
  }

  onOpenModal() {
    this.success = false;
    this.error = false;
  }


}
