import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {LoginService} from '../../_services/login.service';
import {ConsultantService} from '../../_services/consultant.service';
import {DefinitionService} from '../../_services/definition.service';
import {PrivateConversationService} from '../../_services/private-conversation.service';
import {ForfaitService} from '../../_services/forfait.service';
import {RegisterService} from '../../_services/register.service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {Definitions} from '../../_models/definitions';
import {AppState} from '../../app.state';
import {Store} from '@ngrx/store';
import {NoticeService} from '../../_services/notice.service';
import {Location} from '@angular/common';
import {HistoryService} from '../../_services/history.service';
import {HomeService} from '../../_services/home.service';
import {CryptoService} from '../../_services/crypto.service';
import { timer } from 'rxjs';

declare const $: any;


@Component({
  selector: 'app-start-private-conversation',
  templateUrl: './start-private-conversation.component.html',
  styleUrls: ['./start-private-conversation.component.css']
})
export class StartPrivateConversationComponent implements OnInit {

  apiUrl = environment.apiUrlIvr;
  merchantId = environment.merchantId;
  public startTimerSubject$ = new BehaviorSubject(null);
  public currentUserSubject$ = new BehaviorSubject(null);
  currentUser = this.currentUserSubject$.asObservable();

  merStatus = this.startTimerSubject$.asObservable();
  customerId = localStorage.getItem('customerId');
  customerSessionId = localStorage.getItem('customerSessionId');
  transactionId: any;
  cardOneClickId: any;
  historyId: any;
  customerEmail: string;
  customerCallNumber = null;
  consultantId: any;
  consultantCallNumber: any;
  consultantName: any;
  consultantCost: any;
  callInProgress = true;
  callClientInProgress = false;
  minDelayTaxing: any;
  sessionId: any;
  conversationStatus = undefined;
  paymentStatus: any;
  lastTransactionId: any;
  callConsultantConnected = false;
  callClientConnected = false;
  callClientLost = false;
  callConsultantLost = false;
  merOk = false;
  interval;
  time = 0;
  conversationDuration;
  callDuration = 0;
  sumCallDuration = 0;
  lastCallDuration = 0;
  total: any;
  price;
  subscriptionStatus: Subscription;
  subscriptionTimer: Subscription;
  totalformated: any;
  cardPartialNumber: any;
  consultationMode: any;
  definitions: Observable<Definitions>;
  customerNoticeEnabled = false;
  goBack = false;
  callEnd = false;
  isEmpty: boolean;
  durationBeforExtandCall: any;
  startDate: any;
  currentDate: any;
  startDateCheck = false;
  numberOfExtendedCall = 1;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private consultantService: ConsultantService,
    private definitionService: DefinitionService,
    private privateConversarion: PrivateConversationService,
    private forfaitService: ForfaitService,
    private registerService: RegisterService,
    private noticeService: NoticeService,
    private historyService: HistoryService,
    private datePipe: DatePipe,
    private router: Router,
    private store: Store<AppState>,
    private _location: Location,
    public homeService: HomeService,
    private cryptoService: CryptoService
  ) {
    this.definitions = store.select('definitions');
  }

  ngOnInit(): void {

    this.getConsultantInfo();
    this.getSelectedConvTypeInfo();
    this.gteCustomerInfo();
    this.startConversation();

    this.definitions.subscribe((definitions:any) => {
      this.durationBeforExtandCall = definitions?.duration_before_extand_call;
    });

    $('#promotionPopup')
      .on('hidden.bs.modal', () => {

        this.redirectToHomePage();

      });

    $('#ConsultantLost, #ClientLost')
      .on('hidden.bs.modal', () => {

          this._location.back();

      });

    $('#BillingActivated')
      .on('hidden.bs.modal', () => {

        this.redirectToHomePage();

      });

  }

  startConversation() {

    const durationInSeconds = this.callDuration * 60;



    const data = {
      merchantId: String(this.merchantId),
      customerId: this.customerId.toString(),
      consultantId: this.consultantId,
      maxDuration: durationInSeconds,
      isRecord: 'true',
      transactionId: this.historyId
    };
    const isEmpty =  Object.values(data).some(x => (x === null || x === ''  || x === undefined));
    if (isEmpty) {
      this.homeService.redirect('/');
    } else  {
      this.privateConversarion.launchCall(data).subscribe(
        (res: any) => {
          this.sessionId = res.sessionId;
          localStorage.setItem('osid', this.sessionId);
          localStorage.setItem('nsid', this.sessionId);

          this.getConversationStatus();
        },
        error => {
          console.log(error);
          $('#ModalErrorStartConversation').modal('show');
        }
      );

    }
  }

  getConversationStatus() {

    this.subscriptionTimer = interval(1000)
      .subscribe((res) => {
        this.startTimer();

      });



    this.subscriptionStatus = interval(2000)
      .subscribe((val) => {
          this.getliveconversationStatus();

      });

  }

  gteCustomerInfo() {
    this.loginService.getcurrentUser(this.customerSessionId, this.customerId).subscribe(
      (res: any) => {
        this.currentUserSubject$.next(res);
        this.customerEmail = res.customerEmail;
        this.customerCallNumber = res.principalCallNumber;
      },
      error => {

      }
    );
  }

  getConsultantInfo() {
    this.consultantService.consultantId.subscribe(
      x => {
        this.consultantId = x;
      });
    this.consultantService.consultantCallNumber.subscribe(
      x => {
        this.consultantCallNumber = x;

      }
    );
    this.consultantService.consultantName.subscribe(
      x => {
        this.consultantName = x;

      }
    );
    this.consultantService.consultantCost.subscribe(
      x => {
        this.consultantCost = x;

      }
    );
  }


  getliveconversationStatus() {

    const dataSession = {
      sessionId: this.sessionId,
      sessionType: 'mer',
      merchantId: this.merchantId


    };
    this.privateConversarion.getLiveStatus(dataSession).subscribe(
      (res: any) => {

        this.conversationStatus = res.status;
        this.paymentStatus = res.payment_status;
        this.lastTransactionId = res.transaction_id;


        if (this.conversationStatus === 'CALL_EXTENDED') {
          this.sessionId = localStorage.getItem('nsid')
          this.numberOfExtendedCall += 1;
        }

        if (this.conversationStatus === 'new' || this.conversationStatus === 'IN_PROGRESS') {
          this.callInProgress = true;
          localStorage.removeItem('pdt');
        }
        if (this.conversationStatus === 'CALL_CONSULTANT_CONNECTED') {
          this.callConsultantConnected = true;
          this.callClientInProgress = true;
          this.callInProgress = false;
        }
        if (this.conversationStatus === 'CALL_CLIENT_CONNECTED' || this.conversationStatus === 'MER_OK' || this.conversationStatus === 'CALL_EXTENDED') {
          this.callClientConnected = true;
          this.merOk = true;
          this.callInProgress = false;
          this.callClientInProgress = false;
          this.startTimerSubject$.next(this.conversationStatus);
          this.startTimer();
        }
        if (
          this.conversationStatus === 'CALL_CONSULTANT_LOST' ||
          this.conversationStatus === 'SESSION_KILLED') {
          if (this.conversationStatus === 'CALL_CONSULTANT_LOST') {
            this.privateConversarion.sendSMSAlert(String(this.merchantId), this.consultantId, new Date().toLocaleTimeString())
              .subscribe((result: any) => {
                console.log('sendSMSAlert', result);
              });
          }
          this.callConsultantLost = true;
          this.callClientInProgress = false;
          $('#ConsultantLost').modal('show');
          this.subscriptionStatus.unsubscribe();
        }
        if (this.conversationStatus === 'CALL_CLIENT_LOST' ||
          this.conversationStatus === 'SESSION_KILLED') {
          this.callClientLost = true;
          $('#ClientLost').modal('show');
          this.subscriptionStatus.unsubscribe();
        }
        if (this.conversationStatus === 'CALL_CLIENT_HANGUP' || this.conversationStatus === 'CALL_CONSULTANT_HANGUP') {
         this.callEndOperations();
        }
        if (this.conversationStatus === 'CALL_EXCEED_DURATION') {
          this.callEndOperations();
        }


      },
      error => {
        console.log(error);
        this.subscriptionStatus.unsubscribe();
        $('#ModalErrorContinueConversation').modal('show');

      }
    );

  }


  startTimer() {
    let val = 0;
    let play = false;
    if (this.startTimerSubject$.value === 'MER_OK' || this.startTimerSubject$.value==='CALL_CLIENT_CONNECTED' || this.startTimerSubject$.value === 'CALL_EXTENDED') {
      val = val + 1;
    }

    play = val === 1;
    if (play === true) {

        if(!this.startDateCheck)
        {
          this.startDate = new Date();
          this.startDateCheck = true;
        }
        this.currentDate = new Date();
        this.time = ((this.currentDate).getTime()-(this.startDate).getTime())/1000;

      this.conversationDuration = this.transform(this.time);
    }

    if(this.time > (((this.sumCallDuration)*60)-this.durationBeforExtandCall) && this.conversationStatus != "CALL_EXCEED_DURATION"
    &&  this.conversationStatus != "CALL_CLIENT_HANGUP" &&  this.conversationStatus != "CALL_CONSULTANT_HANGUP"
    )
    {
      $('#converationReSelect').modal('show');
    }


  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const seconds: number =  Math.floor(value - minutes * 60);
    return minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ':' + (seconds).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
  }

  pauseTimer() {
    clearInterval(this.interval);
  }
   setHistory() {

     const data = {
       merchantId: String(environment.merchantId),
       partialNumber: this.cardPartialNumber,
       amount: this.total.toString(),
       customerEmail: this.customerEmail,
       consultantId: String(this.consultantId),
       consultationMode: this.consultationMode,
       duration: '00:' + this.conversationDuration,
       dateTime: this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss')
     };

     this.forfaitService.setHistory(data).subscribe(
       (res: any) => {

         this.historyId = res.id;
         this.OnsubmitGetMoney(this.historyId);

         if (res.id) {
           this.customerNoticeEnabled = true;

           this.noticeService.showAddNoticeEndCall.next(true);
           $('#review').modal('show');

         } else {
           console.log('#BillingActivated');
           $('#BillingActivated').modal('show');
         }



     });
   }
  // GET Money
  OnsubmitGetMoney(historyId) {
    this.forfaitService.getMoney(this.customerId, this.cardOneClickId, this.total, this.transactionId, historyId).subscribe(
      (res: any) => {
        if (res.statusCode !== -4) {
          console.log('peyment done');
        } else {
          $('#BillingActivated').modal('hide');
          $('#ModalErrorContinueConversation').modal('show');
        }
    },
    err => {
      console.log('peyment error' + err);
    });
  }

  getSelectedConvTypeInfo() {
    this.privateConversarion.selectedConvInfoSubject.subscribe(
      (res: any) => {
        if ( res === null) {
          const storedProduct = this.cryptoService.get(localStorage.getItem('pdt'));
          this.isEmpty =  Object.values(storedProduct).some(x => (x === null || x === ''  || x === undefined));
          if (this.isEmpty) {
            this.homeService.redirect('/');
          } else {
            res = JSON.parse(storedProduct);
          }

        }
        this.callDuration = res?.duration;
        this.sumCallDuration = this.sumCallDuration + this.callDuration;
        this.price = res?.price;
        this.transactionId = res?.transactionId;
        this.cardOneClickId = res?.OneClickId;
        this.cardPartialNumber = res?.partialNumber;
        this.consultationMode = res?.consultationMode;
        this.historyId = res?.historyId;
      }, error => {
        console.log('Error getSelectedConvTypeInfo');
      }
    );
  }
  redirectToHomePage() {
    this.homeService.redirect('/');
    $('#ModalErrorContinueConversation').modal('hide');
    $('#ModalErrorStartConversation').modal('hide');
    $('div').removeClass('modal-backdrop fade show');
  }

  showNotice() {
    this.noticeService.showAddNoticeEndCall.next(true);
    this.customerNoticeEnabled = true;
    $('#review').modal('show');
    console.log('#review');
  }


  callEndOperations() {

    $('#converationReSelect').modal('hide');

    this.callInProgress = true;
    this.callConsultantConnected = false;
    this.callEnd = true;
    if (this.paymentStatus === 'SUCCESS' && this.lastTransactionId !== '') {
          console.log('SUCCESS paiement');
          this.showNotice();
          this.subscriptionStatus.unsubscribe();
          this.subscriptionTimer.unsubscribe();
    } else if (this.paymentStatus === 'NO_PAYMENT' && this.lastTransactionId !== '') {

       console.log('Short Call');
       $('#BillingDeactivated').modal('show');
       this.subscriptionStatus.unsubscribe();
       this.subscriptionTimer.unsubscribe();

     } else if (this.paymentStatus === 'ERROR') {

      const startDay = new Date();
      const endDay = new Date();

      const startDayStr = startDay.getFullYear() + '-' + ('0' + (Number(startDay.getMonth()) + 1)).slice(-2) + '-' + ('0' + Number(startDay.getDate())).slice(-2) + ' 00:00';
      const endDayStr = endDay.getFullYear() + '-' + ('0' + (Number(endDay.getMonth()) + 1)).slice(-2) + '-' + ('0' + Number(endDay.getDate())).slice(-2) + ' 23:59';

      const startDate  = startDayStr;
      const endDate = endDayStr;

      const params = new  HttpParams({
        fromObject : {
          merchantId: String(this.merchantId),
          customerId: this.customerId,
          startDate: startDayStr,
          endDate: endDayStr,
          transactionId: this.lastTransactionId
        }
      });

      this.historyService.getTransaction(params).subscribe(
        (res: any) => {

          if (res && res.body === undefined) {

            this.conversationDuration = res[0].service_duration;
            this.totalformated = res[0].amount;
            console.log('Error API Payment');
            $('#BillingActivated').modal('show');
            this.subscriptionStatus.unsubscribe();
            this.subscriptionTimer.unsubscribe();

          } else {
            this.redirectToHomePage();
          }

        },
        err => {
          this.redirectToHomePage();
        });


     } else {

     }



  }



}






