import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ForfaitService {
   apiServiceType = environment.apiUrlServiceType;
   apiPayment = environment.apiUrlPayment;
   apiHistory = environment.apiUrlHistory;

  private data: any;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  getBuy(merchantId, officeId) {
    const p = new HttpParams({
      fromObject: {
        merchantId,
        officeId,
        prioritySort: 'SMALLER'
      }
    });

    return this.http.get(`${this.apiServiceType}/get-buy`, {params: p});
  }

  getAskMoney(customerId, cardOneClickId, currency, amount, historyId = null) {
    const p = new HttpParams({
      fromObject: {
        customerId: customerId || localStorage.getItem('customerId'),
        cardOneClickId,
        currency,
        amount,
        historyId
      }
    });
    return this.http.get<any>(`${this.apiPayment}/get_payment_authorization`, {params: p});
  }

  setHistory(data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${this.apiHistory}/set-history`, data, {headers});
  }

  getMoney(customerId, cardOneClickId , amount , authorizationId , historyId) {
    const params = new HttpParams({
      fromObject: {
        customerId: customerId || localStorage.getItem('customerId'),
        cardOneClickId,
        amount,
        authorizationId,
        historyId
      }
    });
    return this.http.get(`${this.apiPayment}/get_money`, {params});
  }
}
