import {CryptoService} from '../app/_services/crypto.service';

const cryptoService = new CryptoService();

export const environment = {
  production: false,
  version: 'v1.0',
  apiUrlPayment: 'https://vcb.dev.api.mobiyo.com/payment',
  apiUrlAccountCustomer: 'https://vcb.dev.api.mobiyo.com/customer-account',
  apiUrlConsultant: 'https://vcb.dev.api.mobiyo.com/consultant-planning',
  apiUrlDefinition: 'https://common.dev.api.mobiyo.com/definition',
  apiUrlNotice: 'https://vcb.dev.api.mobiyo.com/notice',
  cryptoKey: '4aEyZMocyH5UqIzrNhgkqtJewwZE3aHH',
  apiUrlIvr: 'https://mws.mediakiosque.com/rest/voyanceweb',
  apiUrlServiceType: 'https://vcb.dev.api.mobiyo.com/type-buy-cb',
  apiUrlHistory: 'https://vcb.dev.api.mobiyo.com/history',
  apiUrlDomain : 'https://vcb.dev.api.mobiyo.com/domain_client',
  apiUrlPromotion : 'https://vcb.dev.api.mobiyo.com/promotion',
  merchantId: parseInt(cryptoService.get(localStorage.getItem('m')), 10),
  officeId: parseInt(cryptoService.get(localStorage.getItem('o')), 10)
};
