import {Component, OnInit} from '@angular/core';
import {ConsultantService} from '../_services/consultant.service';
import {LoginService} from '../_services/login.service';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {NoticeService} from '../_services/notice.service';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DefinitionService} from '../_services/definition.service';
import {Definitions} from '../_models/definitions';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import * as dayjs from 'dayjs';
import 'dayjs/locale/fr';

import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import {Session} from '../_models/session';
import {SessionService} from '../_services/session.service';
import {Router} from '@angular/router';
import {CryptoService} from '../_services/crypto.service';
import {HomeService} from '../_services/home.service';


declare var $: any;

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.css'],
  providers: [NgbRatingConfig]
})
export class ConsultantComponent implements OnInit {
  definitions: Observable<Definitions>;
  session: Observable<Session>;

  data: any;
  isValidtoken: boolean;
  isLoggedIn$: Observable<boolean>;
  listData: any;
  listDataFiltered: any = [];
  listDataOriginal: any;
  consultationModesFilter: any = [];
  voyanceTypesFilter: object = null;
  languagesTypesFilter: object = null;
  filters: any = {consultantName : '', consultationModesFilter: [], voyanceTypesFilter : [], languagesTypesFilter : []};
  consultantNotice: any;
  consultantReviews: any;
  transactions: any;
  consultantImageUrl: any;
  consultantName: any;
  customerNoticeNotation = 0;
  hovered = 0;
  readonly = false;
  formAddReview: FormGroup;
  customerRemark: string;
  consultantId: any;
  transactionStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tooltipClass: string;
  startHour;
  endHour;
  customerCountries: string[];
  svaNumber: string;
  svaNumberTariff: string;
  svaNumberPrice: string;
  svaNumbers = {
    A005: 'Service 0,05€/appel <br/> + prix appel',
    D040: 'Service 0,40€/min <br/> + prix appel',
    A008: 'Service 0,08 €/appel <br/> + prix appel',
    A009: 'Service 0,09 €/appel  <br/> + prix appel',
    A010: 'Service 0,10 €/appel <br/>  + prix appel',
    A012: 'Service 0,12 €/appel <br/>  + prix appel',
    A015: 'Service 0,15 €/appel <br/>  + prix appel',
    A020: 'Service 0,20 €/appel <br/>  + prix appel',
    A030: 'Service 0,30 €/appel <br/> + prix appel',
    A040: 'Service 0,40 €/appel <br/> + prix appel',
    A050: 'Service 0,50 €/appel <br/> + prix appel',
    A060:  'Service 0,60 €/appel <br/>+ prix appel',
    A065: 'Service 0,65 €/appel <br/> + prix appel',
    A079: 'Service 0,79 €/appel <br/> + prix appel',
    A080: 'Service 0,80 €/appel <br/>  + prix appel',
    A090: 'Service 0,90 €/appel <br/> + prix appel',
    A099: 'Service 0,99 €/appel <br/> + prix appel\n',
    A100: 'Service 1 €/appel <br/> + prix appel',
    A150: 'Service 1,5 €/appel <br/>  + prix appel',
    A199: 'Service 1,99 €/appel <br/> + prix appel',
    A200: 'Service 2 €/appel <br/> + prix appel',
    A250: 'Service 2,5 €/appel <br/> + prix appel',
    A299: 'Service 2,99 €/appel <br/> + prix appel',
    A300: 'Service 3 €/appel <br/>  + prix appel',
    D005: 'Service 0,05 €/min  <br/>  + prix appel',
    D006: 'Service 0,06 €/min <br/>  + prix appel',
    D009: 'Service 0,09 €/min <br/>+ prix appel',
    D010: 'Service 0,10 €/min <br/> + prix appel',
    D012: 'Service 0,12 €/min <br/> + prix appel',
    D015: 'Service 0,15 €/min  <br/> + prix appel',
    D018: 'Service 0,18 €/min <br/> + prix appel',
    D020: 'Service 0,20 €/min  <br/> + prix appel',
    D025: 'Service 0,25 €/min <br/> + prix appel',
    D030: 'Service 0,30 €/min <br/> + prix appel',
    D035: 'Service 0,35 €/min <br/> + prix appel',
    D045: 'Service 0,45 €/min <br/> + prix appel',
    D050: 'Service 0,50 €/min <br/> + prix appel',
    D060: 'Service 0,60 €/min  <br/>  + prix appel',
    D070: 'Service 0,70 €/min <br/>  + prix appel',
    D080: 'Service 0,80 €/min <br/>  + prix appel',
    M001: 'Service 0,08 €/appel  <br/>+ 0,02€/min',
    M002: 'Service 0,12 €/appel <br/> + 0,04€/min'
  };
  showSVADetails = false;
  consultantInfo: any;
  result: any;
  isLogging: boolean;
  langTitle = {FR: '', ES: '¡ Yo también hablo español !', EN: 'I also speak english !' };
  consultationModesTitle = {AUDIOTEL: 'Je consulte en appelant un numéro surtaxé', CB: ' Avec ma CB, j\'effectue une conversation privée', CHAT: 'Avec ma CB, je chatte avec un consultant', MAIL: 'Avec ma CB, je reçois ma consultation par email' };
  public showAddNotice: any;
  public showAllNotice: any;
  activateLink = false;
  voyanceTypes = [
    "tarologist_type",
    "runes_type",
    "astrologer_type",
    "fortune_teller_type",
    "oracle_type",
    "numerologist_type",
    "psychic_seer_type",
    "pendulum_type",
    "dowsing_type",
    "medium_type",
  ];
  loading=true;


  constructor(
    public  consultantservice: ConsultantService,
    private  loginservice: LoginService,
    private  noticeService: NoticeService,
    private  definitionService: DefinitionService,
    config: NgbRatingConfig,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private sessionService: SessionService,
    private router: Router,
    private cryptoService: CryptoService,
    private homeService: HomeService
) {
    dayjs.extend(utc);

    dayjs.extend(timezone);

    this.definitions = store.select('definitions');
    config.max = 5;
    config.readonly = true;

    this.session = store.select('session');

    this.session.subscribe(({isLoggedIn}) => {
      this.isLogging = !isLoggedIn;
    });
  }


  get f() {
    return this.formAddReview.controls;
  }

  ngOnInit(): void {
    dayjs.locale('fr');
    this.isLoggedIn$ = this.loginservice.isLoggedIn;

    this.definitions.subscribe(definitions => {
      environment.merchantId = definitions?.merchantId;
      environment.officeId = definitions?.officeId;
      this.svaNumber = definitions?.sva_number;
      this.svaNumberTariff = definitions?.sva_number_tariff;
      this.customerCountries = definitions?.customer_countries || [];
      this.getSVANumberData(this.customerCountries);
      this.listConsultant();

    });
    this.formAddReview = this.fb.group({
      customerRemark: ['', [
        Validators.required,
      ]],
    });
    $('#modalConsultantReviews').on('hidden.bs.modal', () => {

      $('.hiddenNotice').hide();
      $('#btnShowAllNotices').show();
      this.customerNoticeNotation = 0;
      this.formAddReview.reset();
      $('#consultantAddNotice').hide();
      $('#consultantAllNotices').show();
      this.showAllNotice = false;

    });
    this.isValidtoken = localStorage.getItem('customerSessionId') !== null;
    this.isLoggedIn$ = this.loginservice.isLoggedIn;
    this.consultantNotice = [];
    this.consultantReviews = [];
    this.transactions = [];

  }


  listConsultant() {
    if (environment.merchantId && environment.officeId ) {
      this.consultantservice.sendGetConsutalnt(environment.merchantId, environment.officeId).subscribe(
      (res: any) => {
        this.loading = false;
        this.listData = res.body.data;
        this.listData.forEach(consultant => {
          const result = this.consultantservice.handle(consultant);
          consultant.statut = result.statut ;
          consultant.statutColor = result.colorClass ;
          consultant.prestationSorted = result.prestations;
          consultant.statutCode = result.statutCode;
          consultant.cost = result.cost;
          consultant.date = result.date;
          consultant.service = result.service;
        });
        this.listData .sort((a, b) => {
          if (b.statutCode === -1) { return -1; }
          if ((a.statutCode === 0 || a.statutCode === 1) && (b.statutCode === 0 || b.statutCode === 1)) {
            return  (a.statutCode < b.statutCode ||  (b.service !== 'Conversation' && a.statutCode === 0)) ? -1 : 1;
          }
          if (b.statutCode === 2) { return dayjs(a.date) < dayjs(b.date) ? -1 : 1; }
          return 1;
        });

        this.listDataOriginal = this.listData;

      },
      error => {
        console.log(error);
      }
    );
  }
  }

  ModalConsultantReviews(consultant) {



    this.noticeService.showAllNotice.subscribe(res => {
      this.showAllNotice = res;
    });
    this.noticeService.showAddNotice.subscribe(res => {
      this.showAddNotice = res;
    });

    this.noticeService.showAddNotice.next(false);
    this.noticeService.showAllNotice.next(true);


    this.consultantInfo = consultant;
    this.consultantNotice = [];
    this.consultantReviews = [];
    this.transactions = [];
    this.consultantId = this.consultantInfo.consultantid;
    this.getConsultantNotice(this.consultantInfo.consultantid);
    this.getConsultantReviews(this.consultantInfo.consultantid);
    if (this.isLoggedIn$) {
      this.getCustomerTransactions(this.consultantInfo.consultantid);

    }
    this.consultantImageUrl = this.consultantInfo.consultantpictureurl;
    this.consultantName = this.consultantInfo.consultantlogin;

  }


  getConsultantNotice(consultantId) {

    this.noticeService.consultantNotice(environment.merchantId, consultantId).subscribe(
      (result: any) => {

        if (result.statusCode === undefined) {

          this.consultantNotice = result.body;

        } else {
          $('#modalConsultantReviewsError').modal('show');

        }

      },
      (error: any) => {
        $('#modalConsultantReviewsError').modal('show');

      }
    );

  }

  getConsultantReviews(consultantId) {

    this.noticeService.consultantReviews(environment.merchantId, consultantId).subscribe(
      (result: any) => {
        this.consultantReviews = result.body;
        if (result.statusCode === undefined) {
          $('#modalConsultantReviews').modal('show');

        } else {
          $('#modalConsultantReviewsError').modal('show');

        }

      },
      (error: any) => {
        $('#modalConsultantReviewsError').modal('show');

      }
    );

  }


  getCustomerTransactions(consultantId) {
    const merchantId = environment.merchantId;
    this.noticeService.customerTransactions(
      merchantId,
      localStorage.getItem('customerId'),
      localStorage.getItem('customerSessionId'),
      consultantId
    ).subscribe(
      (result: any) => {
        if (result.body[0]) {
          this.transactions = result.body[0];
          this.transactionStatus.next(true);
          console.log('transactionStatus', this.transactionStatus);
        } else {
          this.transactionStatus.next(false);

        }
      },
      (error: any) => {
        $('#modalConsultantReviewsError').modal('show');

      }
    );

  }

  showAllNotices() {
    $('#reviews').css('height', '100%');
    $('.hiddenNotice').show();
    $('#btnShowAllNotices').hide();
  }

  onSubmit() {
    this.customerRemark = this.formAddReview.value.customerRemark;

    const dataCustomerNoticeEnable = {
      merchantId: environment.merchantId,
      consultantId: parseInt(this.consultantId, 10),
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      transactionId: this.transactions.transaction_id,
      sessionId: localStorage.getItem('customerSessionId'),
    };

    const dataCustomerNoticeSet = {
      transactionId: this.transactions.transaction_id,
      sessionId: localStorage.getItem('customerSessionId'),
      customerNoticeNotation: this.customerNoticeNotation,
      customerRemark: this.formAddReview.value.customerRemark
    };

    this.noticeService.customerNoticeEnable(dataCustomerNoticeEnable).subscribe(
      (result: any) => {
        console.log('customerNoticeEnable success');

        this.noticeService.customerNoticeSet(dataCustomerNoticeSet).subscribe(
          (result2: any) => {
            console.log('customerNoticeSet success');
            $('#consultantAddNotice').hide();
            $('#consultantAllNotices').show();
            this.getCustomerTransactions(this.consultantId);
            this.customerNoticeNotation = 0;
            this.formAddReview.reset();

          },
          (error: any) => {
            console.log('customerNoticeSet error');
            this.getCustomerTransactions(this.consultantId);
            this.customerNoticeNotation = 0;
            this.formAddReview.reset();

          });

      }, (error => {
        console.log('customerNoticeEnable error');
        // this.getCustomerTransactions(this.consultantId);
        this.customerNoticeNotation = 0;
        this.formAddReview.reset();
        $('#modalConsultantReviews').modal('hide');
        $('#modalConsultantAddReviewsError').modal('show');

      })
    );
  }

  addNotice() {
    $('#consultantAllNotices').hide();
    $('#consultantAddNotice').show();
    this.noticeService.showAddNotice.next(true);
    this.noticeService.showAllNotice.next(false);

  }


  mouseOver(statusCode , isActive) {
    if (isActive === 1  && statusCode === 0) {
      this.tooltipClass = 'tooltip-available';
      this.activateLink = true ;

    }
    if (statusCode === 1 || isActive === 2) {
      this.tooltipClass = 'tooltip-busy';
      this.activateLink = false ;


    }
    if (isActive === 0 ) {
      this.tooltipClass = 'tooltip-unavailable';
      this.activateLink = false ;

    }

  }

  getSVANumberData(customerCountries) {
    this.showSVADetails = customerCountries[0] === 'FR';
    this.svaNumberPrice = this.svaNumbers[this.svaNumberTariff];
  }

  mouseOverLang() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  showSVAModal(activateLink) {
   this.consultantservice.showSVAModalAction(activateLink);
  }
  redirectToConsultant(consultantId) {
    // const  redirectTo = '/consultant-details';
    localStorage.setItem('ctcx', this.cryptoService.set(consultantId));
    this.homeService.redirect('/consultant-details');
  }


  filter(type) {


    if (type === 'Audiotel') {

      if ($('#Audiotel').hasClass('fa-audiotel-unavailable')) {
        $('#Audiotel').addClass('fa-audiotel-available').addClass('available').removeClass('fa-audiotel-unavailable');
      } else {
        $('#Audiotel').addClass('fa-audiotel-unavailable').removeClass('fa-audiotel-available').removeClass('available');
      }

    }

    if (type === 'Conversation') {

      if ($('#Conversation').hasClass('fa-conv-unavailable')) {
        $('#Conversation').addClass('fa-conv-available').addClass('available').removeClass('fa-conv-unavailable');
      } else {
        $('#Conversation').addClass('fa-conv-unavailable').removeClass('fa-conv-available').removeClass('available');

      }

    }


    if (type === 'Chat') {

      if ($('#Chat').hasClass('fa-chat-unavailable')) {
        $('#Chat').addClass('fa-chat-available').addClass('available').removeClass('fa-chat-unavailable');
      } else {
        $('#Chat').addClass('fa-chat-unavailable').removeClass('fa-chat-available').removeClass('available');
      }


    }

    if (type === 'Mail') {


      if ($('#Mail').hasClass('fa-mail-unavailable')) {
        $('#Mail').addClass('fa-mail-available').addClass('available').removeClass('fa-mail-unavailable');
      } else {
        $('#Mail').addClass('fa-mail-unavailable').removeClass('fa-mail-available').removeClass('available');

      }

    }

    if (type === 'EN' || type === 'ES') {


      if ($('#'+type).hasClass('flag-border')) {
        $('#'+type).removeClass('flag-border').removeClass('available');
      } else {
        $('#'+type).addClass('flag-border').addClass('available');

      }

    }

    if(this.voyanceTypes.includes(type))
    {
      if ($('#'+type).hasClass('badge-light')) {
        $('#'+type).removeClass('badge-light').removeClass('badge-unactive-mobiyo').addClass('badge-success').addClass('badge-active-mobiyo');
      } else {
        $('#'+type).removeClass('badge-success').removeClass('badge-active-mobiyo').addClass('badge-light').addClass('badge-unactive-mobiyo');

      }
    }


  }

  addFilter(event) {

    if (event.currentTarget.id === 'consultant') {
      this.filters.consultantName = event.target.value;
    }
    this.filter(event.currentTarget.id);

    if (Object.values(event.currentTarget.classList).indexOf('consultation_modes') > -1 && Object.values(event.currentTarget.classList).indexOf('available') > -1) {
      this.filters.consultationModesFilter.indexOf(event.currentTarget.id) === -1 ? this.filters.consultationModesFilter.push(event.currentTarget.id) : '';
    } else if (Object.values(event.currentTarget.classList).indexOf('consultation_modes') > -1 && Object.values(event.currentTarget.classList).indexOf('available') === -1) {
      this.filters.consultationModesFilter.splice(this.filters.consultationModesFilter.indexOf(event.currentTarget.id), 1);
    } else if (Object.values(event.currentTarget.classList).indexOf('voyance_types') > -1 && Object.values(event.currentTarget.classList).indexOf('badge-active-mobiyo') > -1) {
      this.filters.voyanceTypesFilter.indexOf(event.currentTarget.id) === -1 ? this.filters.voyanceTypesFilter.push(event.currentTarget.id) : '';
    } else if (Object.values(event.currentTarget.classList).indexOf('voyance_types') > -1 && Object.values(event.currentTarget.classList).indexOf('badge-active-mobiyo') === -1) {
      this.filters.voyanceTypesFilter.splice(this.filters.voyanceTypesFilter.indexOf(event.currentTarget.id), 1);
    } else if (Object.values(event.currentTarget.classList).indexOf('languages_types') > -1 && Object.values(event.currentTarget.classList).indexOf('available') > -1) {
      this.filters.languagesTypesFilter.indexOf(event.currentTarget.id) === -1 ? this.filters.languagesTypesFilter.push(event.currentTarget.id) : '';
    } else if (Object.values(event.currentTarget.classList).indexOf('languages_types') > -1 &&  Object.values(event.currentTarget.classList).indexOf('available') === -1) {
      this.filters.languagesTypesFilter.splice(this.filters.languagesTypesFilter.indexOf(event.currentTarget.id), 1);
    } else {
    }


   this.getFilteredData();

  }

  getFilteredData() {


    this.listDataFiltered = [];
    this.listData = this.listDataOriginal;

    this.listData.forEach((consultant, index) => {

      let consultyantName = consultant.consultantlogin;
      if (consultyantName == null) {
        consultyantName = '';
      }

      if (this.filters.consultantName != '') {
        if (
          consultyantName.toLowerCase().includes(this.filters.consultantName.toLowerCase()) &&
          this.filters.consultationModesFilter.every(elem => consultant.prestations.includes(elem)) &&
          this.filters.voyanceTypesFilter.every(elem => consultant.voyanceTypes.includes(elem)) &&
          this.filters.languagesTypesFilter.every(elem => consultant.language.includes(elem))
        ) {
          this.listDataFiltered.push(consultant);
        }

      } else {

        if (
          this.filters.consultationModesFilter.every(elem => consultant.prestations.includes(elem)) &&
          this.filters.voyanceTypesFilter.every(elem => consultant.voyanceTypes.includes(elem)) &&
          this.filters.languagesTypesFilter.every(elem => consultant.language.includes(elem))
        ) {
          this.listDataFiltered.push(consultant);
        }

      }


    });

    this.listData = this.listDataFiltered;

  }


  showVoyanceType() {

    if ($('#row-voyance-types:visible').length == 0) {
      $('.row-voyance-types').fadeIn('slow');
      $('#search-advanced-icon').removeClass('fa-chevron-down').addClass('fa-chevron-up');

    } else {
      $('.row-voyance-types').fadeOut('slow');
      $('#search-advanced-icon').removeClass('fa-chevron-up').addClass('fa-chevron-down');

    }

  }

}
