import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProfileService} from '../../../_services/profile.service';
declare var $: any;

@Component({
  selector: 'app-birth-time',
  templateUrl: './birth-time.component.html',
  styleUrls: ['./birth-time.component.css']
})
export class BirthTimeComponent implements OnInit {
  @Input() birthInfo: string;
  @Input() infoDefinitions: any;
  @Output() birthInfoEvent = new EventEmitter<string>();
  form: FormGroup;
  success = false;
  error = false;
  date: Date;
  submitting = false;
  heads: string[];
  hours: { text: string; value: string }[];
  two = (x: number): string => x > 9 ? `${x}` : `0${x}`;
  dateTimeToString = (d: Date) => `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${this.two(d.getHours())}:${this.two(d.getMinutes())}:${this.two(d.getSeconds())}`;
  dateToTimeString = (d: Date) => `${this.two(d.getHours())}:00`;
  textBirthTime = (): string => this.birthInfo ? this.hours.find(({value}) => value === this.dateToTimeString(new Date(this.birthInfo))).text : '';

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
  ) {
    this.heads = ['00:00', '12:00'];
    this.hours = [
      { value: '00:00', text: 'Minuit' },
      { value: '01:00', text: 'Une heure' },
      { value: '02:00', text: 'Deux heures' },
      { value: '03:00', text: 'Trois heures' },
      { value: '04:00', text: 'Quatre heures' },
      { value: '05:00', text: 'Cinq heures' },
      { value: '06:00', text: 'Six heures' },
      { value: '07:00', text: 'Sept heures' },
      { value: '08:00', text: 'Huit heures' },
      { value: '09:00', text: 'Neuf heures' },
      { value: '10:00', text: 'Dix heures' },
      { value: '11:00', text: 'Onze heures' },
      { value: '12:00', text: 'Midi' },
      { value: '13:00', text: 'Treize heures' },
      { value: '14:00', text: 'Quatorze heures' },
      { value: '15:00', text: 'Quinze heures' },
      { value: '16:00', text: 'Seize heures' },
      { value: '17:00', text: 'Dix-sept heures' },
      { value: '18:00', text: 'Dix-huit heures' },
      { value: '19:00', text: 'Dix-neuf heures' },
      { value: '20:00', text: 'Vingt heures' },
      { value: '21:00', text: 'Vingt et une heures' },
      { value: '22:00', text: 'Vingt-deux heures' },
      { value: '23:00', text: 'Vingt-trois heures' }
    ];
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerId: parseInt(localStorage.getItem('customerId'), 10),
      customerSessionId: localStorage.getItem('customerSessionId'),
      customerBirthday: ['', [
        Validators.required
      ]],
    });
    $('#add-birth-time-modal')
      .on('show.bs.modal', () => {
        if (this.birthInfo) {
          this.date = new Date(this.birthInfo);
          this.form.patchValue({
            customerBirthday: this.dateToTimeString(new Date(this.birthInfo))
          });
        }
      }).on('hidden.bs.modal', () => {
      this.reset();
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitting = true;
      const formValue = this.form.value;
      const birthday = new Date(`0 ${formValue.customerBirthday}`);
      if (this.date) {
        birthday.setFullYear(this.date.getFullYear());
        birthday.setMonth(this.date.getMonth());
        birthday.setDate(this.date.getDate());
      }
      formValue.customerBirthday = this.dateTimeToString(birthday);
      this.profileService.editBirthDate(formValue).subscribe(
        (result: any) => {
          if (result.statusCode === undefined) {
            this.birthInfoEvent.emit(formValue.customerBirthday);
            this.next();
          } else {
            this.next(false);
          }
        },
        (error) => {
          this.next(false);
        }
      );
    } else {
      this.next(false);
    }
  }

  next(state = true): void {
    if (state) {
      $('#add-birth-time-modal').modal('hide');
    } else {
      this.success = state;
      this.error = !state;
    }
  }

  reset(): void {
    this.success = false;
    this.error = false;
    this.form.patchValue({
      customerBirthday: ''
    });
    this.submitting = false;
  }
}
